import React, { useState, useRef } from 'react';
// import { graphql, useStaticQuery, Link } from 'gatsby';
import usePagesInfo from '../utils/usePagesInfo';
import useSetting from '../utils/useSetting';
import useUserInfo from '../utils/useUserInfo';
// import useCart from '../utils/useCart';
import useCart from '../utils/useCheckout';

const RootContext = React.createContext();

export function RootProvider(props) {
  const { children, t } = props;
  console.log('===> RootProvider', props, t);
  const {
    pagesInfo,
    updatePagesInfo,
    notification,
    setNotification,
    popupInfo,
    setPopupInfo,
    timestamp,
    setTimestamp,
    subOnlineShop,
    setSubOnlineShop,
  } = usePagesInfo();
  const {
    setting,
    updateSetting,
    loading,
    showOnlineShop,
    setShowOnlineShop,
    showSearch,
    setShowSearch,
    storeMayLikeTag,
    updateStoreMayLikeTag,
    staticLocales,
  } = useSetting();
  const {
    user,
    updateUser,
    vipSetting,
    fetchUserInfoByToken,
    logout,
    loginCheck,
    vipTags,
    setVipTags,
    isVip,
    isAbnormal,
    hasConcerns,
  } = useUserInfo();
  const {
    cart,
    checkCart,
    addItemToCart,
    updateCartLinesQuantity,
    modifyItemQuantity,
    removeCartItem,
    showCart,
    setShowCart,
    updateCart,
  } = useCart(user);
  console.log('===> loading111', loading);
  // if (loading) {
  //   return loading;
  // }
  return (
    <RootContext.Provider
      value={{
        pagesInfo,
        updatePagesInfo,
        setting,
        updateSetting,
        loading,
        env: process.env.NODE_ENV === 'development' ? 'dev' : 'prod',
        // env: 'dev',
        currencyInfo:
          (typeof window !== 'undefined' && window.Currency) || false,
        user,
        updateUser,
        vipSetting,
        fetchUserInfoByToken,
        logout,
        loginCheck,
        cart,
        checkCart,
        addItemToCart,
        updateCartLinesQuantity,
        modifyItemQuantity,
        removeCartItem,
        showCart,
        setShowCart,
        notification,
        setNotification,
        updateCart,
        showOnlineShop,
        setShowOnlineShop,
        showSearch,
        setShowSearch,
        popupInfo,
        setPopupInfo,
        t,
        timestamp,
        setTimestamp,
        storeMayLikeTag,
        updateStoreMayLikeTag,
        vipTags,
        setVipTags,
        isVip,
        staticLocales,
        subOnlineShop,
        setSubOnlineShop,
        isAbnormal,
        hasConcerns,
      }}
    >
      {/* oh!  */}
      {/* {loading ? 'loading' : children} */}
      {children}
    </RootContext.Provider>
  );
}

// export const query = graphql`
//   query($language: String!) {
//     locales: allLocale(filter: { language: { eq: $language } }) {
//       edges {
//         node {
//           ns
//           data
//           language
//         }
//       }
//     }
//   }
// `;

export default RootContext;
