// discountAllocations {
//   discountedAmount {
//     amount
//     currencyCode
//   }
// }

const cart = `
  id
  attributes {
    key
    value
  }
  buyerIdentity {
    countryCode
    customer {
      id
      acceptsMarketing
      email
      firstName
      lastName
      phone
      displayName
      numberOfOrders
      tags
    }
    deliveryAddressPreferences {
      ... on MailingAddress {
        address1
        address2
        city
        country
        firstName
        lastName
        name
        phone
        province
        provinceCode
        zip
      }
    }
    email
    phone
    walletPreferences
  }
  checkoutUrl
  cost {
    checkoutChargeAmount {
      amount
      currencyCode
    }
    subtotalAmount {
      amount
      currencyCode
    }
    subtotalAmountEstimated
    totalAmount {
      amount
      currencyCode
    }
    totalAmountEstimated
    totalDutyAmount {
      amount
      currencyCode
    }
    totalDutyAmountEstimated
    totalTaxAmount {
      amount
      currencyCode
    }
    totalTaxAmountEstimated
  }
  createdAt
  discountAllocations {
    discountedAmount {
      amount
      currencyCode
    }
  }
  discountCodes {
    applicable
    code
  }
  note
  totalQuantity
  updatedAt
  deliveryGroups(first: 24) {
    nodes {
      id
      deliveryAddress {
        address1
        address2
        city
        company
        country
        countryCodeV2
        firstName
        formatted(withName: true, withCompany: true)
        formattedArea
        id
        lastName
        latitude
        longitude
        name
        phone
        province
        provinceCode
        zip
      }
      deliveryOptions {
        code
        deliveryMethodType
        description
        estimatedCost {
          amount
          currencyCode
        }
        handle
        title
      }
      selectedDeliveryOption {
        code
        deliveryMethodType
        description
        estimatedCost {
          amount
          currencyCode
        }
        handle
        title
      }
      cartLines(first: 24) {
        nodes {
          id
          attributes {
            key
            value
          }
          cost {
            amountPerQuantity {
              amount
              currencyCode
            }
            compareAtAmountPerQuantity {
              amount
              currencyCode
            }
            subtotalAmount {
              amount
              currencyCode
            }
            totalAmount {
              amount
              currencyCode
            }
          }
          
          quantity
          sellingPlanAllocation {
            checkoutChargeAmount {
              amount
              currencyCode
            }
            priceAdjustments {
              compareAtPrice {
                amount
                currencyCode
              }
              perDeliveryPrice {
                amount
                currencyCode
              }
              price {
                amount
                currencyCode
              }
              unitPrice {
                amount
                currencyCode
              }
            }
            remainingBalanceChargeAmount {
              amount
              currencyCode
            }
            sellingPlan {
              id
              name
              description
              checkoutCharge {
                type
                value
              }
              priceAdjustments {
                adjustmentValue
                orderCount
              }
              options {
                name
                value
              }
              recurringDeliveries
            }
          }
        }
      }
    }
  }
  lines(first: 10) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        id
        merchandise {
          ... on ProductVariant {
            availableForSale
            barcode
            compareAtPrice {
              amount
              currencyCode
            }
            currentlyNotInStock
            id
            image {
              altText
                height
                id
                url
                
                width
            }
            price {
              amount
              currencyCode
            }
            product {
              availableForSale
              compareAtPriceRange {
                maxVariantPrice {
                  amount
                  currencyCode
                }
                minVariantPrice {
                  amount
                  currencyCode
                }
              }
              createdAt
              description(truncateAt: 10)
              descriptionHtml
              images (first: 10) {
                nodes {
                  altText
                  height
                  id
                  url(transform: {maxHeight: 768 maxWidth: 768})
                  width
                }
              }
              featuredImage {
                altText
                height
                id
                url(transform: {maxHeight: 768 maxWidth: 768})
                width
              }
              handle
              id
              isGiftCard
              onlineStoreUrl
              options(first: 8)  {
                
                  id
                  name
                  values
                
              }
              priceRange {
                maxVariantPrice {
                  amount
                  currencyCode
                }
                minVariantPrice {
                  amount
                  currencyCode
                }
              }
              productType
              publishedAt
              requiresSellingPlan
              seo {
                description
                title
              }
              tags
              title
              totalInventory
              updatedAt
            }
            quantityAvailable
            requiresShipping
            selectedOptions {
              name
              value
            }
            sku
            title
            unitPrice {
              amount
              currencyCode
            }
            unitPriceMeasurement {
              measuredType
              quantityUnit
              quantityValue
              referenceUnit
              referenceValue
            }
            weight
            weightUnit
            sellingPlanAllocations(first: 24) {
              nodes {
                checkoutChargeAmount{
                  amount
                  currencyCode
                }
                priceAdjustments {
                  compareAtPrice{
                    amount
                    currencyCode
                  }
                  perDeliveryPrice{
                    amount
                    currencyCode
                  }
                  price{
                    amount
                    currencyCode
                  }
                  unitPrice{
                    amount
                    currencyCode
                  }
                }
                remainingBalanceChargeAmount{
                  amount
                  currencyCode
                }
                sellingPlan {
                  checkoutCharge {
                    type
                    value
                  }
                  description
                  id
                  name
                  options {
                    name
                    value
                  }
                  priceAdjustments {
                    adjustmentValue
                    orderCount
                  }
                  recurringDeliveries
                }
              }
            }
            storeAvailability(first: 24) {
              nodes {
                available
                location {
                  address {
                    address1
                    address2
                    city
                    country
                    
                    latitude
                    longitude
                    phone
                    province
                    provinceCode
                    zip
                  }
                }
                pickUpTime
              }
            }
          }
        }
        quantity
        cost {
          amountPerQuantity {
            amount
            currencyCode
          }
          compareAtAmountPerQuantity {
            amount
            currencyCode
          }
          subtotalAmount {
            amount
            currencyCode
          }
          totalAmount {
            amount
            currencyCode
          }
        }
        discountAllocations {
          discountedAmount {
            amount
            currencyCode
          }
        }
        sellingPlanAllocation {
          checkoutChargeAmount {
            amount
            currencyCode
          }
          priceAdjustments {
            compareAtPrice  {
              amount
              currencyCode
            }
            perDeliveryPrice  {
              amount
              currencyCode
            }
            price  {
              amount
              currencyCode
            }
            unitPrice  {
              amount
              currencyCode
            }
          }
          remainingBalanceChargeAmount {
            amount
            currencyCode
          }
          sellingPlan {
            checkoutCharge {
              type
              value
            }
            description
            id
            name
            options {
              name
              value
            }
           
            recurringDeliveries
          }
        }
      }
    }
  }
`;

export const CartQuery = `
query Cart( $country: CountryCode $language: LanguageCode $id: ID!)
  @inContext(country: $country, language: $language) {
  cart(id: $id) {
    ${cart}
  }
}`;

export const CartCreateMutation = `
mutation CartCreate($input: CartInput) {
  cartCreate(input: $input) {
    cart {
      ${cart}
    }
    userErrors {
      field
      message
    }
  }
}`;

export const CartCreateMutationInput = {
  input: {
    attributes: [
      {
        key: '',
        value: '',
      },
    ],
    buyerIdentity: {
      countryCode: '',
      customerAccessToken: '',
      deliveryAddressPreferences: {
        customerAddressId: '',
        deliveryAddress: {
          address1: '',
          address2: '',
          city: '',
          company: '',
          country: '',
          firstName: '',
          lastName: '',
          phone: '',
          province: '',
          zip: '',
        },
      },

      email: '',
      phone: '',
      walletPreferences: [''],
    },
    discountCodes: [''],
    lines: [
      {
        attributes: [
          {
            key: '',
            value: '',
          },
        ],
        merchandiseId: '',
        quantity: 1,
        sellingPlanId: '',
      },
    ],
    metafields: [
      {
        key: '',
        type: '',
        value: '',
      },
    ],
    note: '',
  },
};

export const CartSelectdDeliveryOptionUpdateMutation = `
mutation cartSelectedDeliveryOptionsUpdate($cartId: ID!, $selectedDeliveryOptions: [CartSelectedDeliveryOptionInput!]!) {
  cartSelectedDeliveryOptionsUpdate(cartId: $cartId, selectedDeliveryOptions: $selectedDeliveryOptions) {
    cart {
      ${cart}
    }
    userErrors {
      field
      message
    }
  }
}`;

export const CartSelectdDeliveryOptionUpdateMutationInput = {
  cartId: '',
  selectedDeliveryOptions: [
    {
      deliveryGroupId: '',
      deliveryOptionHandle: '',
    },
  ],
};

export const CartBuyerIdentityUpdateMutation = `
mutation CartBuyerIdentityUpdate($buyerIdentity: CartBuyerIdentityInput!, $cartId: ID!) {
  cartBuyerIdentityUpdate(buyerIdentity: $buyerIdentity, cartId: $cartId) {
    cart {
      buyerIdentity {
        countryCode
        customer {
          id
          acceptsMarketing
          email
          firstName
          lastName
          phone
          displayName
          numberOfOrders
          tags
        }
      }
    }
    userErrors {
      field
      message
    }
  }
}`;

export const CartLinesAddMutation = `
mutation CartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
  cartLinesAdd(cartId: $cartId, lines: $lines) {
    cart {
      ${cart}
    }
    userErrors {
      field
      message
    }
  }
}`;

export const cartLinesAddMutationInput = {
  attributes: [{ key: '', value: '' }],
  merchandiseId: '',
  quantity: 0,
  sellingPlanId: '',
};

export const CartLinesUpdateMutation = `
mutation CartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
  cartLinesUpdate(cartId: $cartId, lines: $lines) {
    cart {
      id
      cost {
        checkoutChargeAmount {
          amount
          currencyCode
        }
        subtotalAmount {
          amount
          currencyCode
        }
        subtotalAmountEstimated
        totalAmount {
          amount
          currencyCode
        }
        totalAmountEstimated
        totalDutyAmount {
          amount
          currencyCode
        }
        totalDutyAmountEstimated
        totalTaxAmount {
          amount
          currencyCode
        }
        totalTaxAmountEstimated
      }
      discountAllocations {
          discountedAmount {
            amount
            currencyCode
          }
        }
        discountCodes {
          applicable
          code
        }
      totalQuantity
      lines(first: 10) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          cursor
          node {
            id
            quantity
            cost {
              amountPerQuantity {
                amount
                currencyCode
              }
              compareAtAmountPerQuantity {
                amount
                currencyCode
              }
              subtotalAmount {
                amount
                currencyCode
              }
              totalAmount {
                amount
                currencyCode
              }
            }
            discountAllocations {
              discountedAmount {
                amount
                currencyCode
              }
            }
          }
        }
      }
    }
    userErrors {
      field
      message
    }
  }
}`;

export const CartLinesUpdateMutationInput = {
  cartId: '',
  lines: [
    {
      attributes: [
        {
          key: '',
          value: '',
        },
      ],
      id: '',
      merchandiseId: '',
      quantity: 1,
      sellingPlanId: '',
    },
  ],
};

export const CartLinesRemoveMutation = `
mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
  cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
    cart {
      id
      cost {
        checkoutChargeAmount {
          amount
          currencyCode
        }
        subtotalAmount {
          amount
          currencyCode
        }
        subtotalAmountEstimated
        totalAmount {
          amount
          currencyCode
        }
        totalAmountEstimated
        totalDutyAmount {
          amount
          currencyCode
        }
        totalDutyAmountEstimated
        totalTaxAmount {
          amount
          currencyCode
        }
        totalTaxAmountEstimated
      }
      discountAllocations {
        discountedAmount {
          amount
          currencyCode
        }
      }
      discountCodes {
        applicable
        code
      }
      totalQuantity
      lines(first: 10) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          cursor
          node {
            id
            quantity
            cost {
              amountPerQuantity {
                amount
                currencyCode
              }
              compareAtAmountPerQuantity {
                amount
                currencyCode
              }
              subtotalAmount {
                amount
                currencyCode
              }
              totalAmount {
                amount
                currencyCode
              }
            }
            discountAllocations {
              discountedAmount {
                amount
                currencyCode
              }
            }
          }
        }
      }
    }
    userErrors {
      field
      message
    }
  }
}`;

export const CartLinesRemoveMutationInput = {
  cartId: '',
  lineIds: [''],
};
