import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const Load = styled.div`
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  background: var(--mainColor);
  opacity: 0.9;
  backdrop-filter: blur(20px);
  z-index: 99;
  /* cursor: auto; */
  pointer-events: auto;
  /* opacity: 1; */
  transition: top 0s ease, bottom 0s ease, opacity 0.3s ease;
  transform: translate3d(0px, 0px, 0);
  &.hide {
    pointer-events: none;
    top: -1000000000px;
    bottom: -1000000000px;
    transform: translate3d(-10000000px, -10000000px, 0);
    opacity: 0;
    transition: top 0s 0.3s ease, bottom 0s 0.3s ease, opacity 0.3s ease;
  }
  i {
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    display: inline-block;
    transform-origin: left top;
    position: absolute;
    text-align: center;
    &.loading-icon-small {
      font-size: 18px;
    }
    &.loading-icon-middle {
      font-size: 24px;
    }
    &.loading-icon-large {
      font-size: 36px;
    }
    &.loading-icon-XLarge {
      font-size: 80px;
    }
    &.active {
      /* animation: rotate 1s ease infinite; */
    }
    &.icon-flower {
      animation: rotate 1s ease infinite, opacity10 1s ease infinite alternate;
    }
    &.icon-flower-done {
      animation: rotate 1s ease infinite, opacity01 1s ease infinite alternate;
    }
  }
  @keyframes rotate {
    0% {
      transform: rotate(0deg) translate3d(-50%, -50%, 0);
      /* opacity: 0; */
    }
    100% {
      transform: rotate(360deg) translate3d(-50%, -50%, 0);
      /* opacity: 1; */
    }
  }
  @keyframes opacity10 {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes opacity01 {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export default function Loading(props) {
  const { type, loading = false, controller = {}, className = '', isFull = true } = props;
  const { style = {} } = controller;
  const [shouldHide, setShouldHide] = useState(!loading);
  const timer = useRef(null);
  useEffect(() => {
    const time = controller.delay || 0;
    timer.current = setTimeout(() => {
      setShouldHide(!loading);
    }, time);
    return () => {
      clearTimeout(timer.current);
    };
  }, [loading]);
  if (type === 'small') {
    return (
      <Load className={`${shouldHide && 'hide'} ${className}`} style={style}>
        <i className="icon-flower loading-icon-small active" />
        <i className="icon-flower-done loading-icon-small active" />
      </Load>
    );
  }
  if (type === 'middle') {
    return (
      <Load className={`${shouldHide && 'hide'} ${className}`} style={style}>
        <i className="icon-flower loading-icon-middle active" />
        <i className="icon-flower-done loading-icon-middle active" />
      </Load>
    );
  }
  if (type === 'large') {
    return (
      <Load className={`${shouldHide && 'hide'} ${className}`} style={style}>
        <i className="icon-flower loading-icon-large active" />
        <i className="icon-flower-done loading-icon-large active" />
      </Load>
    );
  }
  if (type === 'XLarge') {
    return (
      <Load className={`${shouldHide && 'hide'} ${isFull ? 'fixed' : ''}`} style={style}>
        <i className="icon-flower loading-icon-XLarge active" />
        <i className="icon-flower-done loading-icon-XLarge active" />
      </Load>
    );
  }
  return '';
}
