import React, { useState, useEffect, useContext, useRef } from 'react';
import styled from 'styled-components';
import { navigate, Link } from 'gatsby';
import {
  Link as I18nLink,
  Trans,
  useTranslation,
  useI18next,
} from '../../plugins/gatsby-plugin-react-i18next-customize';
import RootContext from './RootContext';
import { countryCompare } from '../constants/country';
import shopifyCountry from '../constants/shopify_countries.json';
import TransAni from './TransAni';
import { isBrowser } from '../helpers/browser';
import { langPrefix } from '../helpers/urlParser';
import { lngCovert } from '../utils/useSetting';
import countryDefaultAddress from '../constants/countryDefaultAddress';
import {
  AccountCreateAddressMutation,
  AccountDefaultAddressMutation,
} from '../constants/accountQuery';
import { ProductQuery, ProductHandleQuery } from '../constants/productQuery';
import queryFormat, { mutationFormat } from '../helpers/queryFormat';
// import queryFormat from '../helpers/queryFormat';
import { apiFetch, glStoreFrontFetch } from '../apis-browser';
import Loading from './Loading';

let defaultCountry = 'TW';

let getUserCountry;

try {
  getUserCountry = require('js-user-country');
  defaultCountry = getUserCountry.default().id;
} catch (e) {
  console.log(e);
}

const CHINESE_LANG = ['TW', 'MO', 'SG', 'HK', 'MY'];

const Foot = styled.footer`
  position: relative;
  width: 100%;
  /* height: var(--footerHeight); */
  background: var(--mainColor);
  border-top: 1px solid #999;
  padding: 75px 50px 50px 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
  &.hasSquareLogo {
    padding-right: calc(130px + 8vw);
  }
  .button {
    margin: 10px 0px;
  }
  .term-block {
    /* min-width: 100px; */
    display: inline-block;
    overflow: hidden;
    &:nth-child(4),
    &:nth-child(5) {
      > div {
        &:nth-child(1),
        &:nth-child(2) {
          pointer-events: none;
        }
      }
    }
    .button {
      min-width: 140px;
    }
  }
  @media screen and (max-width: 1020px) {
    &.hasSquareLogo {
      padding-right: calc(90px + 8vw);
      /* padding-right: 25px; */
      padding-left: 25px;
    }
  }

  @media screen and (max-width: 950px) {
    padding-top: 25px;
    display: block;
    &.hasSquareLogo {
      padding-right: 0px;
      padding-left: 0px;
    }
    /* .term-block {
      
    } */
    .term-block {
      width: 50%;
      padding-right: 25px;
      padding-left: 25px;
      &:nth-child(3) {
        margin-left: 50%;
      }
      &:nth-child(4),
      &:nth-child(5) {
        width: 100%;
        > div {
          &:nth-child(1),
          &:nth-child(2) {
            display: none;
          }
        }
      }
    }
  }
  @media screen and (max-width: 900px) {
    padding-top: 20px;
    display: block;
    &.hasSquareLogo {
      padding-right: 0px;
      padding-left: 0px;
    }
    /* .term-block {
      
    } */
    .term-block {
      width: 50%;
      padding-right: 20px;
      padding-left: 20px;
      overflow: hidden;
      &:nth-child(3) {
        margin-left: 50%;
      }
      &:nth-child(4),
      &:nth-child(5) {
        width: 100%;
        > div {
          &:nth-child(1),
          &:nth-child(2) {
            display: none;
          }
        }
      }
    }
  }
  /* @media screen and (max-width: 768px) {
    &.hasSquareLogo {
      padding-right: 130px;
    }
  } */
  /* @media screen and (max-width: 650px) {
    &.hasSquareLogo {
      padding-right: 25px;
      padding-left: 25px;
    }
    .term-block {
      padding-right: 25px;
      padding-left: 25px;
    }
  } */
  @media screen and (max-width: 560px) {
    &.hasSquareLogo {
      padding-right: 0px;
      padding-left: 0px;
    }
    .term-block {
      padding-right: 20px;
      padding-left: 20px;
      &:nth-child(3) {
        margin-bottom: 110px;
      }
    }
  }
`;

export default function Footer(props) {
  const {
    pageContext,
    params = {},
    className = '',
    hasSquareLogo,
    useJSON,
  } = props;
  const { setting, updateSetting, popupInfo, setPopupInfo, user, updateUser } =
    useContext(RootContext);
  const [language, setLanguage] = useState(pageContext?.i18n?.language);
  const [country, setCountry] = useState(setting.country);
  const { languages, changeLanguage } = useI18next();
  const [changeMarket, setChangeMarket] = useState(false);

  // const { languages, originalPath } = useI18next();
  // console.log('===> footer', pageContext, pageContext?.i18n);
  // const refs = useRef({});
  const path = pageContext?.i18n?.originalPath;
  // if (path.includes('[id]') && params.id) {
  //   path = path.replace('[id]', params.id);
  // }
  // console.log('===> hasSquareLogo', language);
  console.log(
    '===> setting.markets',
    setting.markets,
    pageContext?.i18n?.languages
  );
  useEffect(() => {
    if (isBrowser()) {
      const hasShowLocationAndLanguage = localStorage.getItem(
        'hasShowLocationAndLanguage_2312'
      );
      if (!hasShowLocationAndLanguage) {
        document.querySelector('#locationAndLanguage').click();
      }
    }
  }, []);
  return (
    <Foot className={`${className} ${hasSquareLogo}`}>
      <div className="term-block">
        <a
          href={`${langPrefix(pageContext)}/terms-conditions`}
          target="_blank"
          rel="noreferrer"
        >
          <TransAni
            useJSON={useJSON}
            containerClass="GSText button pointer"
            textClass="GSText s-13"
            pageContext={pageContext}
            type="horizontal"
            borderAni={false}
            i18nStyle={{
              // zh: {
              //   className: 's-11',
              //   style: {
              //     // fontWeight: '500',
              //     // textAlign: 'left',
              //     lineHeight: '1.7em',
              //   },
              // },
              'zh/ja/ko': {
                className: 's-11',
                style: {
                  // fontWeight: '500',
                  // textAlign: 'left',
                  lineHeight: '1.7em',
                },
              },
            }}
            onClick={() => {
              // addItemToCart({
              //   item: itemDetail.variantBySelectedOptions,
              //   quantity: quantityCount,
              //   sellingPlanId: itemDetail.requiresSellingPlan?.id,
              //   user,
              //   country: setting.country.toUpperCase(),
              //   language: pageContext.language.toUpperCase(),
              //   setShowCart,
              // });
            }}
          >
            Terms & Conditions
          </TransAni>
        </a>
        <a
          href={`${langPrefix(pageContext)}/privacy-policy`}
          target="_blank"
          rel="noreferrer"
        >
          <TransAni
            useJSON={useJSON}
            containerClass="GSText button pointer"
            textClass="GSText s-13"
            pageContext={pageContext}
            type="horizontal"
            borderAni={false}
            i18nStyle={{
              // zh: {
              //   className: 's-11',
              //   style: {
              //     // fontWeight: '500',
              //     // textAlign: 'left',
              //     lineHeight: '1.7em',
              //   },
              // },
              'zh/ja/ko': {
                className: 's-11',
                style: {
                  // fontWeight: '500',
                  // textAlign: 'left',
                  lineHeight: '1.7em',
                },
              },
            }}
            onClick={() => {}}
          >
            Private Policy
          </TransAni>
        </a>
        {/* <TransAni
          useJSON={useJSON}
          containerClass="GSText button pointer"
          textClass="GSText s-13"
          pageContext={pageContext}
          type="horizontal"
          borderAni={false}
          i18nStyle={{
            zh: {
              className: 's-11',
              style: {
                fontWeight: '500',
                // textAlign: 'left',
                lineHeight: '1.7em',
              },
            },
          }}
          onClick={() => {}}
        >
          Cookie Policy
        </TransAni> */}
      </div>
      <div className="term-block">
        <Link to={`${langPrefix(pageContext)}/shipping-and-return`}>
          <TransAni
            useJSON={useJSON}
            containerClass="GSText button pointer"
            textClass="GSText s-13"
            pageContext={pageContext}
            type="horizontal"
            borderAni={false}
            i18nStyle={{
              // zh: {
              //   className: 's-11',
              //   style: {
              //     // fontWeight: '500',
              //     // textAlign: 'left',
              //     lineHeight: '1.7em',
              //   },
              // },
              'zh/ja/ko': {
                className: 's-11',
                style: {
                  // fontWeight: '500',
                  // textAlign: 'left',
                  lineHeight: '1.7em',
                },
              },
            }}
            onClick={() => {
              // addItemToCart({
              //   item: itemDetail.variantBySelectedOptions,
              //   quantity: quantityCount,
              //   sellingPlanId: itemDetail.requiresSellingPlan?.id,
              //   user,
              //   country: setting.country.toUpperCase(),
              //   language: pageContext.language.toUpperCase(),
              //   setShowCart,
              // });
            }}
          >
            Shipping & Returns
          </TransAni>
        </Link>
        <Link to={`${langPrefix(pageContext)}/care`}>
          <TransAni
            useJSON={useJSON}
            containerClass="GSText button pointer"
            textClass="GSText s-13"
            pageContext={pageContext}
            type="horizontal"
            borderAni={false}
            i18nStyle={{
              // zh: {
              //   className: 's-11',
              //   style: {
              //     // fontWeight: '500',
              //     // textAlign: 'left',
              //     lineHeight: '1.7em',
              //   },
              // },
              'zh/ja/ko': {
                className: 's-11',
                style: {
                  // fontWeight: '500',
                  // textAlign: 'left',
                  lineHeight: '1.7em',
                },
              },
            }}
            onClick={() => {}}
          >
            Care
          </TransAni>
        </Link>
        {/* <TransAni
          useJSON={useJSON}
          containerClass="GSText button pointer"
          textClass="GSText s-13"
          pageContext={pageContext}
          type="horizontal"
          borderAni={false}
          i18nStyle={{
            zh: {
              className: 's-11',
              style: {
                fontWeight: '500',
                // textAlign: 'left',
                lineHeight: '1.7em',
              },
            },
          }}
          onClick={() => {}}
        >
          Size Guide
        </TransAni> */}
      </div>
      <div className="term-block">
        <a
          href="https://www.instagram.com/joucosofficial/"
          target="_blank"
          rel="noreferrer"
        >
          <p
            className="GSText s-13 pointer button s-11-900 l-15"
            // useJSON={useJSON}
            // containerClass="GSText button pointer"
            // textClass="GSText s-13"
            // pageContext={pageContext}
            // type="horizontal"
            // borderAni={false}
            // i18nStyle={{
            //   zh: {
            //     className: 's-11',
            //     style: {
            //       fontWeight: '500',
            //       // textAlign: 'left',
            //       lineHeight: '1.7em',
            //     },
            //   },
            // }}
            // onClick={() => {
            //   // addItemToCart({
            //   //   item: itemDetail.variantBySelectedOptions,
            //   //   quantity: quantityCount,
            //   //   sellingPlanId: itemDetail.requiresSellingPlan?.id,
            //   //   user,
            //   //   country: setting.country.toUpperCase(),
            //   //   language: pageContext.language.toUpperCase(),
            //   //   setShowCart,
            //   // });
            // }}
          >
            INSTAGRAM
          </p>
        </a>
        {/* <TransAni
          // useJSON={useJSON}
          containerClass="GSText button pointer"
          textClass="GSText s-13"
          pageContext={pageContext}
          type="horizontal"
          borderAni={false}
          i18nStyle={{
            zh: {
              className: 's-11',
              style: {
                fontWeight: '500',
                // textAlign: 'left',
                lineHeight: '1.7em',
              },
            },
          }}
          onClick={() => {}}
        >
          Tik Tok
        </TransAni> */}
        <p
          id="locationAndLanguage"
          className="GSText s-13 pointer s-11-900 l-15"
          onClick={() => {
            setPopupInfo({
              active: true,
              type: '',
              hideClose: true,
              showFixedBottomConfirmButton: true,
              onDisable: async (callback = () => {}, state, setState) => {
                if (!isBrowser()) return;
                callback();
              },
              onConfirm: async (callback = () => {}, state, setState) => {
                if (!isBrowser()) return;
                let shouldReload = false;
                console.log('===> w1', state.country, country);

                if (
                  state.country !== country ||
                  (window.location.href.includes('https://joucos.com') &&
                    !CHINESE_LANG.includes(state.country)) ||
                  (window.location.href.includes('https://int.joucos.com') &&
                    CHINESE_LANG.includes(state.country))
                ) {
                  // if (shouldReload) {
                  if (window.location.href.includes('https://joucos.com')) {
                    // alert(country);
                    // if (window.location.href.includes('http://localhost:8888')) {
                    // alert(state.country);
                    if (!CHINESE_LANG.includes(state.country)) {
                      localStorage.setItem('country', state.country || 'TW');
                      localStorage.setItem(
                        'hasShowLocationAndLanguage_2312',
                        'true'
                      );

                      let slash = window.location.href.split(
                        'https://joucos.com/'
                        // 'http://localhost:8888/'
                      )[1];
                      if (slash.includes('?ln=')) {
                        slash = slash.split('?ln=')[0];
                      }
                      if (
                        slash.includes('zh') ||
                        slash.includes('ja') ||
                        slash.includes('ko')
                      ) {
                        slash = slash.slice(3);
                      }
                      // alert(slash);
                      if (window.location.href.includes('/products/')) {
                        try {
                          let pid = window.location.href.split('/products/')[1];
                          pid = pid.split('/')[0];
                          if (!pid) {
                            window.location.href = `https://int.joucos.com/${
                              state.language === 'en' || state.language === ''
                                ? ''
                                : `${state.language}/`
                            }?ln=${state.country.toUpperCase()}`;
                            return;
                          }
                          const cd = queryFormat({
                            query: ProductQuery,
                            variables: {
                              // first: 8,
                              // endCursor: null,
                              id: `gid://shopify/Product/${pid}`,
                              selectedOptions: [],
                              // maxHeight: 1680,
                              maxWidth: 1680,
                            },
                            setting: {},
                            pageContext: {},
                          });

                          glStoreFrontFetch({
                            query: cd,
                            setFetchData: (data) => {
                              console.log('==> p111', data?.product?.handle);
                              const handle = data?.product?.handle;
                              const cdv = queryFormat({
                                query: ProductHandleQuery,
                                variables: {
                                  handle,
                                },
                                setting: {},
                                pageContext: {},
                              });
                              console.log('==> p112', cdv);
                              if (!handle || !cdv) {
                                window.location.href = `https://int.joucos.com/${
                                  state.language === 'en' ||
                                  state.language === ''
                                    ? ''
                                    : `${state.language}/`
                                }?ln=${state.country.toUpperCase()}`;
                                return;
                              }
                              // if (cdv) {
                              glStoreFrontFetch({
                                query: cdv,
                                customStoreDomain:
                                  process.env.GATSBY_INT_SHOPIFY_STORE_DOMAIN,
                                customStoreApiToken:
                                  process.env
                                    .GATSBY_INT_SHOPIFY_STOREFRONT_API_TOKEN,
                                setFetchData: (data) => {
                                  console.log(
                                    '==> p112',
                                    data?.productByHandle?.id
                                  );
                                  let id =
                                    data?.productByHandle?.id?.split('/');
                                  if (id) {
                                    id = id[id.length - 1];
                                    window.location.href = `https://int.joucos.com/${
                                      state.language === 'en' ||
                                      state.language === ''
                                        ? ''
                                        : `${state.language}/`
                                    }products/${id}/?ln=${state.country.toUpperCase()}`;
                                  } else {
                                    window.location.href = `https://int.joucos.com/${
                                      state.language === 'en' ||
                                      state.language === ''
                                        ? ''
                                        : `${state.language}/`
                                    }?ln=${state.country.toUpperCase()}`;
                                  }
                                },
                              });
                              // }
                            },
                          });
                        } catch (e) {
                          window.location.href = `https://int.joucos.com/${
                            state.language === 'en' || state.language === ''
                              ? ''
                              : `${state.language}/`
                          }?ln=${state.country.toUpperCase()}`;
                        }
                        // window.location.href = `https://int.joucos.com/?ln=${defaultCountry}&pid=${pid}`;
                      } else {
                        window.location.href = `https://int.joucos.com/${
                          state.language === 'en' || state.language === ''
                            ? ''
                            : `${state.language}/`
                        }${slash || ''}?ln=${state.country.toUpperCase()}`;
                      }
                      return;
                      // location.href = `https://int.joucos.com/${
                      //   state.language === 'en' || state.language === ''
                      //     ? ''
                      //     : `${state.language}/`
                      // }?ln=${state.country.toUpperCase()}`;
                      // return;
                    }
                  } else if (
                    window.location.href.includes('https://int.joucos.com')
                    // window.location.href.includes('http://localhost:8888')
                  ) {
                    if (CHINESE_LANG.includes(state.country)) {
                      localStorage.setItem('country', state.country || 'TW');
                      localStorage.setItem(
                        'hasShowLocationAndLanguage_2312',
                        'true'
                      );
                      let slash = window.location.href.split(
                        'https://int.joucos.com/'
                        // 'http://localhost:8888'
                      )[1];
                      if (slash.includes('?ln=')) {
                        slash = slash.split('?ln=')[0];
                      }
                      if (
                        slash.includes('zh') ||
                        slash.includes('ja') ||
                        slash.includes('ko')
                      ) {
                        slash = slash.slice(3);
                      }
                      // const slash = window.location.href.split('http://localhost:8888/')[1];
                      // alert(`https://joucos.com/${slash || ''}?ln=${defaultCountry}`);
                      // alert(slash);
                      if (window.location.href.includes('/products/')) {
                        try {
                          let pid = window.location.href.split('/products/')[1];
                          pid = pid.split('/')[0];
                          // alert(pid);
                          if (!pid) {
                            window.location.href = `https://joucos.com/${
                              state.language === 'en' || state.language === ''
                                ? ''
                                : `${state.language}/`
                            }?ln=${state.country.toUpperCase()}`;
                            return;
                          }
                          const cd = queryFormat({
                            query: ProductQuery,
                            variables: {
                              // first: 8,
                              // endCursor: null,
                              id: `gid://shopify/Product/${pid}`,
                              selectedOptions: [],
                              // maxHeight: 1680,
                              maxWidth: 1680,
                            },
                            setting: {},
                            pageContext: {},
                          });

                          glStoreFrontFetch({
                            query: cd,
                            setFetchData: (data) => {
                              console.log('==> p111', data?.product?.handle);
                              const handle = data?.product?.handle;
                              const cdv = queryFormat({
                                query: ProductHandleQuery,
                                variables: {
                                  handle,
                                },
                                setting: {},
                                pageContext: {},
                              });
                              if (!handle || !cdv) {
                                window.location.href = `https://joucos.com/${
                                  state.language === 'en' ||
                                  state.language === ''
                                    ? ''
                                    : `${state.language}/`
                                }?ln=${state.country.toUpperCase()}`;
                                return;
                              }
                              console.log('==> p112', cdv);
                              // if (cdv) {
                              glStoreFrontFetch({
                                query: cdv,
                                customStoreDomain:
                                  process.env.GATSBY_SHOPIFY_STORE_DOMAIN,
                                customStoreApiToken:
                                  process.env
                                    .GATSBY_SHOPIFY_STOREFRONT_API_TOKEN,
                                setFetchData: (data) => {
                                  let id =
                                    data?.productByHandle?.id?.split('/');
                                  if (id) {
                                    id = id[id.length - 1];
                                    window.location.href = `https://joucos.com/${
                                      state.language === 'en' ||
                                      state.language === ''
                                        ? ''
                                        : `${state.language}/`
                                    }products/${id}/?ln=${state.country.toUpperCase()}`;
                                  } else {
                                    window.location.href = `https://joucos.com/${
                                      state.language === 'en' ||
                                      state.language === ''
                                        ? ''
                                        : `${state.language}/`
                                    }?ln=${state.country.toUpperCase()}`;
                                  }
                                },
                              });
                              // }
                            },
                          });
                        } catch (e) {
                          // alert(e);
                          window.location.href = `https://joucos.com/${
                            state.language === 'en' || state.language === ''
                              ? ''
                              : `${state.language}/`
                          }?ln=${state.country.toUpperCase()}`;
                        }
                      } else {
                        window.location.href = `https://joucos.com/${
                          state.language === 'en' || state.language === ''
                            ? ''
                            : `${state.language}/`
                        }${slash || ''}?ln=${state.country.toUpperCase()}`;
                      }
                      // location.href = `https://joucos.com/${
                      //   state.language === 'en' || state.language === ''
                      //     ? ''
                      //     : `${state.language}/`
                      // }?ln=${state.country.toUpperCase()}`;
                      return;
                    }
                  }
                }

                console.log(
                  '==> state.country',
                  state.country,
                  country,
                  countryCompare[state.country]
                );
                // return;
                setState({
                  ...state,
                  changeMarket: true,
                });
                console.log('===> w2', state.country, country);
                if (
                  state.country !== country ||
                  (window.location.href.includes('https://joucos.com') &&
                    !CHINESE_LANG.includes(state.country)) ||
                  (window.location.href.includes('https://int.joucos.com') &&
                    CHINESE_LANG.includes(state.country))
                ) {
                  shouldReload = true;
                  if (user?.addresses?.nodes) {
                    // console.log('===> user?.addresses?.nodes',user?.addresses?.nodes);
                    let address = user?.addresses?.nodes?.find(
                      (address) =>
                        address.country === countryCompare[state.country]
                    );
                    if (!address) {
                      address = countryDefaultAddress[state.country];
                      const result = await glStoreFrontFetch({
                        query: mutationFormat({
                          query: AccountCreateAddressMutation,
                          variables: {
                            address,
                            customerAccessToken: user.token.accessToken,
                          },
                        }),
                        // setFetchData: setFetchResult,
                        // test: true,
                      });
                      if (result.customerAddressCreate.customerAddress) {
                        const defaultResult = await glStoreFrontFetch({
                          query: mutationFormat({
                            query: AccountDefaultAddressMutation,
                            variables: {
                              customerAccessToken: user.token.accessToken,
                              addressId:
                                result.customerAddressCreate.customerAddress.id,
                              first: 8,
                              endCursor: null,
                            },
                          }),
                          // setFetchData: setFetchResult,
                          // test: true,
                        });
                        updateUser({
                          address: {
                            $set: defaultResult.customerDefaultAddressUpdate
                              .customer.addresses.nodes,
                          },
                          defaultAddress: {
                            $set: defaultResult.customerDefaultAddressUpdate
                              .customer.defaultAddress,
                          },
                        });
                      }
                    } else {
                      const defaultResult = await glStoreFrontFetch({
                        query: mutationFormat({
                          query: AccountDefaultAddressMutation,
                          variables: {
                            customerAccessToken: user.token.accessToken,
                            addressId: address.id,
                            first: 8,
                            endCursor: null,
                          },
                        }),
                        // setFetchData: setFetchResult,
                        // test: true,
                      });
                      updateUser({
                        defaultAddress: {
                          $set: defaultResult.customerDefaultAddressUpdate
                            .customer.defaultAddress,
                        },
                      });
                    }
                  }

                  localStorage.removeItem('checkoutId');
                  // return;
                }
                updateSetting({
                  country: { $set: state.country },
                  countryInfo: {
                    $set: shopifyCountry[countryCompare[state.country]],
                  },
                  countryName: { $set: countryCompare[state.country] },
                });
                callback();
                setLanguage(state.language);
                setCountry(state.country);
                if (isBrowser()) {
                  localStorage.setItem('country', state.country || 'TW');
                  localStorage.setItem(
                    'hasShowLocationAndLanguage_2312',
                    'true'
                  );
                  if (state.language !== pageContext.language) {
                    if (state.language === 'zh') {
                      document.querySelector('[hreflang=zh]').click();
                    } else if (state.language === 'ja') {
                      document.querySelector('[hreflang=ja]').click();
                    } else if (state.language === 'ko') {
                      document.querySelector('[hreflang=ko]').click();
                    } else {
                      document.querySelector('[hreflang=en]').click();
                    }
                  } else if (shouldReload) {
                    location.reload();
                  }
                }
              },
              data: {
                state: {
                  language,
                  country,
                  changeMarket,
                },
                setState: {
                  setLanguage,
                  setCountry,
                  setChangeMarket,
                },
                title: (
                  <TransAni
                    useJSON={useJSON}
                    containerClass="GSText button max-width-130 margin-auto"
                    textClass="GSText s-15"
                    pageContext={pageContext}
                    i18nAniAlign="center"
                    type="horizontal"
                    borderAni={false}
                    i18nStyle={{
                      // zh: {
                      //   className: 's-13',
                      //   style: {
                      //     fontWeight: '500',
                      //     textAlign: 'center',
                      //     lineHeight: '1.7em',
                      //     width: '100%',
                      //   },
                      // },
                      'zh/ja/ko': {
                        className: 's-13',
                        style: {
                          fontWeight: '500',
                          textAlign: 'center',
                          lineHeight: '1.7em',
                          width: '100%',
                        },
                      },
                    }}
                    onClick={() => {}}
                  >
                    Language & Location
                  </TransAni>
                ),
                Component: (state, setState) => (
                  <>
                    <div
                      style={{
                        padding: '0px 25px 25px 25px',
                        // display: 'inline-block',
                      }}
                    >
                      <TransAni
                        useJSON={useJSON}
                        containerClass="GSText button margin-bottom-10"
                        textClass="GSText s-15"
                        pageContext={pageContext}
                        type="horizontal"
                        borderAni={false}
                        i18nStyle={{
                          // zh: {
                          //   className: 's-13',
                          //   style: {
                          //     fontWeight: '500',
                          //     // textAlign: 'left',
                          //     lineHeight: '1.7em',
                          //   },
                          // },
                          'zh/ja/ko': {
                            className: 's-13',
                            style: {
                              fontWeight: '500',
                              // textAlign: 'left',
                              lineHeight: '1.7em',
                            },
                          },
                        }}
                        onClick={() => {}}
                      >
                        Language
                      </TransAni>
                      <ul className="languages">
                        {pageContext?.i18n?.languages.map((lng) => {
                          if (!lngCovert[lng]) {
                            return null;
                          }
                          return (
                            // setState
                            <li
                              key={lng}
                              className="p-v-5 pointer"
                              onClick={() => {
                                // setState.setLanguage(lng);
                                setState({
                                  ...state,
                                  language: lng,
                                });
                                // setPopupInfo({
                                //   ...popupInfo,
                                //   active: true,
                                //   data: {
                                //     ...popupInfo.data,

                                //     state: {
                                //       language,
                                //       country,
                                //     },
                                //   },
                                // });
                                console.log('==> setState', setState, lng);
                              }}
                            >
                              <p
                                className={`SAText s-11 inline-block ${
                                  state?.language === lng && 'border-bottom'
                                }`}
                              >
                                {lngCovert[lng]}
                              </p>
                            </li>
                            // <I18nLink
                            //   // ref={(link) => {
                            //   //   console.log(link);
                            //   //   refs.current[lng] = link;
                            //   // }}
                            //   to={path}
                            //   language={lng}
                            // >
                            //   <li key={lng} className="p-v-5">
                            //     <p
                            //       className={`SAText s-11 inline-block ${
                            //         state.language === lng && 'border-bottom'
                            //       }`}
                            //     >
                            //       {lng.toUpperCase()}
                            //     </p>
                            //   </li>
                            // </I18nLink>
                          );
                        })}
                      </ul>
                      <TransAni
                        useJSON={useJSON}
                        containerClass="GSText button margin-bottom-10 margin-top-25"
                        textClass="GSText s-15"
                        pageContext={pageContext}
                        type="horizontal"
                        borderAni={false}
                        i18nStyle={{
                          // zh: {
                          //   className: 's-13',
                          //   style: {
                          //     fontWeight: '500',
                          //     // textAlign: 'left',
                          //     lineHeight: '1.7em',
                          //   },
                          // },
                          'zh/ja/ko': {
                            className: 's-13',
                            style: {
                              fontWeight: '500',
                              // textAlign: 'left',
                              lineHeight: '1.7em',
                            },
                          },
                        }}
                        onClick={() => {}}
                      >
                        Location
                      </TransAni>
                      <ul className="languages">
                        <li
                          key={setting.country.toUpperCase()}
                          className="p-v-5 pointer"
                          onClick={() => {
                            setState({
                              ...state,
                              country: setting.country.toUpperCase(),
                            });
                          }}
                        >
                          <p
                            className={`SAText s-11 inline-block ${
                              state?.country?.toUpperCase() ===
                                setting.country.toUpperCase() && 'border-bottom'
                            }`}
                          >
                            {countryCompare[setting.country.toUpperCase()]}
                          </p>
                          {/* <Trans>{countryCompare[mkt]}</Trans> */}
                        </li>
                        {setting.markets.map((mkt) => {
                          if (setting.country.toUpperCase() === mkt) {
                            return null;
                          }
                          return (
                            <li
                              key={mkt}
                              className="p-v-5 pointer"
                              onClick={() => {
                                setState({
                                  ...state,
                                  country: mkt,
                                });
                              }}
                            >
                              <p
                                className={`SAText s-11 inline-block ${
                                  state?.country?.toUpperCase() === mkt &&
                                  'border-bottom'
                                }`}
                              >
                                {countryCompare[mkt]}
                              </p>
                              {/* <Trans>{countryCompare[mkt]}</Trans> */}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </>
                ),
              },
            });
          }}
        >
          {setting.country.toUpperCase() === 'GB'
            ? 'UK'
            : setting.country.toUpperCase()}{' '}
          (TWD) | {pageContext?.i18n?.language?.toUpperCase()}
        </p>
      </div>
      <div className="term-block">
        {/* <TransAni
          useJSON={useJSON}
          containerClass="GSText button opacity0 pointer"
          textClass="GSText s-13"
          pageContext={pageContext}
          type="horizontal"
          borderAni={false}
          i18nStyle={{
            zh: {
              className: 's-11',
              style: {
                fontWeight: '500',
                // textAlign: 'left',
                lineHeight: '1.7em',
              },
            },
          }}
          onClick={() => {}}
        >
          Subscribe to Newsletter
        </TransAni>
        <TransAni
          useJSON={useJSON}
          containerClass="GSText button opacity0 pointer"
          textClass="GSText s-13"
          pageContext={pageContext}
          type="horizontal"
          borderAni={false}
          i18nStyle={{
            zh: {
              className: 's-11',
              style: {
                fontWeight: '500',
                // textAlign: 'left',
                lineHeight: '1.7em',
              },
            },
          }}
          onClick={() => {}}
        >
          Subscribe to Newsletter
        </TransAni>
        <TransAni
          useJSON={useJSON}
          containerClass="GSText button pointer"
          textClass="GSText s-13"
          pageContext={pageContext}
          type="horizontal"
          borderAni={false}
          i18nStyle={{
            zh: {
              className: 's-11',
              style: {
                fontWeight: '500',
                // textAlign: 'left',
                lineHeight: '1.7em',
              },
            },
          }}
          onClick={() => {}}
        >
          Subscribe to Newsletter
        </TransAni> */}
      </div>
      <div className="term-block">
        {/* <TransAni
          containerClass="GSText button opacity0"
          textClass="GSText s-13"
          pageContext={pageContext}
          type="horizontal"
          borderAni={false}
          i18nStyle={{
            zh: {
              className: 's-11',
              style: {
                fontWeight: '500',
                // textAlign: 'left',
                lineHeight: '1.7em',
              },
            },
          }}
          onClick={() => {}}
        >
          © 2023 JOUCOS, All Rights Reserved.
        </TransAni> */}
        <TransAni
          containerClass="GSText button opacity0"
          textClass="GSText s-13"
          pageContext={pageContext}
          type="horizontal"
          borderAni={false}
          i18nStyle={{
            // zh: {
            //   className: 's-11',
            //   style: {
            //     fontWeight: '500',
            //     // textAlign: 'left',
            //     lineHeight: '1.7em',
            //   },
            // },
            'zh/ja/ko': {
              className: 's-11',
              style: {
                fontWeight: '500',
                // textAlign: 'left',
                lineHeight: '1.7em',
              },
            },
          }}
          onClick={() => {}}
        >
          © 2024 JOUCOS, All Rights Reserved.
        </TransAni>
        <p
          className="GSText button s-13"
          // containerClass="GSText button"
          // textClass="GSText s-13"
          // pageContext={pageContext}
          // type="horizontal"
          // borderAni={false}
          // i18nStyle={{
          //   zh: {
          //     className: 's-11',
          //     style: {
          //       fontWeight: '500',
          //       // textAlign: 'left',
          //       lineHeight: '1.7em',
          //     },
          //   },
          // }}
          // onClick={() => {}}
        >
          © 2024 JOUCOS, All Rights Reserved.
        </p>
      </div>
      <ul className="languages display-none">
        {pageContext?.i18n?.languages.map((lng) => {
          let langPath = path;
          if (language !== 'en') {
            // path.replace('[id]', params.id);
            langPath = langPath.replace(`/${language}`, '');
          }
          //  else {
          //   if (lng !== 'en') {
          //     langPath = `/${lng}` + langPath
          //   }
          // }

          return (
            <li className="pointer" key={lng}>
              <I18nLink
                // ref={(link) => {
                //   console.log(link);
                //   refs.current[lng] = link;
                // }}
                to={langPath}
                language={lng}
              >
                {lng}
              </I18nLink>
            </li>
          );
        })}
      </ul>
      {/* <ul className="languages">
        {setting.markets.map((mkt) => (
          <li
            key={mkt}
            onClick={() => {
              console.log(mkt.toUpperCase(), setting.country.toUpperCase());
              if (mkt.toUpperCase() === setting.country.toUpperCase()) {
                return;
              }
              if (mkt === 'TW') {
                // console.log(refs.current.ZH);
                document.querySelector('[hreflang=zh]').click();
                console.log('zh', document.querySelector('[hreflang=zh]'));
              } else {
                // console.log(refs.current.EN);
                document.querySelector('[hreflang=en]').click();
                console.log('en', document.querySelector('[hreflang=zh]'));
              }
              // navigate(`${origin}${tailUrl}`, {
              //   replace: false,
              // });
              updateSetting({
                country: { $set: mkt },
                countryInfo: { $set: shopifyCountry[countryCompare[mkt]] },
                countryName: { $set: countryCompare[mkt] },
              });
            }}
          >
            <Trans>{countryCompare[mkt]}</Trans>
          </li>
        ))}
      </ul> */}
    </Foot>
  );
}
