export function timeCountdown(time = new Date().getTime()) {
  const currentTime = new Date().getTime();
  // alert(time - currentTime);
  if (currentTime <= time) {
    const diff = time - currentTime;
    if (diff < 86400000) {
      return new Date(diff).toISOString().substring(11, 19);
    }
    const hour = Math.floor(diff / (60 * 60 * 1000));
    if (hour / 24 > 2) {
      return `${Math.floor(hour / 24)}:${hour % 24}:${new Date(diff)
        .toISOString()
        .substring(14, 19)}`;
    }
    return `${hour}:${new Date(diff).toISOString().substring(14, 19)}`;
  }
}

// https://timeapi.io/api/Time/current/zone?timeZone=Europe/Amsterdam
