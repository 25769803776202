import React, { useEffect, useState } from 'react';

import useStoreFrontAPI, { createQuery } from './useStoreFrontAPI';
import queryFormat from '../helpers/queryFormat';

export default function useStoreFrontQueryData({
  defaultData = {},
  query,
  variables = {},
  setting,
  pageContext,
  stopFetch,
}) {
  // const [queryData, setQueryData] = useState(defaultData);
  const [loading, setLoading] = useState(false);
  const [cq, setCQ] = useState(
    queryFormat({
      query,
      variables,
      setting,
      pageContext,
    })
  );
  const { fetchData } = useStoreFrontAPI({
    query: cq,
    setLoading,
    stopFetch,
  });

  useEffect(() => {
    // setCQ({
    //   query,
    //   variables,
    //   setting,
    //   pageContext,
    // });
    const timer = setTimeout(() => {
      console.log('==111', variables);
      setCQ(
        queryFormat({
          query,
          variables,
          setting,
          pageContext,
        })
      );
    }, 50);
    return () => {
      clearTimeout(timer);
    };
  }, [
    query,
    JSON.stringify(variables),
    variables.selectedOptions,
    // variables.query,
    setting.country,
    pageContext.language,
  ]);

  // console.log('==> pood fetchData', fetchData);
  // useEffect(() => {
  //   setQueryData()
  // }, [cq]);

  return {
    fetchData: fetchData || defaultData,
    loading,
    // fetchData: {},
  };
}
