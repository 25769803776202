export const AllProductsQuery = `
  query AllProducts( $country: CountryCode $language: LanguageCode $first: Int $last: Int $startCursor: String $endCursor: String )
  @inContext(country: $country, language: $language) {
    products(first: $first, last: $last, before: $startCursor, after: $endCursor) {
      nodes { title }
      pageInfo { hasPreviousPage hasNextPage startCursor endCursor }
    }    
  }
`;

export const ProductsWithFilterQuery = `
  query ProductsWithFilterQuery( $country: CountryCode $language: LanguageCode $first: Int $last: Int $startCursor: String $endCursor: String $query: String)
  @inContext(country: $country, language: $language) {
    products(first: $first, last: $last, before: $startCursor, after: $endCursor, query: $query) {
      nodes {
        availableForSale
        compareAtPriceRange {
          maxVariantPrice {
            amount
            currencyCode
          }
          minVariantPrice {
            amount
            currencyCode
          }
        }
        createdAt
        description(truncateAt: 10)
        descriptionHtml
        mayLike: metafield(key: "related_products_display", namespace: "shopify--discovery--product_recommendation") {
          value
        }
        enable: metafield(key: "enable", namespace: "custom") {
          value
        }
        colorText: metafield(key: "colortext", namespace: "custom") {
          value
        }
        saleAt: metafield(key: "saleat", namespace: "custom") {
          value
        }
        previewAt: metafield(key: "previewat", namespace: "custom") {
          value
        }
        vipSaleAt: metafield(key: "vipsaleat", namespace: "custom") {
          value
        }
        vipPreviewAt: metafield(key: "vippreviewat", namespace: "custom") {
          value
        }
        size: metafield(key: "size", namespace: "custom") {
          value
        }
        color: metafield(key: "color", namespace: "custom") {
          value
        }
        sketchSrc: metafield(key: "sketchsrc", namespace: "custom") {
          value
        }
        videoSrc: metafield(key: "videosrc", namespace: "custom") {
          value
        }
        showVideo: metafield(key: "showvideo", namespace: "custom") {
          value
        }
        showImageIndex: metafield(key: "showimageindex", namespace: "custom") {
          value
        }
        collectionImage: metafield(key: "collectionimage", namespace: "custom") {
          value
        }
        desc: metafield(key: "desc", namespace: "custom") {
          value
        }
        lookbook: metafield(key: "lookbook", namespace: "custom") {
          value
        }
        collectionDesc: metafield(key: "collectiondesc", namespace: "custom") {
          value
        }
        upgrade: metafield(key: "upgrade", namespace: "custom") {
          value
        }
        upgradeMatch: metafield(key: "upgradematch", namespace: "custom") {
          value
        }
        images (first: 10) {
          nodes {
            altText
            height
            id
            url(transform: {maxHeight: 768 maxWidth: 768})
            width
          }
        }
        featuredImage {
          altText
          height
          id
          url(transform: {maxHeight: 768 maxWidth: 768})
          width
        }
        handle
        id
        isGiftCard
        onlineStoreUrl
        options(first: 8)  {
          
            id
            name
            values
          
        }
        priceRange {
          maxVariantPrice {
            amount
            currencyCode
          }
          minVariantPrice {
            amount
            currencyCode
          }
        }
        productType
        publishedAt
        requiresSellingPlan
        seo {
          description
          title
        }
        tags
        title
        totalInventory
        updatedAt
        variants(first: 10) {
          nodes {
            availableForSale
            barcode
            compareAtPrice {
              amount
              currencyCode
            }
            currentlyNotInStock
            id
            image {
              altText
                height
                id
                url
                
                width
            }
            price {
              amount
              currencyCode
            }
            
            quantityAvailable
            requiresShipping
            selectedOptions {
              name
              value
            }
            sku
            title
            unitPrice {
              amount
              currencyCode
            }
            unitPriceMeasurement {
              measuredType
              quantityUnit
              quantityValue
              referenceUnit
              referenceValue
            }
            weight
            weightUnit
            sellingPlanAllocations(first: 24) {
              nodes {
                checkoutChargeAmount{
                  amount
                  currencyCode
                }
                priceAdjustments {
                  compareAtPrice{
                    amount
                    currencyCode
                  }
                  perDeliveryPrice{
                    amount
                    currencyCode
                  }
                  price{
                    amount
                    currencyCode
                  }
                  unitPrice{
                    amount
                    currencyCode
                  }
                }
                remainingBalanceChargeAmount{
                  amount
                  currencyCode
                }
                sellingPlan {
                  checkoutCharge {
                    type
                    value
                  }
                  description
                  id
                  name
                  options {
                    name
                    value
                  }
                  priceAdjustments {
                    adjustmentValue
                    orderCount
                  }
                  recurringDeliveries
                }
              }
            }
            storeAvailability(first: 24) {
              nodes {
                available
                location {
                  address {
                    address1
                    address2
                    city
                    country
                    
                    latitude
                    longitude
                    phone
                    province
                    provinceCode
                    zip
                  }
                }
                pickUpTime
              }
            }
          }
        }
        variantBySelectedOptions(selectedOptions: [{name: "顏色", value: "卡其色"}, {name: "大小", value: "S"}]) {
          availableForSale
          barcode
          compareAtPrice {
            amount
            currencyCode
          }
          currentlyNotInStock
          id
          image {
            altText
              height
              id
              url
              
              width
          }
          price {
            amount
            currencyCode
          }
          
          quantityAvailable
          requiresShipping
          selectedOptions {
            name
            value
          }
          sku
          title
          unitPrice {
            amount
            currencyCode
          }
          unitPriceMeasurement {
            measuredType
            quantityUnit
            quantityValue
            referenceUnit
            referenceValue
          }
          weight
          weightUnit
          sellingPlanAllocations(first: 24) {
            nodes {
              checkoutChargeAmount{
                amount
                currencyCode
              }
              priceAdjustments {
                compareAtPrice{
                  amount
                  currencyCode
                }
                perDeliveryPrice{
                  amount
                  currencyCode
                }
                price{
                  amount
                  currencyCode
                }
                unitPrice{
                  amount
                  currencyCode
                }
              }
              remainingBalanceChargeAmount{
                amount
                currencyCode
              }
              sellingPlan {
                checkoutCharge {
                  type
                  value
                }
                description
                id
                name
                options {
                  name
                  value
                }
                priceAdjustments {
                  adjustmentValue
                  orderCount
                }
                recurringDeliveries
              }
            }
          }
          storeAvailability(first: 24) {
            nodes {
              available
              location {
                address {
                  address1
                  address2
                  city
                  country
                  
                  latitude
                  longitude
                  phone
                  province
                  provinceCode
                  zip
                }
              }
              pickUpTime
            }
          }
        } 
      }
      pageInfo { hasPreviousPage hasNextPage startCursor endCursor }
    }    
  }
`;

export const ProductQuery = `
  query ProductQuery( $country: CountryCode $language: LanguageCode $id: ID! $selectedOptions: [SelectedOptionInput!]! $maxHeight: Int $maxWidth: Int)
  @inContext(country: $country, language: $language) {
    product(id: $id) { 
      availableForSale
      compareAtPriceRange {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      createdAt
      description(truncateAt: 10)
      descriptionHtml
      mayLike: metafield(key: "related_products_display", namespace: "shopify--discovery--product_recommendation") {
        value
      }
      enable: metafield(key: "enable", namespace: "custom") {
        value
      }
      colorText: metafield(key: "colortext", namespace: "custom") {
        value
      }
      saleAt: metafield(key: "saleat", namespace: "custom") {
        value
      }
      previewAt: metafield(key: "previewat", namespace: "custom") {
        value
      }
      vipSaleAt: metafield(key: "vipsaleat", namespace: "custom") {
        value
      }
      vipPreviewAt: metafield(key: "vippreviewat", namespace: "custom") {
        value
      }
      size: metafield(key: "size", namespace: "custom") {
        value
      }
      color: metafield(key: "color", namespace: "custom") {
        value
      }
      sketchSrc: metafield(key: "sketchsrc", namespace: "custom") {
        value
      }
      videoSrc: metafield(key: "videosrc", namespace: "custom") {
        value
      }
      showVideo: metafield(key: "showvideo", namespace: "custom") {
        value
      }
      showImageIndex: metafield(key: "showimageindex", namespace: "custom") {
        value
      }
      collectionImage: metafield(key: "collectionimage", namespace: "custom") {
        value
      }
      desc: metafield(key: "desc", namespace: "custom") {
        value
      }
      lookbook: metafield(key: "lookbook", namespace: "custom") {
        value
      }
      collectionDesc: metafield(key: "collectiondesc", namespace: "custom") {
        value
      }
      upgrade: metafield(key: "upgrade", namespace: "custom") {
        value
      }
      upgradeMatch: metafield(key: "upgradematch", namespace: "custom") {
        value
      }
      images (first: 10) {
        nodes {
          altText
          height
          id
          url(transform: {maxHeight: $maxHeight maxWidth: $maxWidth})
          width
        }
      }
      featuredImage {
        altText
        height
        id
        url(transform: {maxHeight: $maxHeight maxWidth: $maxWidth})
        width
      }
      handle
      id
      isGiftCard
      onlineStoreUrl
      options(first: 8)  {
        
          id
          name
          values
        
      }
      priceRange {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      productType
      publishedAt
      requiresSellingPlan
      seo {
        description
        title
      }
      tags
      title
      totalInventory
      updatedAt
      variants(first: 10) {
        nodes {
          availableForSale
          barcode
          compareAtPrice {
            amount
            currencyCode
          }
          currentlyNotInStock
          id
          image {
            altText
              height
              id
              url
              
              width
          }
          price {
            amount
            currencyCode
          }
          
          quantityAvailable
          requiresShipping
          selectedOptions {
            name
            value
          }
          sku
          title
          unitPrice {
            amount
            currencyCode
          }
          unitPriceMeasurement {
            measuredType
            quantityUnit
            quantityValue
            referenceUnit
            referenceValue
          }
          weight
          weightUnit
          sellingPlanAllocations(first: 24) {
            nodes {
              checkoutChargeAmount{
                amount
                currencyCode
              }
              priceAdjustments {
                compareAtPrice{
                  amount
                  currencyCode
                }
                perDeliveryPrice{
                  amount
                  currencyCode
                }
                price{
                  amount
                  currencyCode
                }
                unitPrice{
                  amount
                  currencyCode
                }
              }
              remainingBalanceChargeAmount{
                amount
                currencyCode
              }
              sellingPlan {
                checkoutCharge {
                  type
                  value
                }
                description
                id
                name
                options {
                  name
                  value
                }
                priceAdjustments {
                  adjustmentValue
                  orderCount
                }
                recurringDeliveries
              }
            }
          }
          storeAvailability(first: 24) {
            nodes {
              available
              location {
                address {
                  address1
                  address2
                  city
                  country
                  
                  latitude
                  longitude
                  phone
                  province
                  provinceCode
                  zip
                }
              }
              pickUpTime
            }
          }
        }
      }
      variantBySelectedOptions(selectedOptions: $selectedOptions) {
        availableForSale
        barcode
        compareAtPrice {
          amount
          currencyCode
        }
        currentlyNotInStock
        id
        image {
          altText
            height
            id
            url
            
            width
        }
        price {
          amount
          currencyCode
        }
        
        quantityAvailable
        requiresShipping
        selectedOptions {
          name
          value
        }
        sku
        title
        unitPrice {
          amount
          currencyCode
        }
        unitPriceMeasurement {
          measuredType
          quantityUnit
          quantityValue
          referenceUnit
          referenceValue
        }
        weight
        weightUnit
        sellingPlanAllocations(first: 24) {
          nodes {
            checkoutChargeAmount{
              amount
              currencyCode
            }
            priceAdjustments {
              compareAtPrice{
                amount
                currencyCode
              }
              perDeliveryPrice{
                amount
                currencyCode
              }
              price{
                amount
                currencyCode
              }
              unitPrice{
                amount
                currencyCode
              }
            }
            remainingBalanceChargeAmount{
              amount
              currencyCode
            }
            sellingPlan {
              checkoutCharge {
                type
                value
              }
              description
              id
              name
              options {
                name
                value
              }
              priceAdjustments {
                adjustmentValue
                orderCount
              }
              recurringDeliveries
            }
          }
        }
        storeAvailability(first: 24) {
          nodes {
            available
            location {
              address {
                address1
                address2
                city
                country
                
                latitude
                longitude
                phone
                province
                provinceCode
                zip
              }
            }
            pickUpTime
          }
        }
      } 
    }    
  }
`;

// "sku:$sku"
export const ProductHandleQuery = `
query ProductHandleQuery( $handle: String!) {
  productByHandle(handle: $handle) {
    id
  }
}`;

export const ProductDetailHandleQuery = `
  query ProductQuery( $country: CountryCode $language: LanguageCode $handle: String! $maxHeight: Int $maxWidth: Int)
  @inContext(country: $country, language: $language) {
    productByHandle(handle: $handle) {
      availableForSale
      compareAtPriceRange {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      createdAt
      description(truncateAt: 10)
      descriptionHtml
      mayLike: metafield(key: "related_products_display", namespace: "shopify--discovery--product_recommendation") {
        value
      }
      enable: metafield(key: "enable", namespace: "custom") {
        value
      }
      colorText: metafield(key: "colortext", namespace: "custom") {
        value
      }
      saleAt: metafield(key: "saleat", namespace: "custom") {
        value
      }
      previewAt: metafield(key: "previewat", namespace: "custom") {
        value
      }
      vipSaleAt: metafield(key: "vipsaleat", namespace: "custom") {
        value
      }
      vipPreviewAt: metafield(key: "vippreviewat", namespace: "custom") {
        value
      }
      size: metafield(key: "size", namespace: "custom") {
        value
      }
      color: metafield(key: "color", namespace: "custom") {
        value
      }
      sketchSrc: metafield(key: "sketchsrc", namespace: "custom") {
        value
      }
      videoSrc: metafield(key: "videosrc", namespace: "custom") {
        value
      }
      showVideo: metafield(key: "showvideo", namespace: "custom") {
        value
      }
      showImageIndex: metafield(key: "showimageindex", namespace: "custom") {
        value
      }
      collectionImage: metafield(key: "collectionimage", namespace: "custom") {
        value
      }
      desc: metafield(key: "desc", namespace: "custom") {
        value
      }
      lookbook: metafield(key: "lookbook", namespace: "custom") {
        value
      }
      collectionDesc: metafield(key: "collectiondesc", namespace: "custom") {
        value
      }
      upgrade: metafield(key: "upgrade", namespace: "custom") {
        value
      }
      upgradeMatch: metafield(key: "upgradematch", namespace: "custom") {
        value
      }
      images (first: 10) {
        nodes {
          altText
          height
          id
          url(transform: {maxHeight: $maxHeight maxWidth: $maxWidth})
          width
        }
      }
      featuredImage {
        altText
        height
        id
        url(transform: {maxHeight: $maxHeight maxWidth: $maxWidth})
        width
      }
      handle
      id
      isGiftCard
      onlineStoreUrl
      options(first: 8)  {
        
          id
          name
          values
        
      }
      priceRange {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      productType
      publishedAt
      requiresSellingPlan
      seo {
        description
        title
      }
      tags
      title
      totalInventory
      updatedAt
      variants(first: 10) {
        nodes {
          availableForSale
          barcode
          compareAtPrice {
            amount
            currencyCode
          }
          currentlyNotInStock
          id
          image {
            altText
              height
              id
              url
              
              width
          }
          price {
            amount
            currencyCode
          }
          
          quantityAvailable
          requiresShipping
          selectedOptions {
            name
            value
          }
          sku
          title
          unitPrice {
            amount
            currencyCode
          }
          unitPriceMeasurement {
            measuredType
            quantityUnit
            quantityValue
            referenceUnit
            referenceValue
          }
          weight
          weightUnit
          sellingPlanAllocations(first: 24) {
            nodes {
              checkoutChargeAmount{
                amount
                currencyCode
              }
              priceAdjustments {
                compareAtPrice{
                  amount
                  currencyCode
                }
                perDeliveryPrice{
                  amount
                  currencyCode
                }
                price{
                  amount
                  currencyCode
                }
                unitPrice{
                  amount
                  currencyCode
                }
              }
              remainingBalanceChargeAmount{
                amount
                currencyCode
              }
              sellingPlan {
                checkoutCharge {
                  type
                  value
                }
                description
                id
                name
                options {
                  name
                  value
                }
                priceAdjustments {
                  adjustmentValue
                  orderCount
                }
                recurringDeliveries
              }
            }
          }
          storeAvailability(first: 24) {
            nodes {
              available
              location {
                address {
                  address1
                  address2
                  city
                  country
                  
                  latitude
                  longitude
                  phone
                  province
                  provinceCode
                  zip
                }
              }
              pickUpTime
            }
          }
        }
      }
    }    
  }
`;

// localization {
//   language{ #The language of the active localized experience.
//     isoCode
//     name
//   }
//   availableCountries {
//     currency {
//       isoCode
//       name
//       symbol
//     }
//     isoCode
//     name
//     unitSystem
//   }
//   country { #The currency of the active localized experience.
//     currency {
//       isoCode
//       name
//       symbol
//     }
//     isoCode
//     name
//     unitSystem
//   }
// }
