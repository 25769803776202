import React, { useState, useEffect, useRef } from 'react';
import { glStoreFrontFetch } from '../apis-browser';
// require('dotenv').config();

// const productQuery = `
// {
//   "query": "query AllProducts( $country: CountryCode $language: LanguageCode $first: Int $last: Int $startCursor: String $endCursor: String ) @inContext(country: $country, language: $language) { products(first: $first, last: $last, before: $startCursor, after: $endCursor) { nodes { title } pageInfo { hasPreviousPage hasNextPage startCursor endCursor } } }",
//   "variables":{"first":8,"endCursor":null,"country":"US","language":"EN"}
// }`;

export function createQuery({
  query,
  variables,
  setting = {},
  pageContext = {},
}) {
  // console.log(
  //   '==> aaaa',
  //   `{"query": ${JSON.stringify(query)}, "variables": ${JSON.stringify({
  //     ...variables,
  //     country: 'US',
  //     language: 'EN',
  //   })}}`
  // );
  if (!setting.country) {
    setting.country = 'TW';
  }
  if (!pageContext.language) {
    pageContext.language = 'EN';
  }
  if (pageContext.language === 'zh') {
    pageContext.language = 'zh_tw';
  }
  const language = pageContext.language.toUpperCase();
  const country = setting.country.toUpperCase();
  return `{"query": ${JSON.stringify(query)}, "variables": ${JSON.stringify({
    country,
    language,
    ...variables,
  })}}`;
}

export default function useStoreFrontAPI({
  data,
  query,
  fetchOne = false,
  setLoading = () => {},
  stopFetch,
  // variablesCompleted,
}) {
  const hasFetched = useRef(false);
  const [fetchData, setFetchData] = useState(data);
  // const [loading, setLoading] = useState(true);
  useEffect(() => {
    // console.log('===> variablesCompleted', variablesCompleted);
    if ((hasFetched.current && fetchOne) || stopFetch) {
      return;
    }
    console.log('===> useStoreFrontAPI query', query);
    glStoreFrontFetch({
      query,
      setFetchData,
      setLoading,
    });
    // setLoading(true);
    // fetch(`https://${process.env.GATSBY_SHOPIFY_STORE_DOMAIN}/api/graphql`, {
    //   method: 'POST',
    //   headers: {
    //     // Authorization: `Bearer %ACCESS_TOKEN%`,
    //     'content-type': 'application/json',
    //     'X-Shopify-Storefront-Access-Token':
    //       process.env.GATSBY_SHOPIFY_STOREFRONT_API_TOKEN,
    //   },
    //   // body: JSON.stringify({ productQuery }),
    //   body: query,
    // })
    //   .then((response) => response.json())
    //   .then((result) => {
    //     console.log('===> result', result);
    //     setFetchData(result.data);
    //     setLoading(false);
    //   })
    //   .catch((e) => {
    //     setLoading(false);
    //   });
    hasFetched.current = true;
  }, [query]);

  return {
    fetchData,
  };
}
