import React from 'react';
import styled from 'styled-components';
import { Trans } from '../../plugins/gatsby-plugin-react-i18next-customize';

const InputContainer = styled.div`
  /* > div { */
  width: 100%;
  padding: 0px;
  padding-bottom: 10px;
  margin-bottom: 30px;
  position: relative;
  &.default-input {
    opacity: 1 !important;
  }
  &.active-input {
    opacity: 1;
    span {
      transform: translateY(-15px) scale(0.7);
    }
  }
  &.blur-input {
    opacity: 0.5;
  }
  &:last-child {
    margin-bottom: 0px;
  }
  span {
    position: absolute;
    top: 3px;
    left: 0;
    pointer-events: none;
    transform-origin: top left;
    transition: transform 0.3s ease;
  }
  > p {
    position: absolute;
    bottom: -5px;
    left: 0;
    pointer-events: none;
    color: var(--redColor);
    transform-origin: top left;
    transform: scale(0.9);
  }
  &.error-input {
    span {
      color: var(--redColor) !important;
    }
    input {
      border-bottom: 1px solid var(--redColor) !important;
    }
  }
  input {
    width: 100%;
    padding: 0px;
    padding-bottom: 10px;
    border: 0px solid #000;
    border-bottom: 1px solid #000;
    background: transparent;
    /* margin-bottom: 30px; */
    border-radius: 0;
    /* &:last-child {
        margin-bottom: 0px;
      } */
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: black;
      opacity: 1; /* Firefox */
    }
    &:focus {
      outline: none;
    }
    &.has-tail {
      padding-right: 25px !important;
    }
    /* } */
  }
  .tail {
    position: absolute;
    right: 0px;
    top: 0px;
  }
`;

export default function Input(props) {
  const {
    containerStyle = {},
    containerClass = '',
    inputStyle = {},
    inputClass = '',
    onFocus = () => {},
    onBlur = () => {},
    onChange = () => {},
    placeholderStyle = {},
    placeholder = '',
    id = '',
    tailEle = false,
    type = '',
    defaultValue = '',
    disabled = false,
    value = false,
  } = props;
  // console.log('aa');
  const v = value ? { value } : {};
  return (
    <InputContainer className={containerClass} style={containerStyle}>
      {/* <div
        className={`${inputAnchor === -1 && 'default-input'} ${
          inputAnchor === 0
            ? 'active-input'
            : input.firstName
            ? 'active-input'
            : 'blur-input'
        } ${errors.firstName && 'error-input'}`}
      > */}
      {placeholder && (
        <span className="s-13 GSText" style={placeholderStyle}>
          <Trans>{placeholder}</Trans>
        </span>
      )}
      <input
        id={id}
        className={`s-13 GSText ${inputClass} ${tailEle && 'has-tail'}`}
        type={type}
        style={inputStyle}
        onFocus={onFocus}
        // placeholder="名 First Name"
        onBlur={onBlur}
        onChange={onChange}
        defaultValue={defaultValue}
        disabled={disabled}
        {...v}
      />
      {tailEle && <div className="tail">{tailEle()}</div>}
      {/* </div> */}
    </InputContainer>
  );
}
