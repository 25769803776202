const env = process.env.NODE_ENV === 'development' ? 'dev' : 'prod';

// const productMetaFilter = env !== 'dev' ? `` : '{tag:"prod"}';
// `{
//   productMetafield: {
//     namespace: "custom",
//     key: "enable_test",
//     value: "true"
//   }
// }`
// console.log('===> productMetaFilter', productMetaFilter);
// filters: [{
//   // productMetafield:{
//   //   namespace:"product_care",
//   //   key:"drying_instructions",
//   //   value:"tumble dry"
//   // }
//   productMetafield:{
//     namespace:"custom",
//     key:"enable",
//     value: true
//   }
// }]

// export const AllCollectionsQuery = `
//   query AllCollectionsQuery( $country: CountryCode $language: LanguageCode $first: Int $last: Int $startCursor: String $endCursor: String )
//   @inContext(country: $country, language: $language) {
//     collections(first: $first, last: $last, before: $startCursor, after: $endCursor) {
//       nodes {
//         description(truncateAt: 10)
//         descriptionHtml
//         handle
//         id
//         image {
//           altText
//             height
//             id
//             url

//             width
//         }
//         onlineStoreUrl
//         seo {
//           description
//           title
//         }
//         title
//         updatedAt
//         products(first: $first, last: $last, before: $startCursor, after: $endCursor) {
//           nodes {
//             availableForSale
//             compareAtPriceRange {
//               maxVariantPrice {
//                 amount
//                 currencyCode
//               }
//               minVariantPrice {
//                 amount
//                 currencyCode
//               }
//             }
//             createdAt
//             description(truncateAt: 10)
//             descriptionHtml
//             images (first: $first, last: $last, before: $startCursor, after: $endCursor) {
//               nodes {
//                 altText
//                 height
//                 id
//                 url(transform: {maxHeight: 300 maxWidth: 300})
//                 width
//               }
//             }
//             featuredImage {
//               altText
//               height
//               id
//               url
//               width
//             }
//             handle
//             id
//             isGiftCard
//             onlineStoreUrl
//             options(first: 8)  {

//                 id
//                 name
//                 values

//             }
//             priceRange {
//               maxVariantPrice {
//                 amount
//                 currencyCode
//               }
//               minVariantPrice {
//                 amount
//                 currencyCode
//               }
//             }
//             productType
//             publishedAt
//             requiresSellingPlan
//             seo {
//               description
//               title
//             }
//             tags
//             title
//             totalInventory
//             updatedAt
//             variantBySelectedOptions(selectedOptions: [{name: "大小", value: "S"}, {name: "大小", value: "M"}]) {
//               availableForSale
//               barcode
//               compareAtPrice {
//                 amount
//                 currencyCode
//               }
//               currentlyNotInStock
//               id
//               image {
//                 altText
//                   height
//                   id
//                   url

//                   width
//               }
//               price {
//                 amount
//                 currencyCode
//               }

//               quantityAvailable
//               requiresShipping
//               selectedOptions {
//                 name
//                 value
//               }
//               sku
//               title
//               unitPrice {
//                 amount
//                 currencyCode
//               }
//               unitPriceMeasurement {
//                 measuredType
//                 quantityUnit
//                 quantityValue
//                 referenceUnit
//                 referenceValue
//               }
//               weight
//               weightUnit
//               sellingPlanAllocations(first: $first, last: $last, before: $startCursor, after: $endCursor) {
//                 nodes {
//                   checkoutChargeAmount{
//                     amount
//                     currencyCode
//                   }
//                   priceAdjustments {
//                     compareAtPrice{
//                       amount
//                       currencyCode
//                     }
//                     perDeliveryPrice{
//                       amount
//                       currencyCode
//                     }
//                     price{
//                       amount
//                       currencyCode
//                     }
//                     unitPrice{
//                       amount
//                       currencyCode
//                     }
//                   }
//                   remainingBalanceChargeAmount{
//                     amount
//                     currencyCode
//                   }
//                   sellingPlan {
//                     checkoutCharge {
//                       type
//                       value
//                     }
//                     description
//                     id
//                     name
//                     options {
//                       name
//                       value
//                     }
//                     priceAdjustments {
//                       adjustmentValue
//                       orderCount
//                     }
//                     recurringDeliveries
//                   }
//                 }
//               }
//               storeAvailability(first: $first, last: $last, before: $startCursor, after: $endCursor) {
//                 nodes {
//                   available
//                   location {
//                     address {
//                       address1
//                       address2
//                       city
//                       country

//                       latitude
//                       longitude
//                       phone
//                       province
//                       provinceCode
//                       zip
//                     }
//                   }
//                   pickUpTime
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `;

export const AllCollectionsQuery = `
  query AllCollectionsQuery( $country: CountryCode $language: LanguageCode $first: Int $last: Int $startCursor: String $endCursor: String )
  @inContext(country: $country, language: $language) {
    collections(first: $first, last: $last, before: $startCursor, after: $endCursor) {
      nodes {
        description(truncateAt: 10)
        descriptionHtml
        handle
        id
        image {
          altText
            height
            id
            url
            
            width
        }
        onlineStoreUrl
        seo {
          description
          title
        }
        sort: metafield(key: "sort", namespace: "custom") {
          value
        }
        isProd: metafield(key: "prod", namespace: "custom") {
          value
        }
        hide: metafield(key: "hide", namespace: "custom") {
          value
        }
        title
        updatedAt
      }
    } 
    metaobject(id: ${
      process.env.GATSBY_IS_INT === 'true'
        ? '"gid://shopify/Metaobject/42624844063"'
        : '"gid://shopify/Metaobject/7415169323"'
    }) {
      field(key: "discount") {
        value
      }
      handle
      id
      type
      updatedAt
    }  
    vipTags: metaobject(id: ${
      process.env.GATSBY_IS_INT === 'true'
        ? '"gid://shopify/Metaobject/42626515231"'
        : '"gid://shopify/Metaobject/7704314155"'
    }) {
      field(key: "tags") {
        value
      }
      handle
      id
      type
      updatedAt
    }   
  }
`;

export const CollectionQuery = `
  query CollectionQuery( $country: CountryCode $language: LanguageCode $id: ID $maxHeight: Int $maxWidth: Int $after: String)
  @inContext(country: $country, language: $language) {
    collection(id: $id) {
        description(truncateAt: 10)
        descriptionHtml
        handle
        id
        image {
          altText
            height
            id
            url
            
            width
        }
        onlineStoreUrl
        seo {
          description
          title
        }
        title
        updatedAt
        sort: metafield(key: "sort", namespace: "custom") {
          value
        }
        isProd: metafield(key: "prod", namespace: "custom") {
          value
        }
        hide: metafield(key: "hide", namespace: "custom") {
          value
        }
        products(first: 24, sortKey: MANUAL, after: $after ) {
          nodes {
            availableForSale
            compareAtPriceRange {
              maxVariantPrice {
                amount
                currencyCode
              }
              minVariantPrice {
                amount
                currencyCode
              }
            }
            enable: metafield(key: "enable", namespace: "custom") {
              value
            }
            colorText: metafield(key: "colortext", namespace: "custom") {
              value
            }
            saleAt: metafield(key: "saleat", namespace: "custom") {
              value
            }
            previewAt: metafield(key: "previewat", namespace: "custom") {
              value
            }
            vipSaleAt: metafield(key: "vipsaleat", namespace: "custom") {
              value
            }
            vipPreviewAt: metafield(key: "vippreviewat", namespace: "custom") {
              value
            }
            size: metafield(key: "size", namespace: "custom") {
              value
            }
            color: metafield(key: "color", namespace: "custom") {
              value
            }
            sketchSrc: metafield(key: "sketchsrc", namespace: "custom") {
              value
            }
            videoSrc: metafield(key: "videosrc", namespace: "custom") {
              value
            }
            showVideo: metafield(key: "showvideo", namespace: "custom") {
              value
            }
            showImageIndex: metafield(key: "showimageindex", namespace: "custom") {
              value
            }
            collectionImage: metafield(key: "collectionimage", namespace: "custom") {
              value
            }
            desc: metafield(key: "desc", namespace: "custom") {
              value
            }
            lookbook: metafield(key: "lookbook", namespace: "custom") {
              value
            }
            collectionDesc: metafield(key: "collectiondesc", namespace: "custom") {
              value
            }
            createdAt
            description(truncateAt: 10)
            descriptionHtml
            images (first: 10) {
              nodes {
                altText
                height
                id
                url(transform: {maxHeight: $maxHeight maxWidth: $maxWidth})
                width
              }
            }
            featuredImage {
              altText
              height
              id
              url(transform: {maxHeight: $maxHeight maxWidth: $maxWidth})
              width
            }
            handle
            id
            isGiftCard
            onlineStoreUrl
            options(first: 8)  {
              
                id
                name
                values
              
            }
            priceRange {
              maxVariantPrice {
                amount
                currencyCode
              }
              minVariantPrice {
                amount
                currencyCode
              }
            }
            productType
            publishedAt
            requiresSellingPlan
            seo {
              description
              title
            }
            tags
            title
            totalInventory
            updatedAt
            variantBySelectedOptions(selectedOptions: [{name: "大小", value: "S"}, {name: "大小", value: "M"}]) {
              availableForSale
              barcode
              compareAtPrice {
                amount
                currencyCode
              }
              currentlyNotInStock
              id
              image {
                altText
                  height
                  id
                  url
                  
                  width
              }
              price {
                amount
                currencyCode
              }
              
              quantityAvailable
              requiresShipping
              selectedOptions {
                name
                value
              }
              sku
              title
              unitPrice {
                amount
                currencyCode
              }
              unitPriceMeasurement {
                measuredType
                quantityUnit
                quantityValue
                referenceUnit
                referenceValue
              }
              weight
              weightUnit
              sellingPlanAllocations(first: 24) {
                nodes {
                  checkoutChargeAmount{
                    amount
                    currencyCode
                  }
                  priceAdjustments {
                    compareAtPrice{
                      amount
                      currencyCode
                    }
                    perDeliveryPrice{
                      amount
                      currencyCode
                    }
                    price{
                      amount
                      currencyCode
                    }
                    unitPrice{
                      amount
                      currencyCode
                    }
                  }
                  remainingBalanceChargeAmount{
                    amount
                    currencyCode
                  }
                  sellingPlan {
                    checkoutCharge {
                      type
                      value
                    }
                    description
                    id
                    name
                    options {
                      name
                      value
                    }
                    priceAdjustments {
                      adjustmentValue
                      orderCount
                    }
                    recurringDeliveries
                  }
                }
              }
              storeAvailability(first: 24) {
                nodes {
                  available
                  location {
                    address {
                      address1
                      address2
                      city
                      country
                      
                      latitude
                      longitude
                      phone
                      province
                      provinceCode
                      zip
                    }
                  }
                  pickUpTime
                }
              }
            }
          }
          pageInfo { hasPreviousPage hasNextPage startCursor endCursor }
        
      }
    }    
  }
`;
export const CollectionsQuery = `
  query CollectionQuery( $country: CountryCode $language: LanguageCode)
  @inContext(country: $country, language: $language) {
    collection(id: $id) {
        description(truncateAt: 10)
        descriptionHtml
        handle
        id
        image {
          altText
            height
            id
            url
            
            width
        }
        onlineStoreUrl
        seo {
          description
          title
        }
        title
        updatedAt
        sort: metafield(key: "sort", namespace: "custom") {
          value
        }
        isProd: metafield(key: "prod", namespace: "custom") {
          value
        }
        hide: metafield(key: "hide", namespace: "custom") {
          value
        }
        products(first: 24) {
          nodes {
            availableForSale
            compareAtPriceRange {
              maxVariantPrice {
                amount
                currencyCode
              }
              minVariantPrice {
                amount
                currencyCode
              }
            }
            createdAt
            description(truncateAt: 10)
            descriptionHtml
            mayLike: metafield(key: "related_products_display", namespace: "shopify--discovery--product_recommendation") {
              value
            }
            enable: metafield(key: "enable", namespace: "custom") {
              value
            }
            colorText: metafield(key: "colortext", namespace: "custom") {
              value
            }
            saleAt: metafield(key: "saleat", namespace: "custom") {
              value
            }
            previewAt: metafield(key: "previewat", namespace: "custom") {
              value
            }
            vipSaleAt: metafield(key: "vipsaleat", namespace: "custom") {
              value
            }
            vipPreviewAt: metafield(key: "vippreviewat", namespace: "custom") {
              value
            }
            size: metafield(key: "size", namespace: "custom") {
              value
            }
            color: metafield(key: "color", namespace: "custom") {
              value
            }
            sketchSrc: metafield(key: "sketchsrc", namespace: "custom") {
              value
            }
            videoSrc: metafield(key: "videosrc", namespace: "custom") {
              value
            }
            showVideo: metafield(key: "showvideo", namespace: "custom") {
              value
            }
            showImageIndex: metafield(key: "showimageindex", namespace: "custom") {
              value
            }
            collectionImage: metafield(key: "collectionimage", namespace: "custom") {
              value
            }
            desc: metafield(key: "desc", namespace: "custom") {
              value
            }
            lookbook: metafield(key: "lookbook", namespace: "custom") {
              value
            }
            collectionDesc: metafield(key: "collectiondesc", namespace: "custom") {
              value
            }
            images (first: 10) {
              nodes {
                altText
                height
                id
                url(transform: {maxHeight: $maxHeight maxWidth: $maxWidth})
                width
              }
            }
            featuredImage {
              altText
              height
              id
              url(transform: {maxHeight: $maxHeight maxWidth: $maxWidth})
              width
            }
            handle
            id
            isGiftCard
            onlineStoreUrl
            options(first: 8)  {
              
                id
                name
                values
              
            }
            priceRange {
              maxVariantPrice {
                amount
                currencyCode
              }
              minVariantPrice {
                amount
                currencyCode
              }
            }
            productType
            publishedAt
            requiresSellingPlan
            seo {
              description
              title
            }
            tags
            title
            totalInventory
            updatedAt
            variantBySelectedOptions(selectedOptions: [{name: "大小", value: "S"}, {name: "大小", value: "M"}]) {
              availableForSale
              barcode
              compareAtPrice {
                amount
                currencyCode
              }
              currentlyNotInStock
              id
              image {
                altText
                  height
                  id
                  url
                  
                  width
              }
              price {
                amount
                currencyCode
              }
              
              quantityAvailable
              requiresShipping
              selectedOptions {
                name
                value
              }
              sku
              title
              unitPrice {
                amount
                currencyCode
              }
              unitPriceMeasurement {
                measuredType
                quantityUnit
                quantityValue
                referenceUnit
                referenceValue
              }
              weight
              weightUnit
              sellingPlanAllocations(first: 24) {
                nodes {
                  checkoutChargeAmount{
                    amount
                    currencyCode
                  }
                  priceAdjustments {
                    compareAtPrice{
                      amount
                      currencyCode
                    }
                    perDeliveryPrice{
                      amount
                      currencyCode
                    }
                    price{
                      amount
                      currencyCode
                    }
                    unitPrice{
                      amount
                      currencyCode
                    }
                  }
                  remainingBalanceChargeAmount{
                    amount
                    currencyCode
                  }
                  sellingPlan {
                    checkoutCharge {
                      type
                      value
                    }
                    description
                    id
                    name
                    options {
                      name
                      value
                    }
                    priceAdjustments {
                      adjustmentValue
                      orderCount
                    }
                    recurringDeliveries
                  }
                }
              }
              storeAvailability(first: 24) {
                nodes {
                  available
                  location {
                    address {
                      address1
                      address2
                      city
                      country
                      
                      latitude
                      longitude
                      phone
                      province
                      provinceCode
                      zip
                    }
                  }
                  pickUpTime
                }
              }
            }
          }
        
      }
    }    
  }
`;
