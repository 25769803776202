import { order } from './orderQuery';

const checkout = `
  appliedGiftCards {
    amountUsed {
      amount
      currencyCode
    }
    balance {
      amount
      currencyCode
    }
    id
    lastCharacters
    presentmentAmountUsed {
      amount
      currencyCode
    }
  }
  availableShippingRates {
    ready
    shippingRates {
      handle
      price {
        amount
        currencyCode
      }
      title
    }
  }
  buyerIdentity {
    countryCode
  }
  completedAt
  createdAt
  currencyCode
  customAttributes {
    key
    value
  }
  email
  id
  lineItemsSubtotalPrice {
    amount
    currencyCode
  }
  note
  order {
    ${order}
  }
  orderStatusUrl
  paymentDue {
    amount
    currencyCode
  }
  ready
  requiresShipping
  shippingAddress {
    address1
    address2
    city
    company
    country
    countryCodeV2
    firstName
    formattedArea
    id
    lastName
    latitude
    longitude
    name
    phone
    province
    provinceCode
    zip
  }
  shippingDiscountAllocations {
    allocatedAmount {
      amount
      currencyCode
    }
    discountApplication {
      allocationMethod
      targetSelection
      targetType
      value {
        ... on MoneyV2 {
          amount
          currencyCode
        }
        ... on PricingPercentageValue {
          percentage
        }
      }
    }
  }
  shippingLine {
    handle
    price {
      amount
      currencyCode
    }
    title
  }
  subtotalPrice {
    amount
    currencyCode
  }
  subtotalPriceV2 {
    amount
    currencyCode
  }
  taxExempt
  taxesIncluded
  totalDuties {
    amount
    currencyCode
  }
  totalPriceV2 {
    amount
    currencyCode
  }
  totalTaxV2 {
    amount
    currencyCode
  }
  updatedAt
  webUrl
  discountApplications(first:24) {
    nodes {
      allocationMethod
      targetSelection
      targetType
      value {
        ... on MoneyV2 {
          amount
          currencyCode
        }
        ... on PricingPercentageValue {
          percentage
        }
      }
    }
  }
  lineItems(first:240) {
    nodes {
      id
      customAttributes {
        key
        value
      }
      discountAllocations {
        allocatedAmount {
          amount
          currencyCode
        }
        discountApplication {
          allocationMethod
          targetSelection
          targetType
          value {
            ... on MoneyV2 {
              amount
              currencyCode
            }
            ... on PricingPercentageValue {
              percentage
            }
          }
        }
      }
    
      quantity
      title
      variant {
        availableForSale
        barcode
        compareAtPrice {
          amount
          currencyCode
        }
        currentlyNotInStock
        id
        image {
          altText
            height
            id
            url
            
            width
        }
        price {
          amount
          currencyCode
        }
        product {
          availableForSale
          compareAtPriceRange {
            maxVariantPrice {
              amount
              currencyCode
            }
            minVariantPrice {
              amount
              currencyCode
            }
          }
          createdAt
          description(truncateAt: 10)
          descriptionHtml
          colorText: metafield(key: "colortext", namespace: "custom") {
            value
          }
          images (first: 10) {
            nodes {
              altText
              height
              id
              url(transform: {maxHeight: 768 maxWidth: 768})
              width
            }
          }
          featuredImage {
            altText
            height
            id
            url(transform: {maxHeight: 768 maxWidth: 768})
            width
          }
          handle
          id
          isGiftCard
          onlineStoreUrl
          options(first: 8)  {
            
              id
              name
              values
            
          }
          priceRange {
            maxVariantPrice {
              amount
              currencyCode
            }
            minVariantPrice {
              amount
              currencyCode
            }
          }
          productType
          publishedAt
          requiresSellingPlan
          seo {
            description
            title
          }
          tags
          title
          totalInventory
          updatedAt
        }
        quantityAvailable
        requiresShipping
        selectedOptions {
          name
          value
        }
        sku
        title
        unitPrice {
          amount
          currencyCode
        }
        unitPriceMeasurement {
          measuredType
          quantityUnit
          quantityValue
          referenceUnit
          referenceValue
        }
        weight
        weightUnit
        sellingPlanAllocations(first: 24) {
          nodes {
            checkoutChargeAmount{
              amount
              currencyCode
            }
            priceAdjustments {
              compareAtPrice{
                amount
                currencyCode
              }
              perDeliveryPrice{
                amount
                currencyCode
              }
              price{
                amount
                currencyCode
              }
              unitPrice {
                amount
                currencyCode
              }
            }
            remainingBalanceChargeAmount{
              amount
              currencyCode
            }
            sellingPlan {
              checkoutCharge {
                type
                value
              }
              description
              id
              name
              options {
                name
                value
              }
              priceAdjustments {
                adjustmentValue
                orderCount
              }
              recurringDeliveries
            }
          }
        }
        storeAvailability(first: 24) {
          nodes {
            available
            location {
              address {
                address1
                address2
                city
                country
                
                latitude
                longitude
                phone
                province
                provinceCode
                zip
              }
            }
            pickUpTime
          }
        }
      }
    }
  }
`;

export const CheckoutCreateMutation = `
mutation CheckoutCreate($input: CheckoutCreateInput!) {
  checkoutCreate(input: $input) {
    checkout {
      ${checkout}
    }
    checkoutUserErrors {
      code
      field
      message
    }
    queueToken
  }
}`;

export const CheckoutCreateMutationInput = {
  input: {
    allowPartialAddresses: true,
    buyerIdentity: {
      countryCode: '',
    },
    customAttributes: [
      {
        key: '',
        value: '',
      },
    ],
    email: '',
    lineItems: [
      {
        customAttributes: [
          {
            key: '',
            value: '',
          },
        ],
        quantity: 1,
        variantId: '',
      },
    ],
    note: '',
    presentmentCurrencyCode: '',
    shippingAddress: {
      address1: '',
      address2: '',
      city: '',
      company: '',
      country: '',
      firstName: '',
      lastName: '',
      phone: '',
      province: '',
      zip: '',
    },
  },
  queueToken: '',
};

export const CheckoutQuery = `
query CheckoutQuery($country: CountryCode $language: LanguageCode $id: ID!)
  @inContext(country: $country, language: $language) {
  node(id: $id) {
    id
    ... on Checkout {
      ${checkout}
    }
  }
}
`;

export const CheckoutShippingAddressUpdateV2Mutation = `
mutation CheckoutShippingAddressUpdateV2($checkoutId: ID!, $shippingAddress: MailingAddressInput!) {
  checkoutShippingAddressUpdateV2(checkoutId: $checkoutId, shippingAddress: $shippingAddress) {
    checkout {
      ${checkout}
    }
    checkoutUserErrors {
      code
      field
      message
    }
  }
}`;

export const CheckoutLineItemsUpdateMutation = `
mutation CheckoutLineItemsUpdate($checkoutId: ID!, $lineItems: [CheckoutLineItemUpdateInput!]!) {
  checkoutLineItemsUpdate(checkoutId: $checkoutId, lineItems: $lineItems) {
    checkout {
      ${checkout}
    }
    checkoutUserErrors {
      code
      field
      message
    }
  }
}`;

export const CheckoutLineItemsAddMutationInput = {
  checkoutId: '',
  lineItems: [
    {
      customAttributes: [
        {
          key: '',
          value: '',
        },
      ],
      id: '',
      quantity: 1,
      variantId: '',
    },
  ],
};

export const CheckoutLineItemsAddMutation = `
mutation CheckoutLineItemsAdd($checkoutId: ID!, $lineItems: [CheckoutLineItemInput!]!) {
  checkoutLineItemsAdd(checkoutId: $checkoutId, lineItems: $lineItems) {
    checkout {
      ${checkout}
    }
    checkoutUserErrors {
      code
      field
      message
    }
  }
}`;

export const CheckoutLineItemsRemoveMutation = `
mutation CheckoutLineItemsRemove($checkoutId: ID!, $lineItemIds: [ID!]!) {
  checkoutLineItemsRemove(checkoutId: $checkoutId, lineItemIds: $lineItemIds) {
    checkout {
      ${checkout}
    }
    checkoutUserErrors {
      code
      field
      message
    }
  }
}`;

export const CheckoutCustomerAssociateV2Mutation = `
mutation CheckoutCustomerAssociateV2($checkoutId: ID!, $customerAccessToken: String!) {
  checkoutCustomerAssociateV2(checkoutId: $checkoutId, customerAccessToken: $customerAccessToken) {
    checkout {
      ${checkout}
    }
    checkoutUserErrors {
      code
      field
      message
    }
    customer {
      id
    }
  }
}`;

export const CheckoutCustomerDisassociateV2Mutation = `
mutation CheckoutCustomerDisassociateV2($checkoutId: ID!) {
  checkoutCustomerDisassociateV2(checkoutId: $checkoutId) {
    checkout {
      ${checkout}
    }
    checkoutUserErrors {
      code
      field
      message
    }
  }
}`;
