import { isBrowser } from './browser';

export function dollarFormat(amount, country = 'TWD') {
  const dollar = new Intl.NumberFormat('zh-TW', {
    style: 'currency',
    currency: country,
  });
  return dollar.format(amount);
}

export function currencyCode(currency) {
  if (currency === 'TWD') {
    return 'NT';
  }
  return currency;
  // itemDetail?.variants?.nodes[0]?.price?.currencyCode
}

export function dollarFloat(amount, country) {
  // alert(country);
  if (country === 'TW') {
    // alert(amount);
    return parseInt(amount);
  }
  return amount;
}

// export function priceFormater({
//   setting,
//   price,
//   currencyName,
// }) {
//   if (process.env.GATSBY_IS_INT === 'false') {
//     if (isBrowser() &&
//     setting.country &&
//     setting.country.toUpperCase() !== 'TW' &&
//     window.Currency) {
//       return {
//         currentCurrencyPrice: Math.round(
//           window.Currency.convert(
//             dollarFloat(
//               itemDetail?.variants?.nodes[0]?.price?.amount,
//               setting.country
//             ),
//             'TWD',
//             currencyName
//           ) * 100
//         ) / 100,
//         price: dollarFloat(
//           price,
//           setting.country
//         )
//       }
//     } else {
//       return {
//         currentCurrencyPrice:currencyCode(
//           itemDetail?.variants?.nodes[0]?.price?.currencyCode
//         ),
// price: dollarFloat(
//           itemDetail?.variants?.nodes[0]?.price?.amount,
//           setting.country
//         )
//       }
//     }
//   } else if (process.env.GATSBY_IS_INT === 'true') {

//   }
//   {process.env.GATSBY_IS_INT === 'false' &&
//     (isBrowser() &&
//     setting.country &&
//     setting.country.toUpperCase() !== 'TW' &&
//     window.Currency ? (
//       <>
//         {currencyName[setting.country.toUpperCase()]}${' '}
//         {Math.round(
//           window.Currency.convert(
//             dollarFloat(
//               itemDetail?.variants?.nodes[0]?.price?.amount,
//               setting.country
//             ),
//             'TWD',
//             currencyName[setting.country.toUpperCase()]
//           ) * 100
//         ) / 100}
//         <span className="s-9">
//           {' '}
//           (TWD${' '}
//           {dollarFloat(
//             itemDetail?.variants?.nodes[0]?.price?.amount,
//             setting.country
//           )}
//           )
//         </span>
//       </>
//     ) : (
//       <>
//         {currencyCode(
//           itemDetail?.variants?.nodes[0]?.price?.currencyCode
//         )}
//         ${' '}
//         {dollarFloat(
//           itemDetail?.variants?.nodes[0]?.price?.amount,
//           setting.country
//         )}
//       </>
//     ))}
//   {process.env.GATSBY_IS_INT === 'true' && (
//     <>
//       {currencyCode(
//         itemDetail?.variants?.nodes[0]?.price?.currencyCode
//       )}
//       ${' '}
//       {dollarFloat(
//         itemDetail?.variants?.nodes[0]?.price?.amount,
//         setting.country
//       )}
//       {isBrowser() &&
//         setting.country &&
//         setting.country.toUpperCase() !== 'US' &&
//         window.Currency && (
//           <span className="s-9">
//             {' '}
//             ( {currencyName[setting.country.toUpperCase()]}${' '}
//             {Math.round(
//               window.Currency.convert(
//                 dollarFloat(
//                   itemDetail?.variants?.nodes[0]?.price?.amount,
//                   setting.country
//                 ),
//                 'USD',
//                 currencyName[setting.country.toUpperCase()]
//               ) * 100
//             ) / 100}{' '}
//             )
//           </span>
//         )}
//     </>
//   )}
// }
