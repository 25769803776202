export default function queryFormat({
  query,
  variables,
  setting = {},
  pageContext = {},
}) {
  // console.log(
  //   '==> aaaa',
  //   `{"query": ${JSON.stringify(query)}, "variables": ${JSON.stringify({
  //     ...variables,
  //     country: 'US',
  //     language: 'EN',
  //   })}}`
  // );
  if (!setting.country) {
    setting.country = 'TW';
  }
  if (!pageContext.language) {
    pageContext.language = 'EN';
  }
  if (pageContext.language === 'zh') {
    pageContext.language = 'zh_tw';
  }
  const language = pageContext.language.toUpperCase();
  const country = setting.country.toUpperCase();
  // console.log('===> aaaa variables', variables);
  return `{"query": ${JSON.stringify(query)}, "variables": ${JSON.stringify({
    country,
    language,
    ...variables,
  })}}`;
}

export function mutationFormat({
  query,
  variables,
  // setting = {},
  // pageContext = {},
}) {
  // console.log(
  //   '==> aaaa',
  //   `{"query": ${JSON.stringify(query)}, "variables": ${JSON.stringify({
  //     ...variables,
  //     country: 'US',
  //     language: 'EN',
  //   })}}`
  // );
  // if (!setting.country) {
  //   setting.country = 'US';
  // }
  // if (!pageContext.language) {
  //   pageContext.language = 'EN';
  // }
  // const language = pageContext.language.toUpperCase();
  // const country = setting.country.toUpperCase();
  return `{"query": ${JSON.stringify(query)}, "variables": ${JSON.stringify({
    // country,
    // language,
    ...variables,
  })}}`;
}
