import React, { useState, useEffect } from 'react';

export default function useCounter({
  second = 1,
  callback = () => {},
  extra = {},
}) {
  const [stopTimer, setStopTimer] = useState(false);
  useEffect(() => {
    if (stopTimer) return;
    const timer = setInterval(() => {
      callback(setStopTimer);
    }, second * 1000);
    return () => {
      clearInterval(timer);
    };
  }, [stopTimer, extra]);
  return {
    stopTimer,
    setStopTimer,
  };
}
