const _interopRequireDefault = require('@babel/runtime/helpers/interopRequireDefault');

exports.__esModule = true;
exports.wrapPageElement = void 0;
const _extends2 = _interopRequireDefault(
  require('@babel/runtime/helpers/extends')
);
const _taggedTemplateLiteralLoose2 = _interopRequireDefault(
  require('@babel/runtime/helpers/taggedTemplateLiteralLoose')
);
const _react = _interopRequireDefault(require('react'));
const _gatsby = require('gatsby');
const _browserLang = _interopRequireDefault(require('browser-lang'));
const _reactI18next = require('react-i18next');
const _types = require('../types');
const _i18next = _interopRequireDefault(require('i18next'));
const _i18nextContext = require('../i18nextContext');
const _outdent = _interopRequireDefault(require('outdent'));

let _templateObject;
const withI18next = function withI18next(i18n, context) {
  return function (children) {
    return /* #__PURE__ */ _react.default.createElement(
      _reactI18next.I18nextProvider,
      {
        i18n,
      },
      /* #__PURE__ */ _react.default.createElement(
        _i18nextContext.I18nextContext.Provider,
        {
          value: context,
        },
        children
      )
    );
  };
};
const removePathPrefix = function removePathPrefix(
  pathname,
  stripTrailingSlash
) {
  const pathPrefix = (0, _gatsby.withPrefix)('/');
  let result = pathname;
  if (pathname.startsWith(pathPrefix)) {
    result = pathname.replace(pathPrefix, '/');
  }
  if (stripTrailingSlash && result.endsWith('/')) {
    return result.slice(0, -1);
  }
  return result;
};
const wrapPageElement = function wrapPageElement(_ref, _ref2) {
  let _data$localeJsonNodeN;
  let _i18nextOptions$defau;
  const { element } = _ref;
  const { props } = _ref;
  const _ref2$i18nextOptions = _ref2.i18nextOptions;
  const i18nextOptions =
    _ref2$i18nextOptions === void 0 ? {} : _ref2$i18nextOptions;
  const _ref2$redirect = _ref2.redirect;
  const redirect = _ref2$redirect === void 0 ? true : _ref2$redirect;
  const _ref2$generateDefault = _ref2.generateDefaultLanguagePage;
  const generateDefaultLanguagePage =
    _ref2$generateDefault === void 0 ? false : _ref2$generateDefault;
  const { siteUrl } = _ref2;
  const _ref2$localeJsonNodeN = _ref2.localeJsonNodeName;
  const localeJsonNodeName =
    _ref2$localeJsonNodeN === void 0 ? 'locales' : _ref2$localeJsonNodeN;
  const { fallbackLanguage } = _ref2;
  const { trailingSlash } = _ref2;
  if (!props) return;
  const { data } = props;
  const { pageContext } = props;
  const { location } = props;
  const _pageContext$i18n = pageContext.i18n;
  const { routed } = _pageContext$i18n;
  const { language } = _pageContext$i18n;
  const { languages } = _pageContext$i18n;
  const { originalPath } = _pageContext$i18n;
  const { defaultLanguage } = _pageContext$i18n;
  const { path } = _pageContext$i18n;
  const isRedirect = redirect && !routed;
  if (isRedirect) {
    const { search } = location;

    // Skip build, Browsers only
    if (typeof window !== 'undefined') {
      let detected =
        window.localStorage.getItem(_types.LANGUAGE_KEY) ||
        (0, _browserLang.default)({
          languages,
          fallback: fallbackLanguage || language,
        });
      if (!languages.includes(detected)) {
        detected = language;
      }
      window.localStorage.setItem(_types.LANGUAGE_KEY, detected);
      if (detected !== defaultLanguage) {
        const queryParams = search || '';
        const stripTrailingSlash = trailingSlash === 'never';
        const newUrl = (0, _gatsby.withPrefix)(
          `/${detected}${removePathPrefix(
            location.pathname,
            stripTrailingSlash
          )}${queryParams}${location.hash}`
        );
        // @ts-ignore
        window.___replace(newUrl);
        return null;
      }
    }
  }
  const localeNodes =
    (data === null || data === void 0
      ? void 0
      : (_data$localeJsonNodeN = data[localeJsonNodeName]) === null ||
        _data$localeJsonNodeN === void 0
      ? void 0
      : _data$localeJsonNodeN.edges) || [];
  if (
    languages.length > 1 &&
    localeNodes.length === 0 &&
    process.env.NODE_ENV === 'development'
  ) {
    console.error(
      (0, _outdent.default)(
        _templateObject ||
          (_templateObject = (0, _taggedTemplateLiteralLoose2.default)(
            [
              '\n      No translations were found in "',
              '" key for "',
              '". \n      You need to add a graphql query to every page like this:\n      \n      export const query = graphql`\n        query($language: String!) {\n          ',
              ': allLocale(language: {eq: $language}) {\n            edges {\n              node {\n                ns\n                data\n                language\n              }\n            }\n          }\n        }\n      `;\n      ',
            ],
            [
              '\n      No translations were found in "',
              '" key for "',
              '". \n      You need to add a graphql query to every page like this:\n      \n      export const query = graphql\\`\n        query($language: String!) {\n          ',
              ': allLocale(language: {eq: $language}) {\n            edges {\n              node {\n                ns\n                data\n                language\n              }\n            }\n          }\n        }\n      \\`;\n      ',
            ]
          )),
        localeJsonNodeName,
        originalPath,
        localeJsonNodeName
      )
    );
  }
  const namespaces = localeNodes.map(function (_ref3) {
    const { node } = _ref3;
    return node.ns;
  });

  // We want to set default namespace to a page namespace if it exists
  // and use other namespaces as fallback
  // this way you dont need to specify namespaces in pages
  let defaultNS =
    ((_i18nextOptions$defau = i18nextOptions.defaultNS) === null ||
    _i18nextOptions$defau === void 0
      ? void 0
      : _i18nextOptions$defau.toString()) || 'translation';
  defaultNS =
    namespaces.find(function (ns) {
      return ns !== defaultNS;
    }) || defaultNS;
  const fallbackNS = namespaces.filter(function (ns) {
    return ns !== defaultNS;
  });
  const resources = localeNodes.reduce(function (res, _ref4) {
    const { node } = _ref4;
    const parsedData =
      typeof node.data === 'object' ? node.data : JSON.parse(node.data);
    if (!(node.language in res)) res[node.language] = {};
    res[node.language][node.ns || defaultNS] = parsedData;
    return res;
  }, {});
  const i18n = _i18next.default.createInstance();
  i18n.init(
    (0, _extends2.default)({}, i18nextOptions, {
      resources,
      lng: language,
      fallbackLng: defaultLanguage,
      defaultNS,
      fallbackNS,
      react: (0, _extends2.default)({}, i18nextOptions.react, {
        useSuspense: false,
      }),
    })
  );
  if (i18n.language !== language) {
    i18n.changeLanguage(language);
  }
  const context = {
    routed,
    language,
    languages,
    originalPath,
    defaultLanguage,
    generateDefaultLanguagePage,
    siteUrl,
    path,
  };
  return withI18next(i18n, context)(element);
};
exports.wrapPageElement = wrapPageElement;
