import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import Loading from './Loading';

const PopupContainer = styled.div`
  top: 100000000px;
  height: 100000000px;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 15;
  transition: top 0s 0.15s ease, left 0s 0.15s ease;
  .popup-box {
    position: relative;
    width: 100%;
    max-width: 530px;
    /* margin-top: 18vh; */
    top: 10%;
    height: 80%;
    /* max-height: 60%; */
    /* background: red; */
    padding: 25px;
    transform: translate3d(-50%, -30px, 0);
    left: 50%;
    opacity: 0;
    transition: transform 0.15s ease, opacity 0.15s ease;
    display: flex;
    justify-content: center;
    align-items: center;

    .box {
      position: relative;
      width: 100%;
      background: var(--mainColor);
      border: 1px solid #000;
      padding: 10px;
      margin-bottom: 20%;
      max-height: 90%;
      overflow: scroll;
      overflow-x: hidden;
      &::-webkit-scrollbar {
        display: none;
      }
      .content {
        width: 100%;
        position: relative;
        line-break: anywhere;
        padding: 25px 0px 0px 0px;
      }
      .header {
        position: sticky;
        width: 100%;
        top: 0;
        z-index: 3;
        /* background: var(--mainColor); */
        > p {
          text-align: center;
        }
        .icon-close {
          position: absolute;
          top: 0px;
          right: 0px;
          font-size: 16px;
          z-index: 3;
        }
      }
    }
  }
  &.middle {
    .popup-box {
      position: relative;
      width: 100%;
      max-width: 768px;
      /* margin-top: 18vh; */
      top: 10%;
      height: 80%;
      /* max-height: 60%; */
      /* background: red; */
      padding: 25px;
      /* transform: translate3d(-50%, -30px, 0);
      left: 50%;
      opacity: 0;
      transition: transform 0.15s ease, opacity 0.15s ease;
      display: flex;
      justify-content: center;
      align-items: center; */
    }
  }
  &.active {
    top: 0;
    left: 0;
    transition: top 0s ease, left 0s ease;
    .popup-box {
      transform: translate3d(-50%, 0px, 0);
      opacity: 1;
      transition: transform 0.3s ease, opacity 0.3s ease;
    }
  }
  .fixed-bottom-button {
    padding: 10px 15px;
    text-align: center;
    background: black;
    position: sticky;
    bottom: 0px;
    /* left: -10px; */
    width: calc(100% + 22px);
    /* width: 100%; */
    /* top: calc(80% + 12px); */
    /* max-width: 480px; */
    /* bottom: 10%; */
    /* margin-bottom: 0px -10px -10px -10px; */
    transform: translate3d(-11px, 10px, 0);
  }
`;

export default function Popup(props) {
  const { popupInfo, setPopupInfo } = props;
  const [state, setState] = useState(popupInfo?.data?.state || {});
  console.log('===> popupInfo', popupInfo);
  useEffect(() => {
    console.log('===> aaaa');
    setState(popupInfo?.data?.state || {});
  }, [popupInfo?.data?.state]);
  // console.log('===> popupInfo', popupInfo, state, popupInfo.data.state);
  return (
    <PopupContainer
      className={`${popupInfo.active && 'active'} ${popupInfo.size}`}
      onClick={(e) => {
        e.stopPropagation();
        popupInfo.onDisable(
          () => {
            setPopupInfo({
              ...popupInfo,
              active: false,
              // type: '',
              // data: {},
            });
          },
          state,
          setState
        );
      }}
    >
      <div className="popup-box">
        <div
          className="box"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="header">
            {!popupInfo.hideClose && (
              <i
                className="icon-close"
                onClick={(e) => {
                  e.stopPropagation();
                  popupInfo.onDisable(
                    () => {
                      setPopupInfo({
                        ...popupInfo,
                        active: false,
                        // type: '',
                        // data: {},
                      });
                    },
                    state,
                    setState
                  );
                }}
              />
            )}

            <p className="s-13 GSText text-center">{popupInfo?.data?.title}</p>
          </div>
          <div className="content">
            {popupInfo?.data?.Component &&
              popupInfo?.data?.Component(state, setState)}
          </div>
          {popupInfo.showFixedBottomConfirmButton && (
            <div
              className="fixed-bottom-button pointer"
              onClick={(e) => {
                e.stopPropagation();
                setState({
                  ...state,
                  changeMarket: true,
                });
                popupInfo.onConfirm(
                  () => {
                    setPopupInfo({
                      ...popupInfo,
                      active: false,
                      // type: '',
                      // data: {},
                    });
                  },
                  state,
                  setState
                );
              }}
            >
              <p className="s-13 white GSText">
                <Trans>Confirm</Trans>
              </p>
              <Loading
                className="add-item-loading"
                type="small"
                loading={state.changeMarket}
              />
            </div>
          )}
        </div>
      </div>
    </PopupContainer>
  );
}
