export const countryCompare = {
  TW: 'Taiwan',
  HK: 'Hong Kong',
  MO: 'Macao',
  SG: 'Singapore',
  MY: 'Malaysia',
  US: 'United States',
  JP: 'Japan',
  KR: 'Korea',
  AU: 'Australia',
  CA: 'Canada',
  GB: 'United Kingdom',
  FR: 'France',
  DE: 'Germany',
  CZ: 'Czech Republic',
  IT: 'Italy',
  TH: 'Thailand',
  VN: 'Vietnam',
  PH: 'Philippines',
  NL: 'Netherlands',
  ES: 'Spain',
  SE: 'Sweden',
  NZ: 'New Zealand',
  PL: 'Poland',
  AT: 'Austria',
  CH: 'Switzerland',
  HU: 'Hungary',
  BE: 'Belgium',
  DK: 'Denmark',
  PT: 'Portugal',
  NO: 'Norway',
  ZZ: 'Global',
};
