import React, { Children, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Link as I18nLink,
  Trans,
  useTranslation,
  useI18next,
} from '../../plugins/gatsby-plugin-react-i18next-customize';
import { isBrowser } from '../helpers/browser';
import RootContext from './RootContext';

const TextAniContainer = styled.div`
  position: relative;
  /* padding: 2px; */
  .en-text {
    transform: translateY(0px);
    opacity: 1;
    transition: transform 0.2s ease, opacity 0.25s ease;
    line-height: 1.5em;
    &.lock {
      transform: translateY(0px) !important;
      opacity: 1 !important;
    }
  }
  .trans-text {
    display: block;
    text-decoration: none;
    position: absolute;
    opacity: 0;
    transform: translate3d(-50%, 0px, 0px);
    transition: transform 0.2s ease, opacity 0.25s ease;
    width: 100%;
    text-align: center;
    line-height: 1.5em;
    pointer-events: none;
    width: auto;
    white-space: nowrap;
    /* margin-top: -12px; */
    left: 50%;
    /* &.trans-text-center {
      transform: translate3d(100%, 0px, 0px);
      left: 50%;
    } */
    &.horizontal-text-center {
      left: 50% !important;
    }
    &.trans-text-left {
      transform: translate3d(0px, 0px, 0px);
      left: 0;
    }
    &.trans-text-right {
      transform: translate3d(0px, 0px, 0px);
      left: auto;
      right: -50%;
    }
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 1px;
      width: 100%;
      background: black;
      transform: scaleX(0);
      transition: transform 0.1s ease, background 0.15s ease;
    }
  }
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    background: black;
    transform: scaleX(0);
    transition: transform 0.1s ease, background 0.15s ease;
  }
  &.white {
    &:after {
      background: white;
    }
    .trans-text {
      &:after {
        background: white;
      }
    }
  }
  &.black {
    &:after {
      background: black;
    }
    .trans-text {
      &:after {
        background: black;
      }
    }
  }
  &.active {
    &.current-page {
      transform: scaleX(1);
    }
    &:hover,
    &.nav-active {
      opacity: 1 !important;
      /* &:after {
        transform: scaleX(1);
        transition: transform 0.15s 0.2s ease;
      } */
      .trans-text {
        &:after {
          transform: scaleX(1);
          transition: transform 0.15s 0.2s ease;
        }
      }
    }
    &.en:hover,
    &.en.nav-active {
      &:after {
        transform: scaleX(1);
        transition: transform 0.15s 0.2s ease;
      }
    }
  }
  /* .zh-text {
    font-size: 11px;
  } */
  &:hover,
  &.nav-active {
    opacity: 1 !important;
    /* &:after {
      transform: scaleX(1);
      transition: transform 0.15s 0.2s ease;
    } */
    .en-text {
      transform: translateY(-100%);
      opacity: 0;
    }
    .trans-text {
      transform: translate3d(-50%, -100%, 0px);
      opacity: 1;
      &.trans-text-left {
        transform: translate3d(0px, -100%, 0px);
        left: 0;
      }
      &.trans-text-right {
        transform: translate3d(0px, -100%, 0px);
        right: 0;
      }
      /* &.trans-text-center {
        transform: translate3d(-50%, 0px, 0px);
        left: 50%;
      } */
    }
    &.en.active {
      &:after {
        transform: scaleX(1);
        transition: transform 0.15s 0.2s ease;
      }
    }
    /* .trans-text {
      &:after {
        transform: scaleX(1);
        transition: transform 0.15s 0.2s ease;
      }
    } */
  }
  &.horizontal {
    .en-text {
      transform: translate3d(0%, 0, 0);
      /* opacity: 0; */
      /* &:hover {
        transform: translate3d(-100%, 0, 0);
        opacity: 0;
      } */
    }
    .trans-text {
      left: 0;
      transform: translate3d(100%, -100%, 0px);
      /* &:hover {
        transform: translate3d(0%, -100%, 0px);
      } */
    }
    &:hover,
    &.nav-active {
      .trans-text {
        transform: translate3d(0%, -100%, 0px);
        /* transition: none; */
      }
      .en-text {
        transform: translate3d(-100%, 0, 0);
        /* transition: none; */
        opacity: 0;
      }
      .horizontal-text-center {
        transform: translate3d(-50%, -100%, 0px);
        /* transition: none; */
      }
    }
  }
  @media screen and (max-width: 900px) {
    * {
      transition: none !important;
    }
  }
`;

export default function TransAni(props) {
  const {
    pageContext,
    containerStyle = {},
    containerClass = '',
    textStyle = {},
    children,
    textClass = '',
    i18nStyle = {
      'zh/ja/ko': { style: { fontSize: '11px', fontWeight: '500' } },
      // ja: { style: { fontSize: '11px', fontWeight: '500' } },
    },
    borderAni = true,
    headEle = () => null,
    tailEle = () => null,
    i18nAniAlign = 'center',
    type = 'vertical', // vertical, horizontal
    onClick = () => {},
    tailEleClassName = '',
    autoTranslate = true,
    useJSON,
    disable,
    // zhText,
  } = props;
  const { staticLocales } = useContext(RootContext);
  const [innerWidth, setInnerWidth] = useState(10000);
  const [minWidth, setMinWidth] = useState(0);
  const [ref, setRef] = useState(null);
  // const { t } = useTranslation();
  // console.log('==> tailEleIconClassName', tailEleIconClassName);
  useEffect(() => {
    if (isBrowser()) {
      setInnerWidth(window.innerWidth);
    }
  }, []);
  useEffect(() => {
    if (ref && isBrowser()) {
      if (ref.offsetWidth <= window.innerWidth) {
        setMinWidth(ref.offsetWidth);
      }
    }
  }, [ref]);

  let parseStyle = {};
  Object.keys(i18nStyle).map((s) => {
    // alert(`${pageContext.language} ${s}`);
    if (pageContext.language === 'zh_tw' && s.includes('zh')) {
      // console.log('===> 111111', i18nStyle.zh);
      parseStyle = i18nStyle[s];
    }
    if (s.includes(pageContext.language)) {
      parseStyle = i18nStyle[s];
    }
  });
  return (
    <TextAniContainer
      className={`${containerClass} ${pageContext.language} ${
        borderAni ? 'active' : ''
      } ${type} ${autoTranslate && innerWidth <= 900 ? 'nav-active' : ''}`}
      style={{ ...containerStyle, minWidth }}
      onClick={onClick}
    >
      {headEle()}
      <p
        className={`${textClass} en-text ${
          pageContext.language === 'en' ? 'lock' : ''
        }`}
        style={textStyle}
      >
        {children}
      </p>
      {pageContext?.language && pageContext.language !== 'en' && (
        <p
          ref={(p) => setRef(p)}
          className={`${textClass} trans-text ${
            parseStyle?.className || ''
          } ${`trans-text-${i18nAniAlign}`}`}
          style={{
            ...textStyle,
            ...(parseStyle?.style || {}),
          }}
        >
          {isBrowser() ? (
            parseStyle?.text ? (
              parseStyle?.text
            ) : useJSON ? (
              (staticLocales &&
                staticLocales[
                  pageContext.language === 'zh_tw' ? 'zh' : pageContext.language
                ] &&
                staticLocales[
                  pageContext.language === 'zh_tw' ? 'zh' : pageContext.language
                ][children]) ||
              children
            ) : (
              <Trans>{children}</Trans>
            )
          ) : (
            ''
          )}
        </p>
      )}
      {tailEle(tailEleClassName)}
    </TextAniContainer>
  );
}
