import React, { useState } from 'react';
import update from 'immutability-helper';

export default function usePagesInfo() {
  const [pagesInfo, setPagesInfo] = useState({});
  const [subOnlineShop, setSubOnlineShop] = useState(false);
  const [notification, setNotification] = useState({
    active: false,
    type: '',
    data: {},
  });
  const [popupInfo, setPopupInfo] = useState({
    active: false,
    type: '',
    onDisable: () => {},
    data: {},
  });
  const [timestamp, setTimestamp] = useState(false);
  function updatePagesInfo(updateData) {
    setPagesInfo(update(pagesInfo, updateData));
  }
  return {
    pagesInfo,
    updatePagesInfo,
    notification,
    setNotification,
    popupInfo,
    setPopupInfo,
    timestamp,
    setTimestamp,
    subOnlineShop,
    setSubOnlineShop,
  };
}
