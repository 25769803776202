import React, { useState, useEffect, useRef } from 'react';
import update from 'immutability-helper';
// import loadable from '@loadable/component';
// import getUserCountry from 'js-user-country';
import useStoreFrontAPI, { createQuery } from './useStoreFrontAPI';
import useStoreFrontQueryData from './useStoreFrontQueryData';
import { countryCompare } from '../constants/country';
import shopifyCountry from '../constants/shopify_countries.json';
import { isBrowser } from '../helpers/browser';
import zh from '../locales/zh';
import ja from '../locales/ja';
import ko from '../locales/ko';
import { urlParser } from '../helpers/urlParser';
import { glStoreFrontFetch } from '../apis-browser';
import {
  ProductQuery,
  ProductVariantsQuery,
  ProductHandleQuery,
} from '../constants/productQuery';
import queryFormat from '../helpers/queryFormat';

// import useAdminAPI, { createQuery as adminCreateQuery } from './useAdminAPI';
// import { navigate } from 'gatsby';

// const ClientSideOnlyLazy = React.lazy(() => import('js-user-country'));

const langsArray = ['zh', 'en'];

const CHINESE_LANG = ['TW', 'MO', 'SG', 'HK', 'MY'];

const markets = [
  'TW',
  'AT',
  'AU',
  'BE',
  'CA',
  'CZ',
  'DE',
  'DK',
  'ES',
  'FR',
  'HK',
  'HU',
  'IT',
  'JP',
  'KR',
  'MO',
  'MY',
  'NL',
  'NO',
  'NZ',
  'PH',
  'PL',
  'PT',
  'SE',
  'SG',
  'CH',
  'TH',
  'GB',
  'US',
  'VN',
];

const staticLocales = {
  zh,
  ja,
  ko,
};

export const lngCovert = {
  zh: '繁體中文',
  en: 'ENGLISH',
  ja: '日本語',
  ko: '한국어',
};

export const currencyName = {
  TW: 'TWD',
  HK: 'HKD',
  MO: 'MOP',
  SG: 'SGD',
  MY: 'MYR',
  JP: 'JPY',
  KR: 'KRW',
  US: 'USD',
  AU: 'AUD',
  CA: 'CAD',
  GB: 'GBP',
  FR: 'EUR',
  DE: 'EUR',
  CZ: 'EUR',
  IT: 'EUR',
  TH: 'THB',
  VN: 'VND',
  PH: 'PHP',
  NL: 'EUR',
  ES: 'EUR',
  SE: 'SEK',
  NZ: 'NZD',
  PL: 'PLN',
  AT: 'EUR',
  CH: 'CHF',
  HU: 'HUF',
  BE: 'EUR',
  DK: 'DKK',
  PT: 'EUR',
  NO: 'NOK',
};

let defaultCountry = process.env.GATSBY_IS_INT === 'true' ? 'US' : 'TW';
// console.log('===> defaultCountry', defaultCountry);

let getUserCountry;

try {
  getUserCountry = require('js-user-country');
  const { id } = getUserCountry.default();
  // const id = 'CA';
  // console.log('==> getUserCountry id', id);
  if (markets.includes(id)) {
    defaultCountry = id;
  }
  // console.log('==> getUserCountry', defaultCountry);
} catch (e) {
  console.log(e);
}

// if (isBrowser()) {
//   defaultCountry = getUserCountry().id;
//   // defaultCountry = 'JP';
// }

console.log('==> getUserCountry', defaultCountry);

export default function useSetting() {
  // const [setting, setSetting] = useState({ lang: 'EN', country: 'TW' });
  const [loading, setLoading] = useState(true);
  const [onlineShop, setOnlineShop] = useState({});
  const [showOnlineShop, setShowOnlineShop] = useState(false);
  const [showSwicthMarket, setShowSwicthMarket] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [storeMayLikeTag, setStoreMayLikeTag] = useState([]);
  const [storeFrontAPIQuery, setStoreFrontAPIQuery] = useState(
    createQuery({
      query: `query Localization {
        localization {
          country {
            name
            unitSystem
            isoCode
            
          }
        }
      }`,
      variables: {},
    })
  );
  const { fetchData: fetchStoreFrontAPIData } = useStoreFrontAPI({
    fetchOne: true,
    query: storeFrontAPIQuery,
    setLoading,
  });

  const hasCheckCountryUrl = useRef(false);

  // const { fetchData: fetchStoreFrontAPIData } = useStoreFrontQueryData({
  //   defaultData: {},
  //   query: storeFrontAPIQuery,
  //   variables: {
  //     // first: 8,
  //     // endCursor: null,
  //   },
  //   // setting,
  //   // pageContext,
  // });

  // const [adminAPIQuery, setAdminAPIQuery] = useState(
  //   adminCreateQuery(
  //     `query Markets {
  //       markets (first:15)	{
  //         nodes {
  //           name
  //         }
  //       }
  //     }`,
  //     {}
  //   )
  // );
  // const { fetchAdminAPIData } = useAdminAPI({
  //   query: adminAPIQuery,
  // });
  // console.log(
  //   '==> getUserCountry',
  //   fetchStoreFrontAPIData,
  //   getUserCountry().id
  // );
  if (isBrowser() && !hasCheckCountryUrl.current) {
    hasCheckCountryUrl.current = true;
    const { ln } = urlParser(window.location);
    // alert(ln);
    const localCoutry = localStorage.getItem('country');
    defaultCountry = ln || localCoutry || defaultCountry;
    // alert(defaultCountry);
    if (defaultCountry === 'TW') {
      defaultCountry =
        fetchStoreFrontAPIData?.localization?.country?.isoCode ||
        defaultCountry;
      // alert(defaultCountry);
    }
    // alert(defaultCountry);
    if (ln) {
      localStorage.setItem('country', ln);
      localStorage.setItem('hasShowLocationAndLanguage_2312', 'true');
    }

    // if (window.location.href.includes('/products/')) {
    //   let pid = window.location.href.split('/products/')[1];
    //   pid = pid.split('/')[0];

    //   const cd = queryFormat({
    //     query: ProductQuery,
    //     variables: {
    //       // first: 8,
    //       // endCursor: null,
    //       id: `gid://shopify/Product/${pid}`,
    //       selectedOptions: [],
    //       // maxHeight: 1680,
    //       maxWidth: 1680,
    //     },
    //     setting: {},
    //     pageContext: {},
    //   });

    //   const p = glStoreFrontFetch({
    //     query: cd,
    //     setFetchData: (data) => {
    //       console.log('==> p111', data?.product?.handle);
    //       const handle = data?.product?.handle;
    //       const cdv = queryFormat({
    //         query: ProductHandleQuery,
    //         variables: {
    //           handle,
    //         },
    //         setting: {},
    //         pageContext: {},
    //       });
    //       console.log('==> p112', cdv);
    //       glStoreFrontFetch({
    //         query: cdv,
    //         customStoreDomain: process.env.GATSBY_INT_SHOPIFY_STORE_DOMAIN,
    //         customStoreApiToken:
    //           process.env.GATSBY_INT_SHOPIFY_STOREFRONT_API_TOKEN,
    //         setFetchData: (data) => {
    //           console.log('==> p112', data?.productByHandle?.id);
    //           let id = data?.productByHandle?.id?.split('/');
    //           id = id[id.length - 1];
    //         },
    //       });
    //     },
    //   });
    // }

    if (!defaultCountry && !ln) {
    } else {
      defaultCountry = defaultCountry || ln || 'TW';
      if (
        CHINESE_LANG.includes(defaultCountry) &&
        window.location.href.includes('https://int.joucos.com')
        // window.location.href.includes('http://localhost:8888/')
      ) {
        localStorage.setItem('country', defaultCountry);
        let slash = window.location.href.split('https://int.joucos.com/')[1];
        // const slash = window.location.href.split('http://localhost:8888/')[1];
        // alert(`https://joucos.com/${slash || ''}?ln=${defaultCountry}`);
        if (slash.includes('?ln=')) {
          slash = slash.split('?ln=')[0];
        }
        if (window.location.href.includes('/products/')) {
          try {
            let pid = window.location.href.split('/products/')[1];
            pid = pid.split('/')[0];
            if (!pid) {
              window.location.href = `https://joucos.com/?ln=${defaultCountry}`;
              return;
            }
            const cd = queryFormat({
              query: ProductQuery,
              variables: {
                // first: 8,
                // endCursor: null,
                id: `gid://shopify/Product/${pid}`,
                selectedOptions: [],
                // maxHeight: 1680,
                maxWidth: 1680,
              },
              setting: {},
              pageContext: {},
            });

            glStoreFrontFetch({
              query: cd,
              setFetchData: (data) => {
                console.log('==> p111', data?.product?.handle);
                const handle = data?.product?.handle;
                const cdv = queryFormat({
                  query: ProductHandleQuery,
                  variables: {
                    handle,
                  },
                  setting: {},
                  pageContext: {},
                });
                if (!handle || !cdv) {
                  window.location.href = `https://joucos.com/?ln=${defaultCountry}`;
                  return;
                }
                console.log('==> p112', cdv);
                // if (cdv) {
                glStoreFrontFetch({
                  query: cdv,
                  customStoreDomain: process.env.GATSBY_SHOPIFY_STORE_DOMAIN,
                  customStoreApiToken:
                    process.env.GATSBY_SHOPIFY_STOREFRONT_API_TOKEN,
                  setFetchData: (data) => {
                    let id = data?.productByHandle?.id?.split('/');
                    if (id) {
                      id = id[id.length - 1];
                      window.location.href = `https://joucos.com/products/${id}/?ln=${defaultCountry}`;
                    } else {
                      window.location.href = `https://joucos.com/?ln=${defaultCountry}`;
                    }
                  },
                });
                // }
              },
            });
          } catch (e) {
            window.location.href = `https://joucos.com/?ln=${defaultCountry}`;
          }
        } else {
          window.location.href = `https://joucos.com/${
            slash || ''
          }?ln=${defaultCountry}`;
        }
        return;
      }
      if (
        !CHINESE_LANG.includes(defaultCountry) &&
        window.location.href.includes('https://joucos.com')
      ) {
        localStorage.setItem('country', defaultCountry);
        let slash = window.location.href.split('https://joucos.com/')[1];
        if (slash.includes('?ln=')) {
          slash = slash.split('?ln=')[0];
        }
        if (window.location.href.includes('/products/')) {
          try {
            let pid = window.location.href.split('/products/')[1];
            pid = pid.split('/')[0];
            if (!pid) {
              window.location.href = `https://int.joucos.com/?ln=${defaultCountry}`;
              return;
            }
            const cd = queryFormat({
              query: ProductQuery,
              variables: {
                // first: 8,
                // endCursor: null,
                id: `gid://shopify/Product/${pid}`,
                selectedOptions: [],
                // maxHeight: 1680,
                maxWidth: 1680,
              },
              setting: {},
              pageContext: {},
            });

            glStoreFrontFetch({
              query: cd,
              setFetchData: (data) => {
                console.log('==> p111', data?.product?.handle);
                const handle = data?.product?.handle;
                const cdv = queryFormat({
                  query: ProductHandleQuery,
                  variables: {
                    handle,
                  },
                  setting: {},
                  pageContext: {},
                });
                console.log('==> p112', cdv);
                if (!handle || !cdv) {
                  window.location.href = `https://int.joucos.com/?ln=${defaultCountry}`;
                  return;
                }
                // if (cdv) {
                glStoreFrontFetch({
                  query: cdv,
                  customStoreDomain:
                    process.env.GATSBY_INT_SHOPIFY_STORE_DOMAIN,
                  customStoreApiToken:
                    process.env.GATSBY_INT_SHOPIFY_STOREFRONT_API_TOKEN,
                  setFetchData: (data) => {
                    console.log('==> p112', data?.productByHandle?.id);
                    let id = data?.productByHandle?.id?.split('/');
                    if (id) {
                      id = id[id.length - 1];
                      window.location.href = `https://int.joucos.com/products/${id}/?ln=${defaultCountry}`;
                    } else {
                      window.location.href = `https://int.joucos.com/?ln=${defaultCountry}`;
                    }
                  },
                });
                // }
              },
            });
          } catch (e) {
            window.location.href = `https://int.joucos.com/?ln=${defaultCountry}`;
          }
          // window.location.href = `https://int.joucos.com/?ln=${defaultCountry}&pid=${pid}`;
        } else {
          window.location.href = `https://int.joucos.com/${
            slash || ''
          }?ln=${defaultCountry}`;
        }
        return;
      }
    }
  }
  console.log('==> setting', defaultCountry);
  const [setting, setSetting] = useState({
    // country: fetchStoreFrontAPIData?.localization?.country?.isoCode
    //   ? fetchStoreFrontAPIData.localization.country.isoCode
    //   : defaultCountry,
    country: defaultCountry,
    markets,
    vinfo:
      typeof window !== 'undefined' &&
      window.localStorage.getItem('vinfo') &&
      JSON.parse(window.localStorage.getItem('vinfo')),
    countryInfo:
      // shopifyCountry[
      //   countryCompare[
      //     fetchStoreFrontAPIData?.localization?.country?.isoCode
      //       ? fetchStoreFrontAPIData.localization.country.isoCode
      //       : defaultCountry
      //   ]
      // ],
      shopifyCountry[countryCompare[defaultCountry]],
    countryName:
      // countryCompare[
      //   fetchStoreFrontAPIData?.localization?.country?.isoCode
      //     ? fetchStoreFrontAPIData.localization.country.isoCode
      //     : defaultCountry
      // ],
      countryCompare[defaultCountry],
  });
  console.log('==> setting', setting);
  function updateSetting(updateData) {
    console.log('==> updateSetting', update(setting, updateData));
    setSetting(update(setting, updateData));
  }
  // useEffect(() => {
  //   // function checkVIPLogin() {
  //     let vinfo = localStorage.getItem('vinfo');
  //     vinfo = JSON.parse(vinfo);
  //     setSetting({
  //       ...setting,
  //     })
  //   // }
  // }, []);
  useEffect(() => {
    if (isBrowser()) {
      const { ln } = urlParser(window.location);
      defaultCountry = ln || localStorage.getItem('country') || defaultCountry;
      if (defaultCountry === 'TW') {
        defaultCountry = fetchStoreFrontAPIData?.localization?.country?.isoCode;
      }
    }
    setSetting({
      ...setting,
      // country: fetchStoreFrontAPIData?.localization?.country?.isoCode
      //   ? fetchStoreFrontAPIData.localization.country.isoCode
      //   : defaultCountry,
      // countryInfo:
      //   shopifyCountry[
      //     countryCompare[
      //       fetchStoreFrontAPIData?.localization?.country?.isoCode
      //         ? fetchStoreFrontAPIData.localization.country.isoCode
      //         : defaultCountry
      //     ]
      //   ],
      // countryName:
      //   countryCompare[
      //     fetchStoreFrontAPIData?.localization?.country?.isoCode
      //       ? fetchStoreFrontAPIData.localization.country.isoCode
      //       : defaultCountry
      //   ],
      country: defaultCountry,
      countryInfo: shopifyCountry[countryCompare[defaultCountry]],
      countryName: countryCompare[defaultCountry],
    });
  }, [fetchStoreFrontAPIData]);

  function updateStoreMayLikeTag(tag) {
    const tags = [...storeMayLikeTag];
    if (!tags.includes(tag)) {
      tags.unshift(tag);
      localStorage.setItem('MAY_LIKE_ITEMS', JSON.stringify(tags));
    }
  }

  useEffect(() => {
    if (isBrowser()) {
      let localStoreMayLikes = localStorage.getItem('MAY_LIKE_ITEMS') || '';
      localStoreMayLikes = localStoreMayLikes
        ? JSON.parse(localStoreMayLikes)
        : [];
      setStoreMayLikeTag(localStoreMayLikes);
    }
  }, []);

  // useEffect(() => {
  //   const url = typeof window !== 'undefined' ? window.location.href : '';
  //   if (!url) {
  //     return;
  //   }
  //   const { origin } = window.location;
  //   const { pathname } = window.location;

  //   let tailUrl = '';
  //   pathname.split('/').map((data, index) => {
  //     console.log('===> data', data, pathname);
  //     if (
  //       ((index === 0 || index === 1) && langsArray.find((s) => s === data)) ||
  //       data === ''
  //     ) {
  //       return;
  //     }
  //     tailUrl += `/${data}`;
  //   });
  //   // tailUrl = tailUrl === '' ? '/' : tailUrl;
  //   console.log('===> tailUrl', tailUrl, setting.lang);
  //   if (setting.lang === 'ZH') {
  //     navigate(`${origin}${tailUrl}`, {
  //       replace: false,
  //     });
  //   } else {
  //     navigate(`${origin}/${setting.lang.toLowerCase()}${tailUrl}`, {
  //       replace: false,
  //     });
  //   }
  // }, [setting.lang]);
  return {
    setting,
    updateSetting,
    loading,
    showSwicthMarket,
    showOnlineShop,
    setShowOnlineShop,
    showSearch,
    setShowSearch,
    storeMayLikeTag,
    updateStoreMayLikeTag,
    staticLocales,
  };
}
