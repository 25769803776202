import React from 'react';
import { RootProvider } from './src/components/RootContext';
import Layout from './src/components/Layout';
// Logs when the client route changes
export function onRouteUpdate({ location, prevLocation }) {
  console.log('new pathname', location.pathname);
  console.log('old pathname', prevLocation ? prevLocation.pathname : null);
}

// Wraps every page in a component
export function wrapRootElement(data) {
  const { element, props } = data;
  console.log('===> wrapRootElement', data, props);
  return <RootProvider {...props}>{element}</RootProvider>;
}

// export function wrapPageElement({ element, props }) {
//   console.log('===> wrapPageElement props', props);
//   return <Layout pageContext={props.pageContext}>{element}</Layout>;
// }
