export default {
  TW: {
    country: 'Taiwan',
    city: 'Taipei',
  },
  AU: {
    country: 'Australia',
    province: 'Australian Capital Territory',
    city: 'Canberra',
  },
  CA: {
    country: 'Canada',
    province: 'Alberta',
    city: 'Airdrie',
  },
  FR: {
    country: 'France',
    city: 'Paris',
  },
  HK: {
    country: 'Hong Kong',
    province: 'Hong Kong Island',
    city: 'Wan Chai District',
  },
  JP: {
    country: 'Japan',
    province: 'Tōkyō',
    city: 'Tokyo',
  },
  KR: {
    country: 'South Korea',
    province: 'Seoul',
    city: 'Seoul',
  },
  MO: {
    country: 'Macao',
    city: 'Macao',
  },
  MY: {
    country: 'Malaysia',
    province: 'Selangor',
    city: 'Kuala Lumpur',
  },
  SG: {
    country: 'Singapore',
    city: 'Singapore City',
  },
  GB: {
    country: 'United Kingdom',
    province: 'England',
    city: 'London',
  },
  US: {
    country: 'United States',
    province: 'New York',
    city: 'New York City',
  },
  DE: {
    country: 'Germany',
    city: 'Berlin',
  },
  CZ: {
    country: 'Czech Republic',
    // province: 'Praha',
    city: 'Praha',
  },
  IT: {
    country: 'Italy',
    province: 'Roma',
    city: 'Roma',
  },
  TH: {
    country: 'Thailand',
    province: 'Bangkok',
    city: 'Bangkok',
  },
  VN: {
    country: 'Vietnam',
    // province: 'Đông Nam Bộ',
    city: 'Ho Chi Minh',
  },
  PH: {
    country: 'Philippines',
    province: 'Metro Manila',
    city: 'Manila',
  },
  NL: {
    country: 'Netherlands',
    // province: '',
    city: 'Amsterdam',
  },
  ES: {
    country: 'Spain',
    province: 'Madrid',
    city: 'Madrid',
  },
  SE: {
    country: 'Sweden',
    // province: '',
    city: 'Stockholm',
  },
  NZ: {
    country: 'New Zealand',
    province: 'Wellington',
    city: 'Wellington',
  },
  PL: {
    country: 'Poland',
    // province: '',
    city: 'Warszawa',
  },
  AT: {
    country: 'Austria',
    // province: '',
    city: 'Vienna',
  },
  CH: {
    country: 'Switzerland',
    // province: '',
    city: 'Berne',
  },
  HU: {
    country: 'Hungary',
    // province: '',
    city: 'Budapest',
  },
  BE: {
    country: 'Belgium',
    // province: '',
    city: 'brussels',
  },
  DK: {
    country: 'Denmark',
    // province: '',
    city: 'Copenhagen',
  },
  PT: {
    country: 'Portugal',
    province: 'Lisboa',
    city: 'Lisbon',
  },
  NO: {
    country: 'Norway',
    // province: '',
    city: 'Oslo',
  },
};
