import { order } from './orderQuery';

export const AccountLoginQuery = `
query Customer($customerAccessToken: String!) {
  customer(customerAccessToken: $customerAccessToken) {
    id
    acceptsMarketing
    email
    firstName
    lastName
    phone
    defaultAddress {
      address1
      address2
      city
      company
      country
      countryCodeV2
      firstName
      formattedArea
      id
      lastName
      latitude
      longitude
      name
      phone
      province
      provinceCode
      zip
    }
    displayName
    
    numberOfOrders
    tags
    addresses(first: 10) {
      nodes {
        address1
        address2
        city
        company
        country
        countryCodeV2
        firstName
        formattedArea
        id
        lastName
        latitude
        longitude
        name
        phone
        province
        provinceCode
        zip
      }
    }   
    wishlist: metafield(key: "wishlist", namespace: "custom") {
      value
    }
    notify: metafield(key: "notify", namespace: "custom") {
      id
      key
      namespace
      value
    }
  } 
}`;

export const AccountRegisterMutation = `
mutation CustomerCreate($input: CustomerCreateInput!) {
  customerCreate(input: $input) {
    customer {
      id
      acceptsMarketing
      email
      firstName
      lastName
      phone
      defaultAddress {
        address1
          address2
          city
          company
          country
          countryCodeV2
          firstName
          formattedArea
          id
          lastName
          latitude
          longitude
          name
          phone
          province
          provinceCode
          zip
      }
      displayName
      
      numberOfOrders
      tags
      addresses(first: 10) {
        nodes {
          address1
          address2
          city
          company
          country
          countryCodeV2
          firstName
          formattedArea
          id
          lastName
          latitude
          longitude
          name
          phone
          province
          provinceCode
          zip
        }
      }   
    }
    customerUserErrors {
      code
      field
      message
    }
  }    
}`;
// defaultAddress;
// lastIncompleteCheckout

export const AccountRegisterMutationInput = {
  input: {
    acceptsMarketing: true,
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    // email: 'cos@joucos.com',
    // // email: '',
    // firstName: 'Cos',
    // lastName: 'Hsu',
    // password: '111111',
    // phone: '',
  },
};

export const AccountActivateByURLMutation = `
mutation CustomerActivateByUrl($activationUrl: URL!, $password: String!) {
  customerActivateByUrl(activationUrl: $activationUrl, password: $password) {
    customer {
      id
      acceptsMarketing
      email
      firstName
      lastName
      phone
      defaultAddress {
        address1
          address2
          city
          company
          country
          countryCodeV2
          firstName
          formattedArea
          id
          lastName
          latitude
          longitude
          name
          phone
          province
          provinceCode
          zip
      }
      
      displayName
      
      numberOfOrders
      tags
      addresses(first: 10) {
        nodes {
          address1
          address2
          city
          company
          country
          countryCodeV2
          firstName
          formattedArea
          id
          lastName
          latitude
          longitude
          name
          phone
          province
          provinceCode
          zip
        }
      } 
    }
    customerAccessToken {
      accessToken
      expiresAt
    }
    customerUserErrors {
      code
      field
      message
    }
  }    
}`;

export const AccountActivateByURLMutationInput = {
  activationUrl: '',
  password: '',
};

export const AccountLoginMutation = `
mutation CustomerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
  customerAccessTokenCreate(input: $input) {
    customerAccessToken {
      accessToken
      expiresAt
    }
    customerUserErrors {
      code
      field
      message
    }
  }
}`;

export const AccountLoginMutationInput = {
  input: {
    email: '',
    password: '',
  },
};

export const AccountLogoutMutation = `
mutation CustomerAccessTokenDelete($customerAccessToken: String!) {
  customerAccessTokenDelete(customerAccessToken: $customerAccessToken) {
    deletedAccessToken
    deletedCustomerAccessTokenId
    userErrors {
      field
      message
    }
  }
}`;

export const AccountLogoutMutationInput = {
  customerAccessToken: '',
};

export const AccountRecoverMutation = `
mutation CustomerRecover($email: String!) {
  customerRecover(email: $email) {
    customerUserErrors {
      code
      field
      message
    }
  }
}`;

export const AccountRecoverMutationInput = {
  email: '',
};

export const AccountResetByUrlMutation = `
mutation CustomerResetByUrl($password: String!, $resetUrl: URL!) {
  customerResetByUrl(password: $password, resetUrl: $resetUrl) {
    customer {
      id
      acceptsMarketing
      email
      firstName
      lastName
      phone
      defaultAddress {
        address1
          address2
          city
          company
          country
          countryCodeV2
          firstName
          formattedArea
          id
          lastName
          latitude
          longitude
          name
          phone
          province
          provinceCode
          zip
      }
      
      displayName
      
      numberOfOrders
      tags
      addresses(first: 10) {
        nodes {
          address1
          address2
          city
          company
          country
          countryCodeV2
          firstName
          formattedArea
          id
          lastName
          latitude
          longitude
          name
          phone
          province
          provinceCode
          zip
        }
      } 
    }
    customerAccessToken {
      accessToken
      expiresAt
    }
    customerUserErrors {
      code
      field
      message
    }
  }
}`;

export const AccountResetByUrlMutationInput = {
  password: '',
  resetUrl: '',
};

export const AccountAddressOrderQuery = `
query CustomerAddressOrder( $country: CountryCode $language: LanguageCode $first: Int $last: Int $startCursor: String $endCursor: String $customerAccessToken: String! )
  @inContext(country: $country, language: $language) {
    customer(customerAccessToken: $customerAccessToken) {
      addresses(first: 10, last: $last, before: $startCursor, after: $endCursor) {
        nodes {
          address1
          address2
          city
          company
          country
          countryCodeV2
          firstName
          formattedArea
          id
          lastName
          latitude
          longitude
          name
          phone
          province
          provinceCode
          zip
        }
      }   
      orders(first: $first, last: $last, before: $startCursor, after: $endCursor) {
        nodes {
          billingAddress {
            
              address1
              address2
              city
              company
              country
              countryCodeV2
              firstName
              formattedArea
              id
              lastName
              latitude
              longitude
              name
              phone
              province
              provinceCode
              zip
            
          }
          cancelReason
          canceledAt
          currencyCode
          currentSubtotalPrice {
            amount
            currencyCode
          }
          currentTotalDuties
          {
            amount
            currencyCode
          }
          currentTotalPrice
          {
            amount
            currencyCode
          }
          currentTotalTax
          {
            amount
            currencyCode
          }
          
          customAttributes {
            key
            value
          }
          customerUrl
          edited
          email
          financialStatus
          fulfillmentStatus
          name
          orderNumber
          originalTotalDuties {
            amount
            currencyCode
          }
          originalTotalPrice {
            amount
            currencyCode
          }
          phone
          processedAt
          shippingAddress {
            
            address1
            address2
            city
            company
            country
            countryCodeV2
            firstName
            formattedArea
            id
            lastName
            latitude
            longitude
            name
            phone
            province
            provinceCode
            zip
          
          }
          shippingDiscountAllocations {
            
              allocatedAmount {
                amount
                currencyCode
              }
              discountApplication {
                allocationMethod
                targetSelection
                targetType
                value
              }
            
          }
          statusUrl
          subtotalPrice {
            amount
            currencyCode
          }
          successfulFulfillments(first: 5) {
            trackingCompany

          }
          totalPrice {
            amount
            currencyCode
          }
          totalRefunded {
            amount
            currencyCode
          }
          totalShippingPrice {
            amount
            currencyCode
          }
          totalTax {
            amount
            currencyCode
          }
          metafield(key: "timeline", namespace: "custom") {
            value
          }
          lineItems(first: 24) {
            nodes {
              currentQuantity
              customAttributes {
                key
                value
              }
              discountAllocations {
                allocatedAmount {
                  amount
                  currencyCode
                }
                discountApplication {
                  allocationMethod
                  targetSelection
                  targetType
                  value
                }
              }
              discountedTotalPrice {
                amount
                currencyCode
              }
              originalTotalPrice {
                amount
                currencyCode
              }
              title
              quantity
              variant {
                availableForSale
                barcode
                compareAtPrice {
                  amount
                  currencyCode
                }
                currentlyNotInStock
                id
                image {
                  altText
                    height
                    id
                    url
                    
                    width
                }
                price {
                  amount
                  currencyCode
                }
                
                quantityAvailable
                requiresShipping
                selectedOptions {
                  name
                  value
                }
                sku
                title
                unitPrice {
                  amount
                  currencyCode
                }
                unitPriceMeasurement {
                  measuredType
                  quantityUnit
                  quantityValue
                  referenceUnit
                  referenceValue
                }
                weight
                weightUnit
                sellingPlanAllocations(first: 24) {
                  nodes {
                    checkoutChargeAmount{
                      amount
                      currencyCode
                    }
                    priceAdjustments {
                      compareAtPrice{
                        amount
                        currencyCode
                      }
                      perDeliveryPrice{
                        amount
                        currencyCode
                      }
                      price{
                        amount
                        currencyCode
                      }
                      unitPrice{
                        amount
                        currencyCode
                      }
                    }
                    remainingBalanceChargeAmount{
                      amount
                      currencyCode
                    }
                    sellingPlan {
                      checkoutCharge {
                        type
                        value
                      }
                      description
                      id
                      name
                      options {
                        name
                        value
                      }
                      priceAdjustments {
                        adjustmentValue
                        orderCount
                      }
                      recurringDeliveries
                    }
                  }
                }
                storeAvailability(first: 24) {
                  nodes {
                    available
                    location {
                      address {
                        address1
                        address2
                        city
                        country
                        
                        latitude
                        longitude
                        phone
                        province
                        provinceCode
                        zip
                      }
                    }
                    pickUpTime
                  }
                }
              }
            }
          }
        }
      }   
    }
  }
`;

export const AccountCreateAddressMutation = `
mutation CustomerAddressCreate($address: MailingAddressInput!, $customerAccessToken: String!) {
  customerAddressCreate(address: $address, customerAccessToken: $customerAccessToken) {
    customerAddress {
      address1
        address2
        city
        company
        country
        countryCodeV2
        firstName
        formattedArea
        id
        lastName
        latitude
        longitude
        name
        phone
        province
        provinceCode
        zip
    }
    customerUserErrors {
      code
      field
      message
    }
  }
}`;

export const AccountCreateAddressMutationInput = {
  address: {
    address1: '',
    address2: '',
    city: '',
    company: '',
    country: '',
    firstName: '',
    lastName: '',
    phone: '',
    province: '',
    zip: '',
  },
  customerAccessToken: '',
};

export const AccountUpdateAddressMutation = `
mutation CustomerAddressUpdate($address: MailingAddressInput!, $customerAccessToken: String!, $id: ID!) {
  customerAddressUpdate(address: $address, customerAccessToken: $customerAccessToken, id: $id) {
    customerAddress {
      address1
        address2
        city
        company
        country
        countryCodeV2
        firstName
        formattedArea
        id
        lastName
        latitude
        longitude
        name
        phone
        province
        provinceCode
        zip
    }
    customerUserErrors {
      code
      field
      message
    }
  }
}`;

export const AccountUpdateAddressMutationInput = {
  address: {
    address1: '',
    address2: '',
    city: '',
    company: '',
    country: '',
    firstName: '',
    lastName: '',
    phone: '',
    province: '',
    zip: '',
  },
  customerAccessToken: '',
  id: '',
};

export const AccountDeleteAddressMutation = `
mutation CustomerAddressDelete($customerAccessToken: String!, $id: ID!) {
  customerAddressDelete(customerAccessToken: $customerAccessToken, id: $id) {
    customerUserErrors {
      code
      field
      message
    }
    deletedCustomerAddressId
  }
}`;

export const AccountDeleteAddressMutationInput = {
  customerAccessToken: '',
  id: '',
};

export const AccountDefaultAddressMutation = `
mutation CustomerDefaultAddressUpdate($addressId: ID!, $customerAccessToken: String! $last: Int $startCursor: String $endCursor: String) {
  customerDefaultAddressUpdate(addressId: $addressId, customerAccessToken: $customerAccessToken) {
    customer {
      defaultAddress {
        address1
        address2
        city
        company
        country
        countryCodeV2
        firstName
        formattedArea
        id
        lastName
        latitude
        longitude
        name
        phone
        province
        provinceCode
        zip
      }
      addresses(first: 10, last: $last, before: $startCursor, after: $endCursor) {
        nodes {
          address1
          address2
          city
          company
          country
          countryCodeV2
          firstName
          formattedArea
          id
          lastName
          latitude
          longitude
          name
          phone
          province
          provinceCode
          zip
        }
      }   
    }
    customerUserErrors {
      code
      field
      message
    }
  }
}`;

export const AccountDefaultAddressMutationInput = {
  customerAccessToken: '',
  id: '',
};

export const AccountOrderQuery = `
query Customer($customerAccessToken: String! $after: String) {
  customer(customerAccessToken: $customerAccessToken) {
    orders(first: 5, after: $after, reverse: true) {
      edges {
        cursor
        node {
          ${order}
        }
      }
    }   
  }
}
`;

export const AccountOrderForExQuery = `
query Customer($customerAccessToken: String! $after: String) {
  customer(customerAccessToken: $customerAccessToken) {
    orders(first: 240, after: $after, reverse: true) {
      edges {
        cursor
        node {
          ${order}
        }
      }
    }   
  }
}
`;

export const AccountUpdateMutation = `
mutation customerUpdate($customer: CustomerUpdateInput!, $customerAccessToken: String!) {
  customerUpdate(customer: $customer, customerAccessToken: $customerAccessToken) {
    customer {
      id
      acceptsMarketing
      email
      firstName
      lastName
      phone
      defaultAddress {
        address1
          address2
          city
          company
          country
          countryCodeV2
          firstName
          formattedArea
          id
          lastName
          latitude
          longitude
          name
          phone
          province
          provinceCode
          zip
      }
      displayName
      
      numberOfOrders
      tags
      addresses(first: 10) {
        nodes {
          address1
          address2
          city
          company
          country
          countryCodeV2
          firstName
          formattedArea
          id
          lastName
          latitude
          longitude
          name
          phone
          province
          provinceCode
          zip
        }
      }   
    }
    customerAccessToken {
      accessToken
      expiresAt
    }
    customerUserErrors {
      code
      field
      message
    }
  }
}`;
// defaultAddress;
// lastIncompleteCheckout

export const AccountUpdateMutationInput = {
  customer: {
    acceptsMarketing: true,
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    phone: '',
  },
  customerAccessToken: '',
};

// localization {
//   language{ #The language of the active localized experience.
//     isoCode
//     name
//   }
//   availableCountries {
//     currency {
//       isoCode
//       name
//       symbol
//     }
//     isoCode
//     name
//     unitSystem
//   }
//   country { #The currency of the active localized experience.
//     currency {
//       isoCode
//       name
//       symbol
//     }
//     isoCode
//     name
//     unitSystem
//   }
// }
