import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery, Link } from 'gatsby';
import 'normalize.css';
import RootContext from './RootContext';
import Navigation from './Navgation';
import NavgationInfluencer from './NavgationInfluencer';
import Footer from './Footer';
import GlobalStyles from '../styles/GlobalStyles';
import * as style from '../styles/style.css';
import SEO from './SEO';
import { isBrowser } from '../helpers/browser';
import CookiesAccept from './CookiesAccept';
import Popup from './Popup';
import Loading from './Loading';

const useAnalytics =
  typeof window !== `undefined`
    ? require('../utils/useAnalytics').useAnalytics
    : null;
// let useAnalytics;
// if (isBrowser()) {
//   {useAnalytics} = require('../utils/useAnalytics')
// }
// import { useAnalytics } from '../utils/useAnalytics';

const Conatiner = styled.div`
  /* padding */
  padding-top: ${(props) =>
    props.hideNavigation || props.showBehide ? 0 : '113px'};
  // return var(--navHeight);
  min-height: calc(100% - var(--navHeight) - var(--footerHeight));
  display: ${(props) => {
    console.log('==> Conatiner props', props);
    return props.display || 'table';
  }};
  height: inherit;
  width: 100%;
  @include iphonex-support;

  @mixin device-full-screen {
    // iOS < 11.2
    padding-left: constant(safe-area-inset-left);
    padding-top: constant(safe-area-inset-top);
    padding-right: constant(safe-area-inset-right);
    padding-bottom: constant(safe-area-inset-bottom);
    // iOS >= 11.2
    padding-left: env(safe-area-inset-left);
    padding-top: env(safe-area-inset-top);
    padding-right: env(safe-area-inset-right);
    padding-bottom: env(safe-area-inset-bottom);
  }

  @mixin iphonex-support {
    @supports (padding: max(0px)) {
      &.hack_iphoneFullScreen {
        @include device-full-screen;
      }
    }
  }
`;

export default function Layout({
  uri,
  children,
  pageContext,
  headerMenuData,
  containerClassName = '',
  metadata = {},
  titlePosition,
  params,
  cart,
  hideFooter = false,
  hideNavigation = false,
  showBehide = false,
  style = {},
  display,
  containerStyle = {},
  themeColor = false,
  hasSquareLogo = false,
  pageLoading = false,
  location,
  pageLoadingController = {},
  serverData = {},
  hideCountdown = false,
  footerClassName = '',
  lockWheel = () => {},
  setOnlineShopMenu = () => {},
  isFull = true,
  scale = 1,
  useJSON = false,
  navigationStyle = {},
  isInfluencer = false,
  // popupInfo: pop = {},
  // setPopupInfo: setPop = () => {},
  // loading,
}) {
  console.log('==> scale', scale);
  const { popupInfo, setPopupInfo } = useContext(RootContext);
  // console.log('===> pageContext', pageContext);
  // if (loading) {
  //   return loading;
  // }
  // locale = {
  //   currency: 'TWD'
  //   language: pageContext.language,
  // }
  // console.log('===> vc', vc);
  if (isBrowser()) {
    useAnalytics(true, {
      currency: 'TWD',
      language: pageContext.language.toUpperCase(),
      uri: pageContext.uri,
    });
    // const cc = initCookieConsent();
    // console.log('====> cookie', cc);
  }
  // useEffect(() => {
  //   console.log('==> pop', pop);
  //   setPopupInfo(pop);
  // }, [pop]);
  // }
  console.log(
    '==> hasSquareLogo1',
    hasSquareLogo,
    hasSquareLogo ? 'hasSquareLogo' : ''
  );
  return (
    <>
      <SEO
        pageContext={pageContext}
        metadata={metadata}
        titlePosition={titlePosition}
        themeColor={themeColor}
        scale={scale}
      />
      <GlobalStyles />
      {/* {!hideNavigation && isInfluencer ? (
        <NavgationInfluencer
          pageContext={pageContext}
          uri={uri}
          location={location}
          serverData={serverData}
          hideCountdown={hideCountdown}
          lockWheel={lockWheel}
          setOnlineShopMenu={setOnlineShopMenu}
          useJSON={useJSON}
          navigationStyle={navigationStyle}
        />
      ) : (
        <Navigation
          pageContext={pageContext}
          uri={uri}
          location={location}
          serverData={serverData}
          hideCountdown={hideCountdown}
          lockWheel={lockWheel}
          setOnlineShopMenu={setOnlineShopMenu}
          useJSON={useJSON}
          navigationStyle={navigationStyle}
        />
      )} */}
      {!hideNavigation && (
        <Navigation
          pageContext={pageContext}
          uri={uri}
          location={location}
          serverData={serverData}
          hideCountdown={hideCountdown}
          lockWheel={lockWheel}
          setOnlineShopMenu={setOnlineShopMenu}
          useJSON={useJSON}
          navigationStyle={navigationStyle}
        />
      )}
      <Conatiner
        className={`layout-container ${containerClassName}`}
        display={display}
        hideNavigation={hideNavigation}
        style={containerStyle}
        showBehide={showBehide}
      >
        {children}
        {isBrowser() &&
          !localStorage.getItem('hasShowLocationAndLanguage_2312') && (
            <CookiesAccept />
          )}
      </Conatiner>
      {!hideFooter && (
        <Footer
          pageContext={pageContext}
          params={params}
          hasSquareLogo={hasSquareLogo ? 'hasSquareLogo' : ''}
          className={footerClassName}
          useJSON={useJSON}
          // hasSquareLogo="hasSquareLogo"
        />
      )}
      <Popup popupInfo={popupInfo} setPopupInfo={setPopupInfo} />
      <Loading
        type="XLarge"
        loading={pageLoading}
        controller={pageLoadingController}
        isFull={isFull}
      />
    </>
  );
}

// export const query = graphql`
//   query($language: String!) {
//     locales: allLocale(filter: { language: { eq: $language } }) {
//       edges {
//         node {
//           ns
//           data
//           language
//         }
//       }
//     }
//   }
// `;
