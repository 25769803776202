// import { useFetchers, useMatches } from '@remix-run/react';
// useLocation,
import {
  AnalyticsEventName,
  getClientBrowserParameters,
  sendShopifyAnalytics,
  useShopifyCookies,
  AnalyticsPageType,
} from '@shopify/hydrogen';
import { useEffect } from 'react';
import { CartAction } from '../constants/type';
import { isBrowser } from '../helpers/browser';

// function useDataFromFetchers({ formDataKey, formDataValue, dataKey }) {
//   const fetchers = useFetchers();
//   const data = {};

//   for (const fetcher of fetchers) {
//     const formData = fetcher.submission?.formData;
//     const fetcherData = fetcher.data;
//     if (
//       formData &&
//       formData.get(formDataKey) === formDataValue &&
//       fetcherData &&
//       fetcherData[dataKey]
//     ) {
//       Object.assign(data, fetcherData[dataKey]);

//       try {
//         if (formData.get(dataKey)) {
//           const dataInForm = JSON.parse(String(formData.get(dataKey)));
//           Object.assign(data, dataInForm);
//         }
//       } catch {
//         // do nothing
//       }
//     }
//   }
//   return Object.keys(data).length ? data : undefined;
// }

function useDataFromMatches(dataKey) {
  // const matches = useMatches();
  const data = {};

  // matches.forEach((event) => {
  //   const eventData = event?.data;
  //   if (eventData && eventData[dataKey]) {
  //     Object.assign(data, eventData[dataKey]);
  //   }
  // });

  return data;
}

export function useAnalytics(hasUserConsent, locale, currentUri) {
  const uri = currentUri || locale.uri;
  let hasConsent = localStorage.getItem('c-disable') || 'false';
  hasConsent = JSON.parse(hasConsent);
  if (!isBrowser() || hasConsent) return;
  useShopifyCookies({ hasConsent });
  // const location = useLocation();
  const analyticsFromMatches = useDataFromMatches('analytics');

  const pageAnalytics = {
    ...analyticsFromMatches,
    currency: locale.currency,
    acceptedLanguage: locale.language,
    hasConsent,
  };
  // else if (uri) {
  //   pageType = AnalyticsPageType.customersRegister;
  // }

  // Page view analytics
  // We want useEffect to execute only when location changes
  // which represents a page view
  useEffect(() => {
    let pageType;
    if (uri?.includes('login')) {
      pageType = AnalyticsPageType.customersLogin;
    } else if (uri?.includes('register') || uri?.includes('registration')) {
      pageType = AnalyticsPageType.customersRegister;
    } else if (uri === '/zh/' || uri === '' || uri === '/') {
      pageType = AnalyticsPageType.home;
    } else if (
      uri?.includes('account') &&
      !uri?.includes('register') &&
      !uri?.includes('login') &&
      !uri?.includes('reset') &&
      !uri?.includes('activate')
    ) {
      pageType = AnalyticsPageType.customersAccount;
    } else if (uri?.includes('activate')) {
      pageType = AnalyticsPageType.customersActivateAccount;
    } else if (uri?.includes('reset')) {
      pageType = AnalyticsPageType.customersResetPassword;
    } else if (uri?.includes('404')) {
      pageType = AnalyticsPageType.notFound;
    } else if (uri?.includes('privacy')) {
      pageType = 'privacy';
    } else if (uri?.includes('terms')) {
      pageType = 'terms';
    }
    console.log(pageType, uri);

    const payload = {
      ...getClientBrowserParameters(),
      ...pageAnalytics,
      shopId: `gid://shopify/Shop/${74071441707}`,
      pageType,
    };
    console.log('===> opppppppp', {
      eventName: AnalyticsEventName.PAGE_VIEW,
      payload,
      schema_id: 'trekkie_storefront_page_view/1.4',
    });
    sendShopifyAnalytics({
      eventName: AnalyticsEventName.PAGE_VIEW,
      payload,
      schema_id: 'trekkie_storefront_page_view/1.4',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uri]);

  // // Add to cart analytics
  // const cartData = useDataFromFetchers({
  //   formDataKey: 'cartAction',
  //   formDataValue: CartAction.ADD_TO_CART,
  //   dataKey: 'analytics',
  // });
  // if (cartData) {
  //   const addToCartPayload = {
  //     ...getClientBrowserParameters(),
  //     ...pageAnalytics,
  //     ...cartData,
  //   };

  //   sendShopifyAnalytics({
  //     eventName: AnalyticsEventName.ADD_TO_CART,
  //     payload: addToCartPayload,
  //   });
  // }
}

/**
 * Collects data under a certain key from useMatches
 * @param dataKey - The key in `event.data` to collect data from
 * @returns A merged object of the specified key
 *
 * @example
 * ```tsx
 * import {
 *   useDataFromMatches
 * } from '@shopify/hydrogen';
 *
 * export async function loader({request, context}: LoaderArgs) {
 *   return defer({
 *     analytics: {
 *       shopId: 'gid://shopify/Shop/1',
 *     },
 *   });
 * }
 *
 * export default function App() {
 *   const analytics = useDataFromMatches('analytics');
 *
 *   console.log(analytics);
 *   // {
 *   //   shopId: 'gid://shopify/Shop/1',
 *   // }
 * ```
 * */

/**
 * Collects data under a certain key from useFetches.
 *
 * @param formDataKey - The form data key
 * @param formDataValue - The value of formDataKey
 * @param dataKey - the key in `fetcher.data` to collect data from
 * @returns A merged object of the specified key
 *
 * @example
 * ```tsx
 * // In routes/cart.tsx
 * import {
 *   useDataFromFetchers
 * } from '@shopify/hydrogen';
 *
 * export async function action({request, context}: ActionArgs) {
 *   const cartId = await session.get('cartId');
 *   ...
 *   return json({
 *     analytics: {
 *       cartId,
 *     },
 *   });
 * }
 *
 * // Anywhere when an action can be requested, make sure there is a form input and value
 * // to identify the fetcher
 * export function AddToCartButton({
 *   ...
 *   return (
 *     <fetcher.Form action="/cart" method="post">
 *       <input type="hidden" name="cartAction" value={CartAction.ADD_TO_CART} />
 *
 * // You can add additional data as hidden form inputs and it will also be collected
 * // As long as it is JSON parse-able.
 * export function AddToCartButton({
 *
 *   const analytics = {
 *     products: [product]
 *   };
 *
 *   return (
 *     <fetcher.Form action="/cart" method="post">
 *       <input type="hidden" name="cartAction" value={CartAction.ADD_TO_CART} />
 *       <input type="hidden" name="analytics" value={JSON.stringify(analytics)} />
 *
 * // In root.tsx
 * export default function App() {
 *   const cartData = useDataFromFetchers({
 *     formDataKey: 'cartAction',
 *     formDataValue: CartAction.ADD_TO_CART,
 *     dataKey: 'analytics',
 *   });
 *
 *   console.log(cartData);
 *   // {
 *   //   cartId: 'gid://shopify/Cart/abc123',
 *   //   products: [...]
 *   // }
 * ```
 * */
