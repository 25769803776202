const langCount = {
  ZH: 0,
  EN: 1,
};

export function getColorFromOptionsValue(value, language = 'ZH') {
  let colors = value?.split('#');
  if (!colors || !colors[0]) {
    return '';
  }
  console.log('==> getOptionsColor12', colors);
  colors = colors[0].split('-');
  console.log(
    '==> getOptionsColor1',
    value,
    colors,
    colors[langCount[language]],
    language
  );
  // colors =
  //   (options[0]?.values && options[0]?.values[0]?.split('-')) ||
  //   (options[0]?.value && options[0]?.value.split('-'));
  // console.log('==> options', options, options[0]?.values, colors);
  if (!colors) {
    return '';
  }
  const color = colors[1];
  console.log('===> copp1', color);
  // if (color?.split('>')?.length > 1) {
  //   let linear = 'linear-gradient(90deg';
  //   // color.split('>').map((item) => {
  //   //   linear += `#${item}`;
  //   // });
  //   color.split('>').map((item, index) => {
  //     linear += `, #${item}`;
  //   });
  //   linear += ')';
  //   console.log('===> copp1', linear);
  //   return linear;
  // }
  return `${colors[langCount[language]] || ''}`;
}

export function getOptionsColor(options = [], language = 'ZH') {
  let colors =
    (options[0]?.values && options[0]?.values[0]?.split('#')) ||
    (options[0]?.value && options[0]?.value.split('#'));
  // console.log('==> getOptionsColor', colors);
  if (!colors || !colors[0]) {
    return '';
  }
  colors = colors[0].split('-');
  // console.log('==> getOptionsColor1', colors[langCount[language]]);
  // colors =
  //   (options[0]?.values && options[0]?.values[0]?.split('-')) ||
  //   (options[0]?.value && options[0]?.value.split('-'));
  // console.log('==> options', options, options[0]?.values, colors);
  if (!colors) {
    return '';
  }
  const color = colors[1];
  // console.log('===> copp1', color);
  // if (color?.split('>')?.length > 1) {
  //   let linear = 'linear-gradient(90deg';
  //   // color.split('>').map((item) => {
  //   //   linear += `#${item}`;
  //   // });
  //   color.split('>').map((item, index) => {
  //     linear += `, #${item}`;
  //   });
  //   linear += ')';
  //   console.log('===> copp1', linear);
  //   return linear;
  // }
  return `${colors[langCount[language === 'ZH_TW' ? 'ZH' : language]] || ''}`;
}

export function getOptionsColorHEX(options = []) {
  const colors =
    (options[0]?.values && options[0]?.values[0]?.split('#')) ||
    (options[0]?.value && options[0]?.value.split('#'));
  console.log('==> options', options, options[0]?.values, colors);
  if (!colors) {
    return '';
  }
  const color = colors[1];
  console.log('===> copp', color);
  if (color?.split('>')?.length > 1) {
    let linear = 'linear-gradient(90deg';
    // color.split('>').map((item) => {
    //   linear += `#${item}`;
    // });
    color.split('>').map((item, index) => {
      linear += `, #${item}`;
    });
    linear += ')';
    console.log('===> copp1', linear);
    return linear;
  }
  // linear-gradient(45deg, blue, red);
  return `${colors[1] ? `#${colors[1]}` : ''}`;
}

export function getColorHEX(array = []) {
  let color = '';
  if (array.length === 1) {
    return array[0];
  }
  if (array.length > 1) {
    color = 'linear-gradient(90deg';
    array.map((item, index) => {
      color += `, ${item}`;
    });
    color += ')';
  }
  return color;
}
