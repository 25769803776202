export function urlParser(location) {
  if (!location) {
    if (typeof window !== 'undefined') {
      location = window.location;
    } else {
      return {};
    }
  }
  console.log('==> location', location);
  let params = location.search.split('?')[1];
  if (!params) {
    return {};
  }
  params = params.split('&');
  const resultObj = {};
  params.map((param) => {
    const result = param.split('=');
    const [key, value] = result;
    resultObj[key] = value;
    if (result.length > 2) {
      resultObj[key] = value;
      result.map((item, index) => {
        if (index !== 0 && index !== 1) {
          resultObj[result[0]] += `=${result[index]}`;
        }
      });
    }
    return param;
  });
  return resultObj;
  // const verifyUrl = location.search.split('?url=')[1];
}

export function langPrefix(pageContext, location = { origin: '' }) {
  // return '';
  return pageContext.language !== 'en' && pageContext.language !== ''
    ? pageContext.language === 'zh_tw'
      ? '/zh'
      : `/${pageContext.language}`
    : '';
  // return pageContext.language !== 'en' && pageContext.language !== ''
  //   ? `${location.origin}/${pageContext.language}`
  //   : `${location.origin}`;
}
