import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

// titlePosition = 'tail', 'head', 'none'

export default function SEO(props) {
  const {
    title,
    metadata,
    titlePosition = 'tail',
    themeColor,
    scale = 1,
  } = props;
  console.log('===> SEO props', props, themeColor);
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `);
  const titleTemplate =
    titlePosition === 'head'
      ? `${site.siteMetadata.title} - %s`
      : titlePosition === 'tail'
      ? `%s - ${site.siteMetadata.title}`
      : `%s`;
  console.log('===> titleTemplate', titleTemplate);
  return (
    <Helmet
      titleTemplate={titleTemplate}
      defaultTitle={`${site.siteMetadata.title}`}
    >
      <html lang={props.pageContext.language} />
      <title>{metadata.title || 'JOUCOS'}</title>

      {/* <meta name="theme-color" content="#872605" /> */}

      {/* {themeColor && <meta name="apple-mobile-web-app-capable" content="yes" />}
      {themeColor && <meta name="theme-color" content={themeColor} />}
      {themeColor && (
        <meta
          name="theme-color"
          content={themeColor}
          media="(prefers-color-scheme: dark)"
        />
      )}
      {themeColor && (
        <meta
          name="theme-color"
          content={themeColor}
          media="(prefers-color-scheme: light)"
        />
      )} */}
      <meta
        name="theme-color"
        content="black"
        media="(prefers-color-scheme: dark)"
      />
      <meta
        name="theme-color"
        content="black"
        media="(prefers-color-scheme: light)"
      />
      <meta
        name="viewport"
        // content="width=device-width, initial-scale=1.0, viewport-fit=cover, minimum-scale=1, maximum-scale=1.0, user-scalable=0"
        content={`width=device-width,minimum-scale=1,initial-scale=1,maximum-scale=${scale},user-scalable=${
          scale !== 1 ? 1 : 0
        },viewport-fit=cover`}
      />
      {/* <meta name="viewport" content="width=device-width initial-scale=1.0" /> */}

      <meta name="description" content={`${metadata.description}`} />

      {/* <meta
        name="viewport"
        content="width=device-width, initial-scale=4.0, viewport-fit=cover, user-scalable=1"
        // data-react-helmet="true"
      /> */}
      <meta
        name="keywords"
        content="fashion,jeans,clothes,new collection,store,online,accessories,shop,passion,love,wearability,craftsmanship,beauteous,attractive,glitter,poetic,rhapsodical"
      />
      <meta property="og:site_name" content="JOUCOS" />
      <meta property="og:title" content={metadata.title || 'JOUCOS'} />
      <meta property="og:url" content={metadata.url} />
      <meta
        property="og:image"
        content={
          metadata.image ||
          'https://cdn.shopify.com/s/files/1/0740/7144/1707/files/fblogo.jpg?v=1696353663'
        }
      />
      <meta property="og:description" content={metadata.description} />
      <meta property="og:site_name" content="JOUCOS" />
      <meta property="og:type" content="website" />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:image:width" content={metadata.imageWidth || '1200'} />
      <meta
        property="og:image:height"
        content={metadata.imageHeight || '630'}
      />
      {/* <meta content="1500" property="og:image:width"></meta> */}
      {/* <meta content="2100" property="og:image:height"></meta> */}
      {/* <meta content="Embroidered scarf jacket chocolate melange" property="og:image:alt"></meta> */}
      <meta content="TWD" property="product:price:currency" />
      {/* <meta
        name="msapplication-TileImage"
        content="/on/demandware.static/Sites-acne_other_apac-Site/-/default/dw2a3f64cd/favicon/ms-icon-144x144.png"
      />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="theme-color" content="#ffffff" />

      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="black" />
      <meta name="apple-mobile-web-app-title" content="旅遊日記" />
      <link
        rel="apple-touch-icon"
        href="/src/images/icons/apple-icon-57x57.png"
      />
      <link
        rel="apple-touch-icon"
        href="/src/images/icons/apple-icon-60x60.png"
      />
      <link
        rel="apple-touch-icon"
        href="/src/images/icons/apple-icon-72x72.png"
      />
      <link
        rel="apple-touch-icon"
        href="/src/images/icons/apple-icon-76x76.png"
      />
      <link
        rel="apple-touch-icon"
        href="/src/images/icons/apple-icon-114x114.png"
      />
      <link
        rel="apple-touch-icon"
        href="/src/images/icons/apple-icon-120x120.png"
      />
      <link
        rel="apple-touch-icon"
        href="/src/images/icons/apple-icon-144x144.png"
      />
      <link
        rel="apple-touch-icon"
        href="/src/images/icons/apple-icon-152x152.png"
      />
      <link
        rel="apple-touch-icon"
        href="/src/images/icons/apple-icon-180x180.png"
      /> */}
      {/* <link
        rel="stylesheet"
        type="text/css"
        href="../fonts/MyFontsWebfontsKit.css"
      /> */}
      <script src="https://cdn.shopify.com/s/javascripts/currencies.js" />
    </Helmet>
  );
}
