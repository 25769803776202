import React, { useContext, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Link, navigate, withPrefix } from 'gatsby';
import { Trans } from 'react-i18next';
// import Client from 'shopify-buy';
// import { StaticImage } from 'gatsby-plugin-image';
import update from 'immutability-helper';
import RootContext from './RootContext';
// import {
//   // useHeaderMenuQuery,
//   useOnlineShopMenuQuery,
// } from '../utils/useMenuQuery';
import shopifyCountry from '../constants/shopify_countries.json';
import { CartLinesUpdateMutation } from '../constants/cartQuery';
import { ProductsWithFilterQuery } from '../constants/productQuery';
import { AllCollectionsQuery } from '../constants/collectionsQuery';
import useStoreFrontQueryData from '../utils/useStoreFrontQueryData';
import { countryCompare } from '../constants/country';
import { getOptionsColor } from '../helpers/itemHelper';
import { glStoreFrontFetch } from '../apis-browser';
import { mutationFormat } from '../helpers/queryFormat';
import { currencyCode, dollarFloat } from '../helpers/currency';
import { langPrefix } from '../helpers/urlParser';
import Logo from './Logo';
import TransAni from './TransAni';
import Input from './Input';
import Item from './Item';
import Loading from './Loading';
import { isBrowser } from '../helpers/browser';
import useCounter from '../utils/useCounter';
import { timeCountdown } from '../helpers/timeFormat';

const Nav = styled.nav`
  position: ${(props) => props.type || 'fixed'};
  top: 0;
  left: 0;
  width: 100%;
  /* height: 56px; */
  height: 113px;
  /* background: var(--white); */
  /* border-bottom: 1px solid #999; */
  z-index: 12;
  /* padding: 0px 50px; */
  padding-right: 0px;
  display: flex;
  align-items: center;
  /* transform: ${(props) =>
    props.type === 'sticky' ? 'translateY(113px)' : 0}; */
    /* transform: translateY(0%); */
    transition: top 0.3s ease, z-index 0.3s step-end;
    will-change: top;
    /* background: transparent; */
  &.set-zindex-active {
    transition: top 0.3s ease, z-index 0s step-start;
    z-index: 15;
  }
  .left {
    z-index: 2;
    padding-left: 50px;
    width: 50%;
    /* width: 30%; */
    display: flex;
    align-items: center;
    /* max-width: 370px; */
    /* padding: 50px; */
    /* display: grid; */
    /* height: auto; */
    transform: translate(0px, 0px);
    * {
      font-size: 13px;
    }
    .items {
      flex: 1;
    }
    @media screen and (max-width: 1020px) {
      padding-left: 0px;
    }
    .flex-full {
      width: 50%;
      /* width: 60%; */
      align-self: flex-start;
      margin-top: 47px;
      padding: 0px 25px;
      padding-right: 50px;
      /* @media screen and (max-width: 1344px) {
      max-width: 240px;
    } */
    /* transition: margin-top 0.3s ease;
    will-change: margin-top; */
    transform: translate(0px, 0px);
    transition: top 0.3s ease;
    will-change: top;
      @media screen and (max-width: 1344px) {
        /* flex: 1; */
        /* width: 70%; */
        top: 0px;
        position: fixed;
        /* width: 100%; */
        max-width: 320px;
        left: auto;
        right: 0%;
        /* transform: translateX(-50%); */
        /* transform: translateX(-100%); */
        /* max-width: 240px; */
      }
      @media screen and (max-width: 1020px) {
        /* flex: 1; */
        /* width: 70%; */
        /* top: 0px;
        position: fixed;
        width: 100%;
        max-width: 320px;
        left: 50%; */
        /* transform: translateX(-50%); */
        /* transform: translateX(-50%); */
        /* max-width: 240px; */
      }
      /* @media screen and (max-width: 1210px) {
        max-width: 160px;
      }
      @media screen and (max-width: 1210px) {
        max-width: 140px;
      } */
      /* @media screen and (max-width: 1020px) {
        /* flex: 1; */
        /* width: 70%; */
        /* max-width: 240px; */
      } */
      .logo {
        max-width: 320px;
      }
    }

    .nav-item {
      margin-right: 50px;
      /* @media screen and (max-width: 1280px) {
        margin-right: 25px;
      } */
      
      @media screen and (max-width: 1544px) {
        /* flex: 1; */
        margin-right: 25px;
      }
      @media screen and (max-width: 1020px) {
        margin-right: 25px;
      }
    }
    @media screen and (max-width: 1020px) {
      padding-left: 25px;
    }
  }
  .middle {
    /* width: 20%; */
    .flex-full {
      /* width: 100%; */
      width: 50%;
      align-self: flex-start;
      margin-top: 52px;
      padding: 0px 25px;
    }

    @media screen and (max-width: 1090px) {
      /* flex: 1; */
      width: 30%;
      .flex-full {
        /* width: 100%;
        align-self: flex-start;
        margin-top: 52px; */
        padding: 0px 12.5px;
      }
    }
  }
  .right {
    z-index: 2;
    /* padding: 50px; */
    /* display: grid; */
    /* height: auto; */
    display: flex;
    /* width: 50%; */
    flex: 1;
    align-items: center;
    justify-content: flex-end;
    padding-right: 50px;
    transform: translate(0px, 0px);
    * {
      font-size: 13px;
    }

    .nav-item {
      margin-left: 50px;
      
      @media screen and (max-width: 1544px) {
        /* flex: 1; */
        margin-left: 25px;
      }
      @media screen and (max-width: 1020px) {
        margin-left: 0px;
      }
      /* @media screen and (max-width: 1280px) {
        margin-left: 25px;
      } */
    }
    @media screen and (max-width: 1020px) {
      padding-right: 25px;
    }
  }
  .nav-button {
    width: 50%;
    /* width: 40%; */
    display: inline-block;
    
    @media screen and (max-width: 1344px) {
      width: 100%;
    }
  }
  .nav-item {
    /* margin-right: 50px; */
    display: inline-block;
    &:last-child {
      margin-right: 0px;
    }
    .nav-active {

    }
    @media screen and (max-width: 1280px) {
      /* margin-right: 25px; */
    }
  }

  .logo {
    width: 100%;
    /* width: 120%;
    margin-left: -20%; */
    /* max-width: 237px; */

    @media screen and (max-width: 1090px) {
      /* width: 173px; */
    }
  }
  /* .logo {
    flex: 1;
  } */
  p {
    /* float: left; */
    display: inline-block;
    /* margin-right: 32px; */
  }
  .white-show-online {
    color: white !important;
    &::after {
      background: white;
    }
    .trans-text::after {
      background: white;
    }
  }
  .cartModal {
    position: fixed;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(0px);
    background: rgba(255, 255, 255, 0.3);
    left: -1000000px;
    top: -20000000px;
    transition: left 0s 0.15s ease, top 0s 0.15s ease,
      backdrop-filter 0s 0.16s ease;
    .left-panel {
      /* max-width: 720px; */
      width: 50%;
      position: absolute;
      height: 100%;
      background: var(--mainColor);
      top: 0;
      right: 0;
      transform: translateX(100%);
      /* transition: transform 0.3s ease; */
      transition: transform 0.15s ease;
      padding: 50px;
      border-left: 1px solid black;
      &:hover {
        .scroll-bar {
          opacity: 1;
        }
      }
      .scroll-bar {
        display: block;
        position: absolute;
        right: 25px;
        top: 0;
        width: 1px;
        height: 75px;
        background: black;
        opacity: 0;
        transition: opacity 0.3s ease;
        &.lock {
          opacity: 0 !important;
        }
      }
      .detail-container {
        padding-top: 130px;
        padding-left: 50px;
        position: relative;
        height: 100%;
        /* overflow: scroll; */
        overflow: visible;
        .subtotal {
          width: 100%;
          margin-bottom: 30px;
          display: flex;
          .title {
            flex: 1;
          }
          p {
            font-size: 13px;
          }
          &.align-end {
            align-items: flex-end;
          }
          &:last-child {
            margin-bottom: 0px;
          }
        }
        .currency {
          padding: 0px 10px;
          p {
            font-size: 13px;
          }
        }
        .total {
          width: 100px;
          text-align: right;
          p {
            font-size: 13px;
          }
        }
        .sep-1 {
          margin-top: 50px;
          width: 100%;
          height: 1px;
          background: black;
          /* margin-bottom: 25px; */
        }

        .cloth-detail {
          position: relative;
          /* height: calc(100% - 300px); */
          overflow: scroll;
          height: calc(100% - 175px);
          padding-top: 25px;
          padding-right: 10px;
          margin-right: -30px;
          padding-right: 30px;

          /* padding-right: 30px; */
          padding-top: 0px;
          /* margin-top: 25px; */
          /* margin- */
          &::-webkit-scrollbar {
            display: none;
          }

          .item-detail {
            /* display: flex; */
            /* display: relative; */
            position: relative;
            padding: 25px 0px;
            text-align: right;
            /* margin-bottom: 25px; */
            &:after {
              content: '';
              height: 1px;
              max-width: 400px;
              width: 100%;
              background: black;
              position: absolute;

              bottom: 0;
              right: 0;
              /* @media screen and (max-width: 1020px) {
                width: 390px;
              }
              @media screen and (max-width: 675px) {
                width: 100%;
              } */
            }
            /* border-bottom: 1px solid black; */
            img {
              /* width: 150px; */
              width: auto;
              height: 140px;
              /* padding-right: 50px; */
              display: inline-block;
              vertical-align: top;
              margin-right: 50px;
              /* border-bottom: 1px solid black; */
              /* padding-bottom: 25px; */
              @media screen and (max-width: 1110px) {
                margin-right: 25px;
              }
            }
            .item-detail-content {
              /* display: flex; */
              width: 240px;
              height: 140px;
              position: relative;
              display: inline-block;
              vertical-align: top;
              /* border-bottom: 1px solid black; */
              /* padding-bottom: 25px; */
              > div {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                text-align: left;
                height: 140px;
                .title {
                  font-size: 13px;
                  line-height: 2em;
                }
                .price {
                  font-size: 9px;
                }
                .line {
                  width: 100%;
                  position: relative;
                  display: flex;
                  align-items: center;
                  .label {
                    width: 80px;
                    display: inline-block;
                    p {
                      font-size: 13px;
                    }
                  }
                  .content {
                    flex: 1;
                    align-items: center;
                    justify-content: space-between;
                    display: flex;
                    .count {
                      display: flex;
                      align-items: center;
                      span:first-child {
                        float: left;
                        padding-right: 10px;
                      }
                      span:last-child {
                        float: left;
                        padding-left: 10px;
                      }
                    }
                    p {
                      font-size: 13px;
                      float: left;
                    }
                    .remove {
                      float: right;
                      p {
                        color: rgba(0, 0, 0, 0.4);
                        font-size: 11px;
                        text-decoration-line: underline;
                      }
                    }
                  }
                }
              }
            }
            /* .sep-item {
              width: 390%;

            } */
          }
        }
        @media screen and (max-width: 1110px) {
          padding-left: 25px;
        }
      }
      .checkout-bar {
        width: 390px;
        height: 80px;
        background: #f5f2ec;
        position: absolute;
        bottom: 0px;
        right: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .button {
          width: 240px;
          height: 30px;
          border-radius: 2px;
          border: 1px solid #000;
          text-align: center;
          padding: 6px 0px 8px 0px;
          font-size: 11px;
          a {
            text-decoration: none;
          }
        }
      }
      @media screen and (max-width: 1344px) {
        padding-top: 125px;
        /* max-width: px; */
      }
      @media screen and (max-width: 1020px) {
        width: 75%;
      }
      @media screen and (max-width: 900px) {
        width: 100%;
        .detail-container {
          .item-detail {
            .item-detail-content {
              > div {
                .price {
                  font-size: 11px;
                }
              }
            }
          }
        }
      }
      /* @media screen and (max-width: 768px) {
        width: 100%;
      } */
    }
    &.active {
      left: 0px;
      top: 0px;
      backdrop-filter: blur(20px);
      transition: left 0s 0s ease, top 0s 0s ease, backdrop-filter 0.15s ease;
      z-index: 13;
      .left-panel {
        transform: translateX(0%);
        transition: transform 0.3s ease;
      }
      
    }
    /* @media screen and (max-width: 900px) {
      .left-panel {
        padding: 16px 20px 0px 20px;
      }
    } */
    @media screen and (max-width: 900px) {
      width: 100%;
      z-index: 3;
      /* transform: translateY(100%); */
      .left-panel {
        transform: translateY(100%);
        padding: 16px 20px 0px 20px;
        .detail-container {
          padding: 2vh 0px 0px;
          .cloth-detail {
              
            .item-detail {
              text-align: left;
              &:after {
                max-width: 100%;
              }
              &:last-child {
                &:after {
                  display: none;
                }
              }
              .item-detail-content {
                width: calc(100% - 138px);
              }
            }
          }
          
        }
        .scroll-bar {
            display:none;
          }

        .checkout-bar {
          width: 100%;
          right: 0px;
          height: 40px;
          .continue-shop {
            display: none;
          }
          .checkout-button {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            
            > div {
              position: absolute;
              width: 100%;
              height: 100%;
              background: black;
              padding-top: 10px;
              .GSText {
                color: white !important;
              }
            }
            .button {
              border-radius: 0px;
            }
          }
        }
        .sep-1 {
          display: none;
        }
        
        .price-info {
          position: fixed;
          bottom: 40px;
          width: 100%;
          left: 0px;
          padding: 20px;
          border-top: 1px solid black;
          .subtotal {
            margin-bottom: 15px;
            align-items: center;
            .title {
              .s-15 {
                font-size: 13px !important;
              }
              .shipping-desc {
                font-size: 11px !important;
              }
            }
            .currency, .total {
              padding-top: 7px;
            }
          }
        }
      }
      &.active {
        .left-panel {
          transform: translateY(0%);
        }
      }
    }
    @media screen and (max-width: 490px) {
      width: 100%;
      /* transform: translateY(100%); */
      .left-panel {
        /* transform: translateY(100%); */
        /* padding: 12vh 25px 25px 25px; */
        .detail-container {
          .total {
            width: 80px;
          }
          .cloth-detail {
            .item-detail {
              .item-detail-content {
                > div {
                  .line {
                    .label {
                      width: 60px;
                    }
                  }
                }
                /* width: calc(100% - 138px); */
              }
            }
          }
        }
      }
      &.active {
        .left-panel {
          transform: translateY(0%);
        }
      }
    }
  }
  .sub-online-shop-900 {
    transition: transform 0.3s ease, opacity 0.3s ease;
    /* padding: 0 200px; */
    /* padding: 50px 25px 30px 25px; */
    padding: 0px 20px 0px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    max-height: 0px;
    transition: transform 0s ease, opacity 0s ease, padding 0.3s ease, max-height 0.3s ease;
    > a {
      opacity: 0;
      transform: translateY(25px);
      transition: opacity 0.3s ease, transform 0s 0.3s ease;
    }
    &.sub-online-shop-900-active {
      transition: transform 0.15s ease, opacity 0s ease, padding 0.3s ease, max-height 1s ease;
      opacity: 1;
      transform: translate3d(0, 0%, 0);
      padding: 50px 20px 30px 20px;
      max-height: 1200px;
      > a {
        opacity: 1;
        transform: translateY(0px);
        transition: opacity 0.3s 0.3s ease, transform 0.3s 0.3s ease;
        /* transition: opacity 0.3s 0.3s ease, transform 0.3s 0.3s ease; */
        &:nth-child(2) {
          transition: opacity 0.3s 0.4s ease, transform 0.3s 0.4s ease;
          /* transition: opacity 0.3s 0.35s ease, transform 0.3s 0.35s ease; */
        }
        &:nth-child(3) {
          transition: opacity 0.3s 0.5s ease, transform 0.3s 0.5s ease;
          /* transition: opacity 0.3s 0.4s ease, transform 0.3s 0.4s ease; */
        }
        &:nth-child(4) {
          transition: opacity 0.3s 0.6s ease, transform 0.3s 0.6s ease;
          /* transition: opacity 0.3s 0.45s ease, transform 0.3s 0.45s ease; */
        }
        &:nth-child(5) {
          transition: opacity 0.3s 0.7s ease, transform 0.3s 0.7s ease;
          /* transition: opacity 0.3s 0.5s ease, transform 0.3s 0.5s ease; */
        }
        &:nth-child(6) {
          transition: opacity 0.3s 0.8s ease, transform 0.3s 0.8s ease;
          /* transition: opacity 0.3s 0.55s ease, transform 0.3s 0.55s ease; */
        }
        &:nth-child(7) {
          transition: opacity 0.3s 0.9s ease, transform 0.3s 0.9s ease;
          /* transition: opacity 0.3s 0.6s ease, transform 0.3s 0.6s ease; */
        }
        &:nth-child(8) {
          transition: opacity 0.3s 1s ease, transform 0.3s 1s ease;
          /* transition: opacity 0.3s 0.65s ease, transform 0.3s 0.65s ease; */
        }
        &:nth-child(9) {
          transition: opacity 0.3s 1.1s ease, transform 0.3s 1.1s ease;
          /* transition: opacity 0.3s 0.7s ease, transform 0.3s 0.7s ease; */
        }
        &:nth-child(10) {
          transition: opacity 0.3s 1.2s ease, transform 0.3s 1.2s ease;
          /* transition: opacity 0.3s 1.2s ease, transform 0.3s 1.2s ease; */
        }
        &:nth-child(11) {
          transition: opacity 0.3s 1.3s ease, transform 0.3s 1.3s ease;
          /* transition: opacity 0.3s 0.75s ease, transform 0.3s 0.75s ease; */
        }
        &:nth-child(12) {
          transition: opacity 0.3s 1.4s ease, transform 0.3s 1.4s ease;
          /* transition: opacity 0.3s 0.8s ease, transform 0.3s 0.8s ease; */
        }
        &:nth-child(13) {
          transition: opacity 0.3s 1.5s ease, transform 0.3s 1.5s ease;
          /* transition: opacity 0.3s 0.85s ease, transform 0.3s 0.85s ease; */
        }
        &:nth-child(15) {
          transition: opacity 0.3s 1.6s ease, transform 0.3s 1.6s ease;
          /* transition: opacity 0.3s 0.9s ease, transform 0.3s 0.9s ease; */
        }
      }
    }
  }
  .online-shop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    transform: translate3d(0, -100%, 0);
    background: var(--redColor);
    transition: transform 0.3s ease, opacity 0.3s ease;
    padding: 0 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    > a {
      opacity: 0;
      transform: translateY(25px);
      transition: opacity 0.3s ease, transform 0s 0.3s ease;
    }
    &.online-shop-active {
      transition: transform 0.3s ease, opacity 0s ease;
      opacity: 1;
      transform: translate3d(0, 0%, 0);
      > a {
        opacity: 1;
        transform: translateY(0px);
        transition: opacity 0.3s 0.3s ease, transform 0.3s 0.3s ease;
        /* transition: opacity 0.3s 0.3s ease, transform 0.3s 0.3s ease; */
        &:nth-child(2) {
          transition: opacity 0.3s 0.4s ease, transform 0.3s 0.4s ease;
          /* transition: opacity 0.3s 0.35s ease, transform 0.3s 0.35s ease; */
        }
        &:nth-child(3) {
          transition: opacity 0.3s 0.5s ease, transform 0.3s 0.5s ease;
          /* transition: opacity 0.3s 0.4s ease, transform 0.3s 0.4s ease; */
        }
        &:nth-child(4) {
          transition: opacity 0.3s 0.6s ease, transform 0.3s 0.6s ease;
          /* transition: opacity 0.3s 0.45s ease, transform 0.3s 0.45s ease; */
        }
        &:nth-child(5) {
          transition: opacity 0.3s 0.7s ease, transform 0.3s 0.7s ease;
          /* transition: opacity 0.3s 0.5s ease, transform 0.3s 0.5s ease; */
        }
        &:nth-child(6) {
          transition: opacity 0.3s 0.8s ease, transform 0.3s 0.8s ease;
          /* transition: opacity 0.3s 0.55s ease, transform 0.3s 0.55s ease; */
        }
        &:nth-child(7) {
          transition: opacity 0.3s 0.9s ease, transform 0.3s 0.9s ease;
          /* transition: opacity 0.3s 0.6s ease, transform 0.3s 0.6s ease; */
        }
        &:nth-child(8) {
          transition: opacity 0.3s 1s ease, transform 0.3s 1s ease;
          /* transition: opacity 0.3s 0.65s ease, transform 0.3s 0.65s ease; */
        }
        &:nth-child(9) {
          transition: opacity 0.3s 1.1s ease, transform 0.3s 1.1s ease;
          /* transition: opacity 0.3s 0.7s ease, transform 0.3s 0.7s ease; */
        }
        &:nth-child(10) {
          transition: opacity 0.3s 1.2s ease, transform 0.3s 1.2s ease;
          /* transition: opacity 0.3s 1.2s ease, transform 0.3s 1.2s ease; */
        }
        &:nth-child(11) {
          transition: opacity 0.3s 1.3s ease, transform 0.3s 1.3s ease;
          /* transition: opacity 0.3s 0.75s ease, transform 0.3s 0.75s ease; */
        }
        &:nth-child(12) {
          transition: opacity 0.3s 1.4s ease, transform 0.3s 1.4s ease;
          /* transition: opacity 0.3s 0.8s ease, transform 0.3s 0.8s ease; */
        }
        &:nth-child(13) {
          transition: opacity 0.3s 1.5s ease, transform 0.3s 1.5s ease;
          /* transition: opacity 0.3s 0.85s ease, transform 0.3s 0.85s ease; */
        }
        &:nth-child(15) {
          transition: opacity 0.3s 1.6s ease, transform 0.3s 1.6s ease;
          /* transition: opacity 0.3s 0.9s ease, transform 0.3s 0.9s ease; */
        }
      }
    }
    @media screen and (max-width: 1020px) {
      padding: 15vh 100px;
    }

  }
  .search {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    opacity: 0;
    /* opacity: 1; */
    transform: translate3d(0, -100%, 0);
    /* transform: translate3d(0, 0%, 0); */
    /* background: var(--redColor); */
    background: rgba(245, 242, 236, 0.80);
    backdrop-filter: blur(20px);
    transition: transform 0.3s ease, opacity 0.3s ease;
    
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: center; */
    &.search-active {
      transition: transform 0.3s ease, opacity 0s ease;
      opacity: 1;
      transform: translate3d(0, 0%, 0);
      .left-container {
        opacity: 1;
        transform: translate3d(0, 0%, 0);
        transition: opacity 0.6s 0.3s ease, transform 0.6s 0.3s ease;
        pointer-events: auto;
      }
      .right-container {
        opacity: 1;
        transform: translate3d(0, 0%, 0);
        transition: opacity 0.6s 0.3s ease, transform 0.6s 0.3s ease;
        pointer-events: auto;
      }
    }
    .nav-header {
        width: calc(100% - 310px);
        /* width: 100%; */
        left: 0px;
        top: 0px;
        padding: 50px;
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 2;
        .controllor-container {
          display: flex;
          align-items: center;
          &:last-child {
            margin-right: -5px;
          }
          p {
            font-size: 13px;
            /* margin-right: 10px; */
            padding: 0px 5px;
            position: relative;
            /* &:first-child {
              padding-right: 10px;
            } */
            /* &:last-child {
              padding-right: 0px;
            } */
            &:hover {
              span {
                opacity: 1;
              }
            }
            &.selected {
              span {
                opacity: 1;
              }
            }
            span {
              transition: opacity 0.3s ease;
              opacity: 0;
              position: absolute;
              width: 8px;
              height: 1px;
              /* top: 10px; */
              display: inline-block;
              background: black;
              left: 50%;
              transform: translateX(-50%);
              bottom: -5px;
            }
          }
        }
      }
    .left-container {
      /* float: left; */
      position: absolute;
      /* width: calc(100% - 310px); */
      height: 100%;
      padding: 0px 50px;
      /* padding-bottom: 50px; */
      padding-top: 24vh;
      overflow: scroll;
      padding-right: 310px;
      width: 100%;
      opacity: 0;
      transform: translate3d(0, 50px, 0);
      transition: transform 0.3s ease, opacity 0.3s ease;
      pointer-events: none;
      &::-webkit-scrollbar {
        display: none;
      }
      
      .items {
        width: 100%;
        position: relative;
        /* margin-bottom: 50px; */
        /* display: flex;
        flex-direction: row;
        flex-wrap: wrap; */
        .item {
          float: left;
          width: calc((100% - 20px) / 3);
          /* height: 100px; */
          /* background: red; */
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.4;
          transition: opacity 0.3s ease;
          cursor: pointer;
          &:hover {
            opacity: 1;
          }
        }
        &[data-column="3"] {
          .item {
            &:nth-child(3n) {
              margin-right: 0px;
            }
          }
        }
        &[data-column="4"] {
          .item {
            width: calc((100% - 30px) / 4);
            &:nth-child(4n) {
              margin-right: 0px;
            }
          }
        }
        &[data-column="5"] {
          .item {
            width: calc((100% - 40px) / 5); 
            &:nth-child(5n) {
              margin-right: 0px;
            }
          }
        }
      }
    }
    .right-container {
      opacity: 0;
      /* float: left; */
      /* position: relative; */
      position: absolute;
      width: 310px;
      /* height: 100%; */
      right: 0px;
      /* background: red; */
      padding: 50px;
      padding-right: 70px;
      transform: translate3d(0, 50px, 0);
      transition: transform 0.3s ease, opacity 0.3s ease;
      pointer-events: none;
      z-index: 2;
      .input-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        .icon-close {
          margin-bottom: 5px;
          margin-right: -20px;
          /* position: absolute; */
          /* right: 50px; */
          /* top: 50px; */
          /* z-index: 2; */
        }
      }
      .block-container {
        margin-top: 20px;
        width: 100%;
        text-align: right;
        .input-history {
          cursor: pointer;
          display: inline-block;
          padding: 5px;
          margin-left: 10px;
          margin-top: 10px;
          /* padding-right: 5px; */
          /* display: flex; */
          display: inline-block;
          flex-direction: row;
          align-items: center;
          border-bottom: 1px solid #000;
          .icon-close {
            margin-left: 10px;
            font-size: 12px;
            cursor: pointer;
          }
        }
      }
      .filter {
        top: 24vh;
        width: 100%;
        position: absolute;
        left: 0px;
        padding: 0px 50px;
        padding-right: 70px;
        /* pointer-events: none; */
        /* flex-direction: row; */
        /* align-items: center; */
        .label {
          width: 100%;
          position: relative;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          .icon-filter {
            font-size: 10px;
            margin-right: 10px;
          }
        }
        .content {
          position: relative;
        }
        .icon-plus {
          position: relative;
          font-size: 10px;
          
        }
      }
      /* .input-container {
        padding-right: ;
      } */
    }
  }
  &.nav-zoom-out {
    /* transform: translate3d(0, -100%, 0); */
    top: -114px;
    /* background: var(--mainColor); */
    .left {
      .flex-full {
        /* margin-top: 22px; */
        top: -114px;
      }
    }
  }
  
  .menu-900 {
    display: none;
    
  }
  .close-900 {
    display: none;
  }
  .show-menu-900 {
    position: fixed;
    right: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    transform: translateX(100%);
    transition: transform 0.3s ease;
    background: var(--redColor);
    padding: 12vh 20px 0px 20px;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    &.show-menu-900-active {
      transform: translateX(0%);
    }
    .icon-plus {
      transition: transform 0.3s ease;
      transform: rotate(0deg);
      /* background: green; */
      &.icon-plus-active {
        transform: rotate(45deg);
      }
    }
  }
  
  @media screen and (min-width: 1344px) {
    .left {
      * {
        font-size: 15px;
      }
    }
    .right {
      * {
        font-size: 15px;
      }
    }
  }
  @media screen and (max-width: 900px) {
    height: 52px;
    margin-bottom: -52px !important;
    padding-left: 0px;
    .add-item-loading {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
  }
    .left {
      display: none;
    }
    .right {
      display: none;
    }
    
    .search {
      overflow: scroll;
      background: var(--mainColor);
      .nav-header {
        width: 100%;
        padding: 20px;
        position: relative;
        padding-top: 0px;
        .controllor-container {
          display: none !important;
        }
      }
      .left-container {
        .items {
          .item {
            opacity: 1;
          }
        }
      }
      .right-container {
        position: relative;
        padding: 50px 20px 30px 20px;
        width: 100%;
        z-index: 3;
        .input-container {
          padding-right: 20px;
        }
      }
      .left-container {
        position: relative;
        width: 100%;
        padding: 20px;
        height: auto;
        .items {
          overflow: hidden;
        }
      }
    }
    .menu-900 {
      display: block;
      top: 0;
      left: 0;
      /* position: fixed; */
      position: relative;
      padding: 16px 20px;
      padding-left: 20px;
      width: 100%;
      z-index: 2;
      .logo-900 {
        position: relative;
        max-width: 108px;
        width: 100%;
        
      }
      .menu-info-900 {
        position: absolute;
        right: 10px;
        top: 16px;
        height: 20px;
        .icon-burger {
          font-size: 18px;
          /* margin-top: px; */
          color: black;
          transition: color 0.3s ease;
          display: inline-block;
          padding: 1px 10px;
        }
        .icon-cart {
          font-size: 20px;
          color: black;
          transition: color 0.3s ease;
          display: inline-block;
          /* margin-right: 20px; */
          padding: 0px 10px;
          /* margin-top: -1px; */
        }
        .cart-count-900 {
          font-size: 11px;
          position: absolute;
          left: 50%;
          transform: translateX(-50%) scale(0.8);
          bottom: 3px;
          color: black;
          transition: color 0.3s ease;
          pointer-events: none;
        }
        
      }
    }
    .close-900 {
      display: block;
      position: absolute;
      right: 25px;
      top: 18px;
    }
    .search-loading-900 {
      background: transparent !important;
      backdrop-filter: blur(0px);
    }
  }
  @media screen and (max-width: 768px) {
    
  }
`;

function getMarket(country) {
  return countryCompare[country];
}

export default function NavgationInfluencer(props) {
  const {
    setting,
    updateSetting,
    user,
    loginCheck,
    logout,
    cart,
    checkCart,
    updateCartLinesQuantity,
    modifyItemQuantity,
    removeCartItem,
    showCart,
    setShowCart,
    showOnlineShop,
    setShowOnlineShop,
    showSearch,
    setShowSearch,
    env,
    setPopupInfo = () => {},
    timestamp,
    vipTags,
    setVipTags,
    isVip,
    staticLocales,
    subOnlineShop,
    setSubOnlineShop,
    // style = {},
  } = useContext(RootContext);
  // const menu = useHeaderMenuQuery();
  // const onlineShopMenu = useOnlineShopMenuQuery();
  // console.log('==> onlineShopMenu', onlineShopMenu);
  const {
    color = 'black',
    type = 'fixed',
    style = {},
    cartActiveCallback = () => {},
    location,
    logoClassName = '',
    logoContainerClassName = '',
    hideCountdown = false,
    lockWheel = () => {},
    pageContext,
    setOnlineShopMenu = () => {},
    useJSON = false,
    navigationStyle = {},
  } = props;
  const [cartActive, setCartActive] = useState(false);
  const [grid, setGrid] = useState(3);
  const [searchText, setSearchText] = useState('');
  const [available, setAvailable] = useState(0); // 0全部, 1可以, 2 不可以
  const [filterPrice, setFilterPrice] = useState([]);
  const [searchItems, setSearchItems] = useState([]);
  const [recentlySearched, setRecentlySearched] = useState([]);
  // const [subOnlineShop, setSubOnlineShop] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [editItemsLoading, setEditItemsLoading] = useState(false);
  const [countdown, setCountdown] = useState('--:--:--');
  const [showMenu900, setShowMenu900] = useState(false);
  const [language, setLanguage] = useState(pageContext?.i18n?.language);
  const [country, setCountry] = useState(setting.country);
  const clothDetail = useRef(null);
  const searchTimer = useRef(null);
  // const { menu } = props.headerMenuData;
  // console.log('===> menu', menu, cart, color);
  const { fetchData: onlineShopMenu } = useStoreFrontQueryData({
    defaultData: {},
    query: AllCollectionsQuery,
    variables: {
      first: 240,
      // endCursor: null,
      // id: `gid://shopify/Collection/${collectionId}`,
      // 457814868267
      // pageData[env]?.metadata?.defaultCollectionId,
      // handle: props.id,
      // maxHeight: 1280,
      // maxWidth: 1280,
    },
    setting,
    pageContext: props.pageContext,
  });

  // console.log('===> onlineShopMenu', onlineShopMenu, timestamp);

  async function fetchItemsWithQuery(append = false) {
    // console.log('===> iiiiii1', fetchItem);
    if (!searchText) return;
    let afs = '';
    let fp = '';
    if (available !== 0) {
      afs = ` AND available_for_sale:${available === 1}`;
    }
    if (filterPrice && filterPrice.length > 1) {
      fp = ` AND variants.price:>=${filterPrice[0]} AND variants.price:<=${filterPrice[1]}`;
    }
    // let result;
    try {
      setPageLoading(true);
      const result = await glStoreFrontFetch({
        query: mutationFormat({
          query: ProductsWithFilterQuery,
          variables: {
            first: 24,
            // query: `(tag:${searchText} OR title:${searchText} OR product_type:"${searchText}")${afs}${fp}`,
            query: `(tag:${searchText} OR title:${searchText} OR product_type:"${searchText})${afs}${fp}`,
            // query: `(tag:${searchText})${afs}${fp}`,
            maxWidth: 768,
            country: setting.country.toUpperCase(),
            language: props.pageContext.language.toUpperCase(),
          },
        }),
        token: user.token?.accessToken,
      });

      if (result.errors) {
        setPageLoading(false);
        return;
      }
      setPageLoading(false);
      if (result?.products?.nodes?.length > 0) {
        if (append) {
          setSearchItems(
            update(searchItems, {
              $push: result?.products?.nodes,
            })
          );
        } else {
          setSearchItems(result?.products?.nodes);
        }
      }
    } catch (e) {
      setPageLoading(false);
    }
    // return result;
  }

  const metaobject =
    JSON.parse(onlineShopMenu?.metaobject?.field?.value || '{}')[
      setting.country
    ] || {};
  const { startedAt, endAt } = metaobject;
  useCounter({
    // second: 0.5,
    second: 1,
    callback: (setStopTimer) => {
      if (
        timestamp &&
        (timestamp > new Date(endAt).getTime() ||
          timestamp < new Date(startedAt).getTime())
      ) {
        setStopTimer(true);
        return;
      }
      // console.log('==> log', loginCheck, user?.token?.accessToken);
      // const currentTime = new Date().getTime();
      // console.log(
      //   '===> startedAt, endAt',
      //   startedAt,
      //   endAt,
      //   timestamp,
      //   new Date().getTime() < new Date(startedAt).getTime(),
      //   new Date().getTime() > new Date(endAt).getTime()
      // );
      if (
        new Date().getTime() < new Date(startedAt).getTime() ||
        new Date().getTime() > new Date(endAt).getTime()
      ) {
        // navigate('/', {
        //   replace: false,
        // });
        setStopTimer(true);
      } else {
        // setStopTimer(true);
        // console.log(
        //   '==> log campaignInfo.expiredAt',
        //   endAt,
        //   timeCountdown(new Date(endAt).getTime(), timestamp)
        // );
        setCountdown(timeCountdown(new Date(endAt).getTime(), timestamp));
      }
    },
    extra: {
      startedAt,
      endAt,
      timestamp,
    },
  });
  useEffect(() => {
    console.log(
      '==> loginCheck',
      loginCheck,
      user,
      setting.country,
      props.pageContext.language.toUpperCase()
    );
    if (loginCheck) {
      // console.log('==> ')
      checkCart(
        user,
        setting.country,
        props.pageContext.language.toUpperCase()
      );
    }
  }, [loginCheck]);

  useEffect(() => {
    setOnlineShopMenu(onlineShopMenu);
    if (onlineShopMenu?.vipTags?.field?.value) {
      const vtags = JSON.parse(onlineShopMenu?.vipTags?.field?.value);
      setVipTags(vtags);
    }
  }, [onlineShopMenu]);

  useEffect(() => {
    if (searchText) {
      searchTimer.current && clearTimeout(searchTimer.current);
      searchTimer.current = setTimeout(() => {
        fetchItemsWithQuery();
      }, 1000);
    } else {
      setSearchItems([]);
    }
  }, [searchText]);

  useEffect(() => {
    // if (cart.id) {
    //   console.log('====> checkout1', cart.id); // The retrieved checkout
    //   client.checkout.fetch(cart.id).then((checkout) => {
    //     console.log('====> checkout', checkout); // The retrieved checkout
    //   });
    // }
  }, [cart]);

  useEffect(() => {
    let scroll = function () {};
    if (isBrowser()) {
      if (showCart) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
      cartActiveCallback(showCart);
      console.log('==> clothDetail.current', clothDetail.current);
      scroll = function (ratio) {
        return (e) => {
          console.log(e.target.scrollTop, e.target.scrollTop * ratio);
          document.querySelector('.scroll-bar').style.transform = `translateY(${
            e.target.scrollTop * ratio
          }px)`;
        };
      };
      if (clothDetail.current && showCart) {
        const react = clothDetail.current.getBoundingClientRect();
        console.log(clothDetail.current.scrollHeight, react.height);
        if (clothDetail.current.scrollHeight <= react.height) {
          document.querySelector('.scroll-bar').classList.add('lock');
          return;
        }
        document.querySelector('.scroll-bar').classList.remove('lock');
        console.log(react, clothDetail.current.scrollHeight);
        const scrollLength = clothDetail.current.scrollHeight - react.height;
        const scrollHeight = react.height - 50 - 50;
        console.log('==> aaaaa', scrollLength, scrollHeight);
        document.querySelector('.scroll-bar').style.top = `${react.top + 25}px`;
        clothDetail.current.addEventListener(
          'scroll',
          scroll(scrollHeight / scrollLength)
        );
        // document.querySelector('.scroll-bar').style.opacity = 1;
      }
    }
    return () => {
      clothDetail.current &&
        clothDetail.current.removeEventListener('scroll', scroll);
    };
  }, [showCart]);

  // useEffect(() => {
  //   console.log('==> aaaaaaaaaaaa');
  //   if (showCart) {
  //     document.body.style.overflow = 'hidden';
  //   } else {
  //     document.body.style.overflow = 'auto';
  //   }
  //   cartActiveCallback(showCart);
  // }, [showCart]);
  useEffect(() => {
    if (isBrowser()) {
      console.log('==> aa');
      let recently = localStorage.getItem('RecentlySearched') || '[]';
      recently = JSON.parse(recently);
      setRecentlySearched(recently);
    }
    return () => {
      // alert('a');
      setCartActive(false);
    };
  }, []);

  async function onCartClick(e) {
    // // e.stopPropagation();
    // if (!cartActive) {
    //   document.body.style.overflow = 'hidden';
    // } else {
    //   document.body.style.overflow = 'auto';
    // }
    // cartActiveCallback(!cartActive);
    // setCartActive(!cartActive);
    if (!showCart) {
      // document.querySelector('#nav').style.zIndex = 15;
    } else {
      // document.querySelector('#nav').style.zIndex = 12;
    }
    setShowCart(!showCart);
    if (showOnlineShop) {
      setShowOnlineShop(false);
    }
    if (showSearch) {
      setShowSearch(false);
    }
  }
  async function onShowMenu900(e) {
    if (!showMenu900) {
      // document.querySelector('#nav').style.zIndex = 15;
    } else {
      setTimeout(() => {
        // document.querySelector('#nav').style.zIndex = 12;
      }, 300);
    }
    lockWheel(!showMenu900);
    setShowMenu900(!showMenu900);
  }
  async function onOnlineShopClick(e) {
    if (showCart) {
      setShowCart(false);
    }
    if (showSearch) {
      setShowSearch(false);
    }
    if (showMenu900) {
      lockWheel(false);
      setShowMenu900(false);
    }
    setShowOnlineShop(!showOnlineShop);
  }
  async function onSearchClick(e) {
    if (showCart) {
      setShowCart(false);
    }
    if (showOnlineShop) {
      setShowOnlineShop(false);
    }
    // if (showMenu900) {
    //   lockWheel(false);
    //   setShowMenu900(false);
    // }
    setShowSearch(!showSearch);
  }
  async function closeCartIfShow(e) {
    if (showCart) {
      setShowCart(false);
    }
    if (showSearch) {
      setShowSearch(false);
    }
    if (showOnlineShop) {
      setShowOnlineShop(false);
    }
    if (showMenu900) {
      lockWheel(false);
      setShowMenu900(false);
    }
  }
  async function closeIfShow(e) {
    if (showCart) {
      setShowCart(false);
    }
    if (showSearch) {
      setShowSearch(false);
    }
    if (showOnlineShop) {
      setShowOnlineShop(false);
    }
    if (showMenu900) {
      lockWheel(false);
      setShowMenu900(false);
    }
  }

  async function cartItemUpdate(lines) {
    if (cart.id) {
      const result = await glStoreFrontFetch({
        query: mutationFormat({
          query: CartLinesUpdateMutation,
          variables: {
            cartId: cart.id,
            lines,
          },
        }),
        // setFetchData: setFetchResult,
        // test: true,
      });
      if (result.errors) {
        return;
      }
      if (
        result.cartLinesUpdate?.userErrors &&
        result.cartLinesUpdate.userErrors.length > 0
      ) {
        console.log(result.cartLinesUpdate.userErrors);
        // const errors = errorsParser(
        //   result.cartLinesUpdate.customerUserErrors,
        //   'reset'
        // );
        // console.log(
        //   errorsParser(result.cartLinesUpdate.customerUserErrors, 'reset')
        // );

        // if (errors.includes('無效URL')) {
        // }
        return;
      }
      updateCartLinesQuantity(result.cartLinesUpdate);
      console.log({
        ...result.cartLinesUpdate.cart,
      });
    }
  }
  // console.log('===> cart', cart, cart?.lineItems?.nodes?.length);
  let cartCount = '';
  if (cart?.lineItems?.nodes?.length <= 1) {
    cartCount = cart?.lineItems?.nodes[0]?.quantity || '';
  } else {
    cartCount = cart?.lineItems?.nodes?.reduce((prev, next) => {
      // console.log('===> ppp', typeof prev);
      if (typeof prev === 'number') {
        return prev + next.quantity;
      }
      return prev.quantity + next.quantity;
    });
  }
  // console.log(
  //   '==> cartCount',
  //   cartCount,
  //   color,
  //   endAt,
  //   new Date(endAt).getTime(),
  //   new Date().getTime()
  // );
  // console.log(showMenu900)
  // console.log('===> llllloooooo', onlineShopMenu?.collections?.nodes
  // ?.filter((item) => {
  //   // let json = {};
  //   const isProd = JSON.parse(item.isProd.value || 'false');
  //   // try {
  //   //   json = JSON.parse(item.descriptionHtml);
  //   // } catch (e) {}
  //   // if (!json.prod && env === 'prod') {
  //   //   return false;
  //   // }
  //   console.log('===> isProd', item.title, isProd);
  //   // if (isProd) {
  //   //   return true;
  //   // }
  //   return isProd;
  //   // return true;
  // }))
  return (
    <Nav
      id="nav"
      type={type}
      className={`${
        showCart || showMenu900 || showSearch ? 'set-zindex-active' : ''
      }`}
      style={{ ...style, ...navigationStyle }}
    >
      {!hideCountdown &&
        onlineShopMenu?.metaobject?.field?.value &&
        timestamp >= new Date(startedAt).getTime() &&
        timestamp < new Date(endAt).getTime() && (
          <div
            className={`special-event text-center ${
              showMenu900 ? 'active' : ''
            }`}
          >
            <p className="s-11 GSText white">
              {
                JSON.parse(onlineShopMenu.metaobject.field.value || '{}')[
                  setting.country
                ]?.desc
              }
              ：{'  '}
              {countdown}
            </p>
          </div>
        )}
      <div className="menu-900">
        <Link
          to={`${langPrefix(props.pageContext, location)}/`}
          // to="/"
          onClick={() => {
            // document.body.style.overflow = 'scroll';
            closeIfShow();
          }}
        >
          <Logo
            className="logo-900"
            color={showOnlineShop || showMenu900 ? 'white' : color}
            // style={{
            //   width: '100%',
            //   maxWidth: '237px',
            // }}
          />
        </Link>
        <div className="menu-info-900">
          <div className="inline-block relative">
            <i
              className={`icon-cart ${showMenu900 ? 'white' : color} ${
                showCart && 'black'
              } pointer`}
              onClick={onCartClick}
            />
            <span
              className={`GSText cart-count-900 ${
                showMenu900 ? 'white' : color
              } ${showCart && 'black'}`}
            >
              {cartCount}
            </span>
          </div>

          <i
            className={`icon-burger ${showMenu900 ? 'white' : color} ${
              showCart && 'black'
            } pointer`}
            onClick={onShowMenu900}
          />
        </div>
      </div>
      <div className="half left">
        <div className="nav-button">
          {/* <div className="nav-item">
            <Link
              to={`${langPrefix(props.pageContext, location)}/collections`}
              // to="/collections"
              activeClassName="nav-active"
              onClick={closeCartIfShow}
            >
              <TransAni
                useJSON={useJSON}
                containerClass={`${color} ${
                  props.uri === 'collections' &&
                  !showOnlineShop &&
                  !showSearch &&
                  !showCart &&
                  'nav-active'
                } ${showOnlineShop && 'white-show-online'} pointer`}
                textClass={`SAText color-ani ${color} ${
                  showOnlineShop && 'white-show-online'
                }`}
                pageContext={props.pageContext}
                i18nStyle={{
                  zh: {
                    className: 's-11-nav',
                    style: {
                      fontWeight: '500',
                    },
                  },
                }}
              >
                COLLECTION
              </TransAni>
            </Link>
          </div>
          <div className="nav-item">
            <TransAni
              useJSON={useJSON}
              containerClass={`${color} ${
                props.uri === 'shop' && 'nav-active'
              } ${showOnlineShop && 'nav-active'} ${
                showOnlineShop && 'white-show-online'
              } pointer`}
              textClass={`SAText color-ani ${color} ${
                showOnlineShop && 'white-show-online'
              }`}
              pageContext={props.pageContext}
              onClick={onOnlineShopClick}
              i18nStyle={{
                zh: {
                  className: 's-11-nav',
                  style: {
                    fontWeight: '500',
                  },
                },
              }}
            >
              ONLINE STORE
            </TransAni>
          </div> */}
        </div>
        <div className={`flex-full ${logoContainerClassName}`}>
          {/* <img src="" /> */}
          <Link
            to={`${langPrefix(props.pageContext, location)}/`}
            // to="/"
            onClick={() => {
              // document.body.style.overflow = 'scroll';
              closeIfShow();
            }}
          >
            <Logo
              className="logo pointer"
              color={showOnlineShop ? 'white' : color}
              // style={{
              //   width: '100%',
              //   maxWidth: '237px',
              // }}
            />
          </Link>
        </div>
      </div>
      <div className="half right">
        {/* <div className="nav-item">
          <TransAni
            useJSON={useJSON}
            containerClass={`${color} ${showSearch && 'nav-active'} ${
              showCart && 'black'
            } ${showOnlineShop && 'white-show-online'} pointer`}
            textClass={`SAText color-ani ${color} ${showCart && 'black'} ${
              showOnlineShop && 'white-show-online'
            }`}
            pageContext={props.pageContext}
            i18nStyle={{
              zh: {
                className: 's-11-nav',
                style: {
                  fontWeight: '500',
                },
              },
            }}
            onClick={(e) => {
              // setTimeout(() => {
              onSearchClick(e);
              // }, 1000);
            }}
          >
            SEARCH
          </TransAni>
        </div> */}
        <div
          className="nav-item"
          // onClick={() => {
          //   if (user?.token?.accessToken) {
          //     navigate(`${langPrefix(props.pageContext, location)}/account`, {
          //       replace: true,
          //     });
          //   } else {

          //   }
          // }}
        >
          <p
            className={`SAText color-ani ${color} ${showCart && 'black'} ${
              showOnlineShop && 'white-show-online'
            }`}
          >
            Hi cos55555
          </p>
          {/* <Link
            // to="/account"
            to={`${
              user?.token?.accessToken
                ? `${langPrefix(props.pageContext, location)}/account`
                : `${langPrefix(props.pageContext, location)}/account/login`
            }`}
            onClick={closeCartIfShow}
          >
            <TransAni
              useJSON={useJSON}
              containerClass={`${color} ${
                props.uri === 'account' &&
                !showOnlineShop &&
                !showSearch &&
                !showCart &&
                'nav-active'
              } ${showCart && 'black'} ${
                showOnlineShop && 'white-show-online'
              } pointer`}
              // containerClass={`${color} ${showCart && 'black'}`}
              textClass={`SAText color-ani ${color} ${showCart && 'black'} ${
                showOnlineShop && 'white-show-online'
              }`}
              pageContext={props.pageContext}
              i18nStyle={{
                zh: {
                  className: 's-11-nav',
                  style: {
                    fontWeight: '500',
                  },
                },
              }}
            >
              ACCOUNT
            </TransAni>
          </Link> */}
        </div>
        <div className="nav-item" onClick={onCartClick}>
          <TransAni
            useJSON={useJSON}
            // containerClass={`${color} ${showCart && 'black'}`}
            containerClass={`${color} ${showCart && 'nav-active'} ${
              showCart && 'black'
            } ${showOnlineShop && 'white-show-online'} pointer`}
            textClass={`SAText color-ani ${color} ${showCart && 'black'} ${
              showOnlineShop && 'white-show-online'
            }`}
            pageContext={props.pageContext}
            i18nStyle={{
              zh: {
                className: 's-11-nav',
                style: {
                  marginLeft: cart?.lineItems?.nodes?.length > 0 ? 5 : 0,
                  fontWeight: '500',
                  fontSize: '11px',
                },
              },
            }}
            headEle={() => (
              <span
                style={{
                  display: 'inline-block',
                  transform: 'translate3d(-3px, 0px, 0) scale(0.6)',
                  transformOrigin: 'left top',
                }}
              >
                {cartCount}
              </span>
            )}
          >
            SELECTED
          </TransAni>
        </div>
      </div>
      <div className={`cartModal ${showCart ? 'active' : ''}`}>
        <div
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
          }}
          onClick={onCartClick}
        />
        <div className="left-panel">
          <p className="GSText s-17">Order Summary</p>
          <div className="close-900" onClick={onCartClick}>
            <i className="icon-close" />
          </div>
          <div className="detail-container">
            <div className="price-info">
              <div className="subtotal align-end">
                <div className="title">
                  <p className="GSText block">SHIPPING</p>
                  <p className="GSText bloc shipping-desc">
                    {onlineShopMenu?.metaobject?.field?.value &&
                    JSON.parse(onlineShopMenu?.metaobject?.field?.value) &&
                    JSON.parse(onlineShopMenu?.metaobject?.field?.value)[
                      setting.country
                    ]?.desc &&
                    timestamp >= new Date(startedAt).getTime() &&
                    timestamp < new Date(endAt).getTime()
                      ? `${
                          JSON.parse(onlineShopMenu?.metaobject?.field?.value)[
                            setting.country
                          ]?.desc
                        }: ${countdown}`
                      : null}
                  </p>
                </div>
                {/* <div className="SAText currency s-13">
                    {currencyCode(cart.totalPriceV2.currencyCode)}$
                  </div>
                  <div className="SAText total s-13">0</div> */}
              </div>
              {cart.totalPriceV2 && (
                <>
                  <div className="subtotal">
                    <div className="title">
                      <p className="GSText s-15">SUBTOTAL</p>
                    </div>
                    <div className="SAText currency s-13">
                      {currencyCode(cart.totalPriceV2.currencyCode)}$
                    </div>
                    <div className="SAText total s-13">
                      {/* {cart.totalPriceV2.amount} */}
                      {dollarFloat(cart.totalPriceV2.amount, setting.country)}
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="sep-1" />
            <div ref={clothDetail} className="cloth-detail">
              {cart?.lineItems?.nodes?.length > 0 &&
                cart?.lineItems?.nodes?.map((item) => {
                  // console.log('=> qty', item);

                  const prod = item.variant;

                  if (!prod || prod.product) {
                    return null;
                  }

                  const itemId =
                    prod?.product?.id.split('/')[
                      prod?.product?.id.split('/').length - 1
                    ];
                  // console.log(
                  //   '==> ppp',
                  //   cart,
                  //   cart?.lineItems?.nodes,
                  //   item,
                  //   cart?.lineItems
                  // );

                  return (
                    <div key={`cart-${item.id}`} className="item-detail">
                      <Link
                        to={`${langPrefix(pageContext)}/products/${itemId}`}
                        onClick={closeCartIfShow}
                      >
                        <img src={prod.image.url} />
                      </Link>
                      <div className="item-detail-content">
                        <div>
                          <Link
                            to={`${langPrefix(pageContext)}/products/${itemId}`}
                            onClick={closeCartIfShow}
                          >
                            <div>
                              <p className="GSText block title">
                                {/* Double-breasted scarf trench */}
                                {prod.product.title}
                              </p>
                              <p className="GSText block price">
                                {currencyCode(cart.totalPriceV2.currencyCode)}${' '}
                                {dollarFloat(
                                  prod.price.amount * item.quantity,
                                  setting.country
                                )}
                                {/* {prod.price.amount * item.quantity} */}
                              </p>
                            </div>
                          </Link>
                          <div>
                            {prod.sku === 'exhibition' ? (
                              <>
                                <div className="line">
                                  <div className="label">
                                    <p className="GSText">type</p>
                                  </div>
                                  <div className="content">
                                    {/* <p className="GSText">1</p> */}
                                    <div className="count">
                                      <p className="GSText">
                                        {/* <Trans>Exhibition</Trans> */}
                                        {useJSON ? (
                                          (staticLocales[
                                            pageContext.language
                                          ] &&
                                            staticLocales[pageContext.language]
                                              .Exhibition) ||
                                          'Exhibition'
                                        ) : (
                                          <Trans>Exhibition</Trans>
                                        )}
                                        {/* {getOptionsColor(
                                          prod?.product?.options,
                                          props.pageContext.language.toUpperCase()
                                        )} */}
                                      </p>
                                    </div>
                                    {/* <div className="remove">
                                      <p
                                        onClick={() => {
                                          removeCartItem({
                                            itemId: item.id,
                                            setLoading: setEditItemsLoading,
                                            setPopupInfo,
                                          });
                                        }}
                                      >
                                        remove
                                      </p>
                                    </div> */}
                                  </div>
                                </div>
                                <div className="line">
                                  <div className="label">
                                    <p className="GSText">extra</p>
                                  </div>
                                  <div className="content">
                                    {/* <p className="GSText">1</p> */}
                                    <div className="count">
                                      <p className="GSText">
                                        {currencyCode(
                                          cart.totalPriceV2.currencyCode
                                        )}
                                        $ {prod.price.amount} coupon x{' '}
                                        {item.quantity}
                                        {/* {getOptionsColor(
                                          prod?.product?.options,
                                          props.pageContext.language.toUpperCase()
                                        )} */}
                                      </p>
                                    </div>
                                    {/* <div className="remove">
                                      <p
                                        onClick={() => {
                                          removeCartItem({
                                            itemId: item.id,
                                            setLoading: setEditItemsLoading,
                                            setPopupInfo,
                                          });
                                        }}
                                      >
                                        remove
                                      </p>
                                    </div> */}
                                  </div>
                                </div>
                                <div className="line">
                                  <div className="label">
                                    <p className="GSText">qty.</p>
                                  </div>
                                  <div className="content">
                                    <div className="count">
                                      <p className="GSText">{item.quantity}</p>
                                    </div>
                                    <div className="remove">
                                      <p
                                        onClick={(e) => {
                                          e.preventDefault();
                                          removeCartItem({
                                            useJSON,
                                            staticLocales,
                                            setting,
                                            itemId: item.id,
                                            setLoading: setEditItemsLoading,
                                            setPopupInfo,
                                          });
                                        }}
                                      >
                                        remove
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="line">
                                  <div className="label">
                                    <p className="GSText">color</p>
                                  </div>
                                  <div className="content">
                                    {/* <p className="GSText">1</p> */}
                                    <p className="GSText">
                                      {getOptionsColor(
                                        prod?.product?.options,
                                        props.pageContext.language.toUpperCase()
                                      )}
                                    </p>
                                  </div>
                                </div>
                                <div className="line">
                                  <div className="label">
                                    <p className="GSText">size</p>
                                  </div>
                                  <div className="content">
                                    <p className="GSText">
                                      {prod?.selectedOptions[1]?.value}
                                    </p>
                                  </div>
                                </div>
                                <div className="line">
                                  <div className="label">
                                    <p className="GSText">qty.</p>
                                  </div>
                                  <div className="content">
                                    <div className="count">
                                      <span
                                        className="pointer"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          modifyItemQuantity({
                                            useJSON,
                                            staticLocales,
                                            setting,
                                            item,
                                            action: 'remove',
                                            setLoading: setEditItemsLoading,
                                            setPopupInfo,
                                          });
                                        }}
                                      >
                                        -
                                      </span>
                                      <p className="GSText">{item.quantity}</p>
                                      <span
                                        className="pointer"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          if (item.quantity >= 3) {
                                            return;
                                          }
                                          modifyItemQuantity({
                                            useJSON,
                                            staticLocales,
                                            setting,
                                            item,
                                            action: 'add',
                                            setLoading: setEditItemsLoading,
                                            setPopupInfo,
                                          });
                                        }}
                                      >
                                        +
                                      </span>
                                    </div>
                                    <div className="remove">
                                      <p
                                        className="pointer"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          removeCartItem({
                                            useJSON,
                                            staticLocales,
                                            setting,
                                            itemId: item.id,
                                            setLoading: setEditItemsLoading,
                                            setPopupInfo,
                                          });
                                        }}
                                      >
                                        remove
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    // </Link>
                  );
                })}
            </div>
            <Loading
              className="add-item-loading"
              type="middle"
              loading={editItemsLoading}
            />
          </div>
          <span className="scroll-bar" />
          <div className="checkout-bar">
            <TransAni
              useJSON={useJSON}
              onClick={onCartClick}
              containerClass="continue-shop pointer"
              textClass="GSText s-11 gray"
              pageContext={props.pageContext}
              type="horizontal"
              borderAni={false}
              i18nStyle={{
                zh: {
                  className: 's-9 horizontal-text-center',
                  style: {
                    // fontWeight: '500',
                    // textAlign: 'left',
                    // lineHeight: '1.7em',
                  },
                },
              }}
            >
              Continue Shopping
            </TransAni>
            {/* <p className="GSText s-11 gray" onClick={onCartClick}>
              Continue Shopping
            </p> */}
            <a
              className="block checkout-button"
              href={
                cart.checkoutUrl ||
                (cart.webUrl &&
                  cart.webUrl.replace('joucos.myshopify', 'pay.joucos'))
              }
            >
              {/* <div className="GSText button"> */}
              <TransAni
                useJSON={useJSON}
                containerClass="button pointer"
                textClass="GSText"
                pageContext={props.pageContext}
                type="horizontal"
                borderAni={false}
                i18nStyle={{
                  zh: {
                    className: 's-11 horizontal-text-center',
                    style: {
                      fontWeight: '500',
                      // textAlign: 'left',
                      // lineHeight: '1.7em',
                    },
                  },
                }}
              >
                CHECKOUT
              </TransAni>
              {/* </div> */}
            </a>
            {/* <Loading
              className="add-item-loading"
              type="middle"
              loading={editItemsLoading}
            /> */}
          </div>
        </div>
      </div>
      <div
        className={`show-menu-900 ${showMenu900 && 'show-menu-900-active'}`}
        onClick={() => {}}
        // onClick={onSearchClick}
      >
        {/* <div className="" /> */}
        <div className="GSText s-13 white margin-bottom-20">
          <div
            className="display-flex justify-content width-50 align-items-center pointer"
            onClick={() => {
              setSubOnlineShop(!subOnlineShop);
            }}
          >
            {useJSON ? (
              (staticLocales[pageContext.language] &&
                staticLocales[pageContext.language]['ONLINE STORE']) ||
              'ONLINE STORE'
            ) : (
              <Trans>ONLINE STORE</Trans>
            )}
            {/* <Trans>ONLINE STORE</Trans> */}
            <i
              className={`icon-plus ${
                subOnlineShop ? 'icon-plus-active' : ''
              } s-11`}
            />
          </div>
          <div
            className={`sub-online-shop-900 ${
              subOnlineShop ? 'sub-online-shop-900-active' : ''
            }`}
          >
            {onlineShopMenu?.collections?.nodes
              ?.filter((item) => {
                // let json = {};
                const isProd = JSON.parse(item?.isProd?.value || 'false');
                // try {
                //   json = JSON.parse(item.descriptionHtml);
                // } catch (e) {}
                // if (!json.prod && env === 'prod') {
                //   return false;
                // }
                // console.log('===> isProd', item.title, isProd, item);
                // if (isProd) {
                //   return true;
                // }
                if (env === 'prod') {
                  return isProd;
                }
                return true;

                // return true;
              })
              ?.sort(
                (a, b) =>
                  // let jsonA = {};
                  // let jsonB = {};
                  // try {
                  //   if (a.descriptionHtml) {
                  //     jsonA = JSON.parse(a.descriptionHtml);
                  //   }
                  //   if (b.descriptionHtml) {
                  //     jsonB = JSON.parse(b.descriptionHtml);
                  //   }
                  // } catch (e) {
                  //   // console.log('===> item21', item, e);
                  // }
                  // console.log('===> item22', jsonA.sort, jsonB.sort);
                  (a.sort?.value || 100) - (b.sort?.value || 100)
              )
              .map((item) => {
                const collectionId =
                  item.id.split('/')[item.id.split('/').length - 1];
                let json = {};
                try {
                  if (item.descriptionHtml) {
                    json = JSON.parse(item.descriptionHtml);
                  }
                } catch (e) {
                  console.log('===> item21', item, e);
                }

                // patch
                if (item.handle === 'new-arrivals') {
                  return null;
                }
                // patch

                // if (!json.prod) {
                //   return '';
                // }
                // console.log(
                //   '===> item2',
                //   item,
                //   collectionId,
                //   {
                //     collectionId,
                //     ...item,
                //   }
                //   // withPrefix('https://aaa')
                // );
                return (
                  <Link
                    key={collectionId}
                    to={`${langPrefix(
                      props.pageContext,
                      location
                    )}/collections/${item.handle}`}
                    // collectionId={collectionId}
                    state={{ collectionId, ...item }}
                    onClick={(e) => {
                      e.stopPropagation();
                      // document.body.style.overflow = 'scroll';
                      setTimeout(() => {
                        closeIfShow();
                      }, 100);
                    }}
                  >
                    <TransAni
                      useJSON={useJSON}
                      type="horizontal"
                      containerClass={`p-v-5 opacity6-hover1 ${
                        isBrowser() &&
                        window?.location?.pathname?.includes(item.handle)
                          ? 'nav-active'
                          : ''
                      } pointer`}
                      // containerStyle={{ paddingTop: '6vh' }}
                      // containerStyle={{
                      //   paddingTop: json.sort === 99 ? '4vh' : 0,
                      // }}
                      textClass="SAText s-13  white"
                      pageContext={props.pageContext}
                      borderAni={false}
                      i18nAniAlign="left"
                      i18nStyle={{
                        zh: {
                          className: 's-13',
                          style: {
                            fontWeight: '500',
                            textAlign: 'left',
                            lineHeight: '1.7em',
                          },
                        },
                      }}
                      // tailEle={() => (
                      //   <p
                      //     style={{ position: 'absolute', right: 0, top: '6vh' }}
                      //   >
                      //     +
                      //   </p>
                      // )}
                    >
                      {/* NEW ARRIVALS */}
                      {item.title}
                    </TransAni>
                  </Link>
                );
              })}
          </div>
        </div>
        <Link
          to={`${langPrefix(props.pageContext, location)}/collections`}
          // to="/collections"
          activeClassName="nav-active"
          onClick={closeCartIfShow}
        >
          <div className="GSText s-13 white margin-bottom-20 pointer">
            {useJSON ? (
              (staticLocales[pageContext.language] &&
                staticLocales[pageContext.language].COLLECTION) ||
              'COLLECTION'
            ) : (
              <Trans>COLLECTION</Trans>
            )}
            {/* <i className="icon-plus" /> */}
          </div>
        </Link>
        <div
          className="GSText s-13 white margin-bottom-20 pointer"
          onClick={onSearchClick}
        >
          {useJSON ? (
            (staticLocales[pageContext.language] &&
              staticLocales[pageContext.language].SEARCH) ||
            'SEARCH'
          ) : (
            <Trans>SEARCH</Trans>
          )}
          {/* <Trans>SEARCH</Trans> */}
        </div>
        <div className="flex-1" />
        <Link
          // to="/account"
          to={`${
            user?.token?.accessToken
              ? `${langPrefix(props.pageContext, location)}/account`
              : `${langPrefix(props.pageContext, location)}/account/login`
          }`}
          onClick={closeCartIfShow}
        >
          <div
            className="GSText s-13 white margin-bottom-20 pointer"
            // onClick={onSearchClick}
          >
            {/* <Trans>ACCOUNT</Trans> */}
            {useJSON ? (
              (staticLocales[pageContext.language] &&
                staticLocales[pageContext.language].ACCOUNT) ||
              'ACCOUNT'
            ) : (
              <Trans>ACCOUNT</Trans>
            )}
          </div>
        </Link>
        <p
          className="GSText s-13 white margin-bottom-50 pointer"
          onClick={() => {
            setPopupInfo({
              active: true,
              type: '',
              onDisable: (callback = () => {}, state) => {
                // console.log(
                //   mkt.toUpperCase(),
                //   setting.country.toUpperCase()
                // );
                // if (
                //   state.toUpperCase() ===
                //   setting.country.toUpperCase()
                // ) {
                //   return;
                // }
                // if (mkt === 'TW') {
                //   // console.log(refs.current.ZH);
                //   document.querySelector('[hreflang=zh]').click();
                //   console.log(
                //     'zh',
                //     document.querySelector('[hreflang=zh]')
                //   );
                // } else {
                //   // console.log(refs.current.EN);
                //   document.querySelector('[hreflang=en]').click();
                //   console.log(
                //     'en',
                //     document.querySelector('[hreflang=zh]')
                //   );
                // }
                // navigate(`${origin}${tailUrl}`, {
                //   replace: false,
                // });
                // changeLanguage(state.language);
                // alert(`${state.language}, ${state.country}`);
                if (!isBrowser()) return;
                if (state.language !== pageContext.language) {
                  if (state.language === 'zh') {
                    document.querySelector('[hreflang=zh]').click();
                  } else {
                    document.querySelector('[hreflang=en]').click();
                  }
                }
                updateSetting({
                  country: { $set: state.country },
                  countryInfo: {
                    $set: shopifyCountry[countryCompare[state.country]],
                  },
                  countryName: { $set: countryCompare[state.country] },
                });
                callback();
                setLanguage(state.language);
                setCountry(state.country);
                // if (
                //   state.language === pageContext.language &&
                //   state.country !== country &&
                //   isBrowser()
                // ) {
                //   window.location.reload();
                // }
                // changeLanguage(state.language);
              },
              data: {
                state: {
                  language,
                  country,
                },
                setState: {
                  setLanguage,
                  setCountry,
                },
                title: (
                  <TransAni
                    useJSON={useJSON}
                    containerClass="GSText button max-width-120 margin-auto"
                    textClass="GSText s-15"
                    pageContext={pageContext}
                    i18nAniAlign="center"
                    type="horizontal"
                    borderAni={false}
                    i18nStyle={{
                      zh: {
                        className: 's-13',
                        style: {
                          fontWeight: '500',
                          textAlign: 'center',
                          lineHeight: '1.7em',
                          width: '100%',
                        },
                      },
                    }}
                    onClick={() => {}}
                  >
                    Language & Location
                  </TransAni>
                ),
                Component: (state, setState) => (
                  <div
                    style={{
                      padding: '0px 25px 25px 25px',
                      // display: 'inline-block',
                    }}
                  >
                    <TransAni
                      useJSON={useJSON}
                      containerClass="GSText button margin-bottom-10"
                      textClass="GSText s-15"
                      pageContext={pageContext}
                      type="horizontal"
                      borderAni={false}
                      i18nStyle={{
                        zh: {
                          className: 's-13',
                          style: {
                            fontWeight: '500',
                            // textAlign: 'left',
                            lineHeight: '1.7em',
                          },
                        },
                      }}
                      onClick={() => {}}
                    >
                      Language
                    </TransAni>
                    <ul className="languages">
                      {pageContext?.i18n?.languages.map((lng) => (
                        // setState
                        <li
                          key={lng}
                          className="p-v-5 pointer"
                          onClick={() => {
                            // setState.setLanguage(lng);
                            setState({
                              ...state,
                              language: lng,
                            });
                            // setPopupInfo({
                            //   ...popupInfo,
                            //   active: true,
                            //   data: {
                            //     ...popupInfo.data,

                            //     state: {
                            //       language,
                            //       country,
                            //     },
                            //   },
                            // });
                            console.log('==> setState', setState, lng);
                          }}
                        >
                          <p
                            className={`SAText s-11 inline-block ${
                              state?.language === lng && 'border-bottom'
                            }`}
                          >
                            {lng.toUpperCase()}
                          </p>
                        </li>
                        // <I18nLink
                        //   // ref={(link) => {
                        //   //   console.log(link);
                        //   //   refs.current[lng] = link;
                        //   // }}
                        //   to={path}
                        //   language={lng}
                        // >
                        //   <li key={lng} className="p-v-5">
                        //     <p
                        //       className={`SAText s-11 inline-block ${
                        //         state.language === lng && 'border-bottom'
                        //       }`}
                        //     >
                        //       {lng.toUpperCase()}
                        //     </p>
                        //   </li>
                        // </I18nLink>
                      ))}
                    </ul>
                    <TransAni
                      useJSON={useJSON}
                      containerClass="GSText button margin-bottom-10 margin-top-25"
                      textClass="GSText s-15"
                      pageContext={pageContext}
                      type="horizontal"
                      borderAni={false}
                      i18nStyle={{
                        zh: {
                          className: 's-13',
                          style: {
                            fontWeight: '500',
                            // textAlign: 'left',
                            lineHeight: '1.7em',
                          },
                        },
                      }}
                      onClick={() => {}}
                    >
                      Location
                    </TransAni>
                    <ul className="languages">
                      {setting.markets.map((mkt) => (
                        <li
                          key={mkt}
                          className="p-v-5 pointer"
                          onClick={() => {
                            setState({
                              ...state,
                              country: mkt,
                            });
                            // console.log(
                            //   mkt.toUpperCase(),
                            //   setting.country.toUpperCase()
                            // );
                            // if (
                            //   mkt.toUpperCase() ===
                            //   setting.country.toUpperCase()
                            // ) {
                            //   return;
                            // }
                            // if (mkt === 'TW') {
                            //   // console.log(refs.current.ZH);
                            //   document.querySelector('[hreflang=zh]').click();
                            //   console.log(
                            //     'zh',
                            //     document.querySelector('[hreflang=zh]')
                            //   );
                            // } else {
                            //   // console.log(refs.current.EN);
                            //   document.querySelector('[hreflang=en]').click();
                            //   console.log(
                            //     'en',
                            //     document.querySelector('[hreflang=zh]')
                            //   );
                            // }
                            // // navigate(`${origin}${tailUrl}`, {
                            // //   replace: false,
                            // // });
                            // updateSetting({
                            //   country: { $set: mkt },
                            //   countryInfo: {
                            //     $set: shopifyCountry[countryCompare[mkt]],
                            //   },
                            //   countryName: { $set: countryCompare[mkt] },
                            // });
                          }}
                        >
                          <p
                            className={`SAText s-11 inline-block ${
                              state?.country?.toUpperCase() === mkt &&
                              'border-bottom'
                            }`}
                          >
                            {countryCompare[mkt]}
                          </p>
                          {/* <Trans>{countryCompare[mkt]}</Trans> */}
                        </li>
                      ))}
                    </ul>
                  </div>
                ),
              },
            });
          }}
        >
          TW (TWD) | {pageContext?.i18n?.language.toUpperCase()}
        </p>
      </div>
      <div
        className={`online-shop ${
          showOnlineShop && 'online-shop-active'
        } pointer`}
        onClick={onOnlineShopClick}
        // onClick={onSearchClick}
      >
        <div />

        {onlineShopMenu?.collections?.nodes
          ?.filter((item) => {
            // let json = {};
            const isProd = JSON.parse(item?.isProd?.value || 'false');
            // try {
            //   json = JSON.parse(item.descriptionHtml);
            // } catch (e) {}
            // if (!json.prod && env === 'prod') {
            //   return false;
            // }
            // console.log('===> isProd', item.title, isProd);
            // if (isProd) {
            //   return true;
            // }

            if (env === 'prod') {
              return isProd;
            }
            return true;

            // return true;
          })
          ?.sort(
            (a, b) =>
              // let jsonA = {};
              // let jsonB = {};
              // try {
              //   if (a.descriptionHtml) {
              //     jsonA = JSON.parse(a.descriptionHtml);
              //   }
              //   if (b.descriptionHtml) {
              //     jsonB = JSON.parse(b.descriptionHtml);
              //   }
              // } catch (e) {
              //   // console.log('===> item21', item, e);
              // }
              // console.log('===> item22', jsonA.sort, jsonB.sort);
              // return a.sort - b.sort;
              (a.sort?.value || 100) - (b.sort?.value || 100)
          )
          .map((item) => {
            const collectionId =
              item.id.split('/')[item.id.split('/').length - 1];
            let json = {};
            try {
              if (item.descriptionHtml) {
                json = JSON.parse(item.descriptionHtml);
              }
            } catch (e) {
              console.log('===> item21', item, e);
            }

            // if (!json.prod) {
            //   return '';
            // }
            // console.log(
            //   '===> item2',
            //   item,
            //   collectionId,
            //   {
            //     collectionId,
            //     ...item,
            //   }
            //   // withPrefix('https://aaa')
            // );
            return (
              <Link
                key={collectionId}
                to={`${langPrefix(props.pageContext, location)}/collections/${
                  item.handle
                }`}
                // collectionId={collectionId}
                state={{ collectionId, ...item }}
                onClick={(e) => {
                  e.stopPropagation();
                  // document.body.style.overflow = 'scroll';
                  setTimeout(() => {
                    closeIfShow();
                  }, 100);
                }}
              >
                <TransAni
                  useJSON={useJSON}
                  type="horizontal"
                  containerClass={`p-v-5 opacity6-hover1 ${
                    isBrowser() &&
                    window?.location?.pathname?.includes(item.handle)
                      ? 'nav-active'
                      : ''
                  } pointer`}
                  // containerStyle={{ paddingTop: '6vh' }}
                  // containerStyle={{ paddingTop: json.sort === 99 ? '4vh' : 0 }}
                  textClass="SAText s-13  white"
                  pageContext={props.pageContext}
                  borderAni={false}
                  i18nAniAlign="left"
                  i18nStyle={{
                    zh: {
                      className: 's-11',
                      style: {
                        fontWeight: '500',
                        textAlign: 'left',
                        lineHeight: '1.7em',
                      },
                    },
                  }}
                  // tailEle={() => (
                  //   <p style={{ position: 'absolute', right: 0, top: '6vh' }}>+</p>
                  // )}
                >
                  {/* NEW ARRIVALS */}
                  {item.title}
                </TransAni>
              </Link>
            );
          })}
        <div style={{ opacity: 0 }}>
          <p className="p-v-5 SAText s-13  white" style={{ paddingTop: '8vh' }}>
            TOPICS
          </p>
        </div>
        <div style={{ opacity: 0 }}>
          <TransAni
            useJSON={useJSON}
            type="horizontal"
            containerClass="p-v-5 opacity6-hover1 pointer"
            textClass="SAText s-13  white"
            pageContext={props.pageContext}
            borderAni={false}
            i18nAniAlign="left"
            i18nStyle={{
              zh: {
                className: 's-11',
                style: {
                  fontWeight: '500',
                  textAlign: 'left',
                  lineHeight: '1.7em',
                },
              },
            }}
          >
            THE CLASSICS
          </TransAni>
        </div>
        <div style={{ opacity: 0 }}>
          <TransAni
            useJSON={useJSON}
            type="horizontal"
            containerClass="p-v-5 opacity6-hover1 "
            textClass="SAText s-13  white"
            pageContext={props.pageContext}
            borderAni={false}
            i18nAniAlign="left"
            i18nStyle={{
              zh: {
                className: 's-11',
                style: {
                  fontWeight: '500',
                  textAlign: 'left',
                  lineHeight: '1.7em',
                },
              },
            }}
          >
            THE GARDEN
          </TransAni>
        </div>
      </div>
      <div
        className={`online-shop ${showOnlineShop && 'online-shop-active'}`}
        onClick={onOnlineShopClick}
        // onClick={onSearchClick}
      >
        <div />

        {onlineShopMenu?.collections?.nodes
          ?.filter((item) => {
            // let json = {};
            const isProd = JSON.parse(item?.isProd?.value || 'false');
            // try {
            //   json = JSON.parse(item.descriptionHtml);
            // } catch (e) {}
            // if (!json.prod && env === 'prod') {
            //   return false;
            // }
            // console.log('===> isProd', item.title, isProd);
            // if (isProd) {
            //   return true;
            // }
            if (env === 'prod') {
              return isProd;
            }
            return true;

            // return true;
          })
          ?.sort(
            (a, b) =>
              // let jsonA = {};
              // let jsonB = {};
              // try {
              //   if (a.descriptionHtml) {
              //     jsonA = JSON.parse(a.descriptionHtml);
              //   }
              //   if (b.descriptionHtml) {
              //     jsonB = JSON.parse(b.descriptionHtml);
              //   }
              // } catch (e) {
              //   // console.log('===> item21', item, e);
              // }
              // console.log('===> item22', jsonA.sort, jsonB.sort);
              // return a.sort - b.sort;
              (a.sort?.value || 100) - (b.sort?.value || 100)
          )
          .map((item) => {
            const collectionId =
              item.id.split('/')[item.id.split('/').length - 1];
            let json = {};
            try {
              if (item.descriptionHtml) {
                json = JSON.parse(item.descriptionHtml);
              }
            } catch (e) {
              console.log('===> item21', item, e);
            }

            // if (!json.prod) {
            //   return '';
            // }
            // console.log(
            //   '===> item2',
            //   item,
            //   collectionId,
            //   {
            //     collectionId,
            //     ...item,
            //   }
            //   // withPrefix('https://aaa')
            // );
            return (
              <Link
                key={collectionId}
                to={`${langPrefix(props.pageContext, location)}/collections/${
                  item.handle
                }`}
                // collectionId={collectionId}
                state={{ collectionId, ...item }}
                onClick={(e) => {
                  e.stopPropagation();
                  // document.body.style.overflow = 'scroll';
                  setTimeout(() => {
                    closeIfShow();
                  }, 100);
                }}
              >
                <TransAni
                  useJSON={useJSON}
                  type="horizontal"
                  containerClass={`p-v-5 opacity6-hover1 ${
                    isBrowser() &&
                    window?.location?.pathname?.includes(item.handle)
                      ? 'nav-active'
                      : ''
                  } pointer`}
                  // containerStyle={{ paddingTop: '6vh' }}
                  // containerStyle={{ paddingTop: json.sort === 99 ? '4vh' : 0 }}
                  textClass="SAText s-13  white"
                  pageContext={props.pageContext}
                  borderAni={false}
                  i18nAniAlign="left"
                  i18nStyle={{
                    zh: {
                      className: 's-11',
                      style: {
                        fontWeight: '500',
                        textAlign: 'left',
                        lineHeight: '1.7em',
                      },
                    },
                  }}
                  // tailEle={() => (
                  //   <p style={{ position: 'absolute', right: 0, top: '6vh' }}>+</p>
                  // )}
                >
                  {/* NEW ARRIVALS */}
                  {item.title}
                </TransAni>
              </Link>
            );
          })}
        <div style={{ opacity: 0 }}>
          <p className="p-v-5 SAText s-13  white" style={{ paddingTop: '8vh' }}>
            TOPICS
          </p>
        </div>
        <div style={{ opacity: 0 }}>
          <TransAni
            useJSON={useJSON}
            type="horizontal"
            containerClass="p-v-5 opacity6-hover1 pointer"
            textClass="SAText s-13  white"
            pageContext={props.pageContext}
            borderAni={false}
            i18nAniAlign="left"
            i18nStyle={{
              zh: {
                className: 's-11',
                style: {
                  fontWeight: '500',
                  textAlign: 'left',
                  lineHeight: '1.7em',
                },
              },
            }}
          >
            THE CLASSICS
          </TransAni>
        </div>
        <div style={{ opacity: 0 }}>
          <TransAni
            useJSON={useJSON}
            type="horizontal"
            containerClass="p-v-5 opacity6-hover1 pointer"
            textClass="SAText s-13  white"
            pageContext={props.pageContext}
            borderAni={false}
            i18nAniAlign="left"
            i18nStyle={{
              zh: {
                className: 's-11',
                style: {
                  fontWeight: '500',
                  textAlign: 'left',
                  lineHeight: '1.7em',
                },
              },
            }}
          >
            THE GARDEN
          </TransAni>
        </div>
      </div>
      <div
        className={`search ${showSearch && 'search-active'}`}
        // onClick={onOnlineShopClick}
      >
        <div className="right-container">
          <div className="input-container">
            <Input
              id="search-text"
              containerStyle={{ marginBottom: 0, marginRight: 15 }}
              inputClass="text-right"
              inputStyle={{ paddingBottom: 5 }}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
            <i className="icon-close pointer" onClick={onSearchClick} />
          </div>
          {!searchText && (
            <div className="block-container">
              <TransAni
                useJSON={useJSON}
                type="horizontal"
                containerClass="p-v-5 opacity4"
                textClass="GSText s-13"
                pageContext={props.pageContext}
                borderAni={false}
                i18nAniAlign="right"
                i18nStyle={{
                  zh: {
                    className: 's-13',
                    style: {
                      fontWeight: '500',
                      textAlign: 'right',
                      lineHeight: '1.7em',
                    },
                  },
                }}
              >
                recently searched
              </TransAni>
              {recentlySearched.map((item, index) => (
                <div
                  key={`recentlySearched-${item.id}-${index} pointer`}
                  className="input-history"
                  onClick={() => {
                    if (isBrowser()) {
                      setSearchText(item);
                      document.querySelector('#search-text').value = item;
                    }
                  }}
                >
                  <p className="s-13 GSText">{item}</p>
                  <i
                    className="icon-close pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      const recently = [...recentlySearched];
                      recently.splice(index, 1);
                      localStorage.setItem(
                        'RecentlySearched',
                        JSON.stringify(recently)
                      );
                      setRecentlySearched(recently);
                    }}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
        {searchText && (
          <div className="nav-header">
            <p className="s-13 opacity4 SAText l-15">
              {searchItems?.length || 0} RESULTS FOR "{searchText}"
            </p>
            <div className="controllor-container">
              {/* <p className="SAText">VIEW</p> */}
              <p
                className={`${grid === 3 && 'selected'} SAText pointer`}
                onClick={() => {
                  setGrid(3);
                }}
              >
                3<span />
              </p>
              <p
                className={`${grid === 4 && 'selected'} SAText pointer`}
                onClick={() => {
                  setGrid(4);
                }}
              >
                4<span />
              </p>
              <p
                className={`${grid === 5 && 'selected'} SAText pointer`}
                onClick={() => {
                  setGrid(5);
                }}
              >
                5<span />
              </p>
            </div>
          </div>
        )}
        <div className="left-container">
          {searchText && (
            <div className="items" data-column={grid}>
              {searchItems?.map((item) => {
                const itemId =
                  item?.id.split('/')[item?.id.split('/').length - 1];
                return (
                  <div key={item.id} className="item">
                    <Item
                      // className="opacity6"
                      setting={setting}
                      location={location}
                      pageContext={props.pageContext}
                      itemId={itemId}
                      item={item}
                      url={item?.images?.nodes[0]?.url}
                      onClick={() => {
                        onSearchClick();
                        const indexOfSearch =
                          recentlySearched.includes(searchText);
                        const recently = [...recentlySearched];
                        if (indexOfSearch < 0) {
                          recently.push(searchText);
                          // recently = [...recentlySearched, searchText];
                        }

                        localStorage.setItem(
                          'RecentlySearched',
                          JSON.stringify(recently)
                        );
                        if (showMenu900) {
                          lockWheel(false);
                          setShowMenu900(false);
                        }
                        setRecentlySearched(recently);
                      }}
                    />
                  </div>
                );
              })}
            </div>
          )}
          <Loading
            className="search-loading-900"
            type={showMenu900 ? 'middle' : 'XLarge'}
            loading={pageLoading}
          />
        </div>
      </div>
    </Nav>
  );
}

// const client = Client.buildClient({
//   domain: 'joucos.myshopify.com',
//   storefrontAccessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_API_TOKEN,
// });

// const Nav = styled.nav`
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   /* height: 56px; */
//   height: 113px;
//   /* background: var(--white); */
//   /* border-bottom: 1px solid #999; */
//   z-index: 10;
//   padding: 0px 50px;
//   display: flex;
//   align-items: center;
//   .left-half {
//     display: flex;
//     align-items: center;
//     /* padding: 50px; */
//     /* display: grid; */
//     /* height: auto; */
//     * {
//       font-size: 13px;
//     }
//     .items {
//       flex: 1;
//     }
//     @media screen and (max-width: 1090px) {
//       /* flex: 1; */
//       width: 66.666%;
//     }
//     .nav-item {
//       margin-right: 50px;
//       @media screen and (max-width: 1280px) {
//         margin-right: 25px;
//       }
//     }
//   }
//   .right-half {
//     /* padding: 50px; */
//     /* display: grid; */
//     /* height: auto; */
//     display: flex;
//     align-items: center;
//     justify-content: flex-end;
//     * {
//       font-size: 13px;
//     }
//     @media screen and (max-width: 1090px) {
//       /* flex: 1; */
//       width: 33.333%;
//     }
//     .nav-item {
//       margin-left: 50px;
//       @media screen and (max-width: 1280px) {
//         margin-left: 25px;
//       }
//     }
//   }
//   .nav-item {
//     /* margin-right: 50px; */
//     display: inline-block;
//     &:last-child {
//       /* margin-right: 0px; */
//     }
//     @media screen and (max-width: 1280px) {
//       /* margin-right: 25px; */
//     }
//   }
//   .flex-full {
//     flex: 1;
//     align-self: flex-start;
//     margin-top: 52px;
//   }
//   .logo {
//     width: 100%;
//     max-width: 237px;
//     @media screen and (max-width: 1090px) {
//       width: 173px;
//     }
//   }
//   /* .logo {
//     flex: 1;
//   } */
//   p {
//     /* float: left; */
//     display: inline-block;
//     /* margin-right: 32px; */
//   }
// `;

// function getMarket(country) {
//   return countryCompare[country];
// }

// export default function Navigation(props) {
//   const {
//     setting,
//     updateSetting,
//     user,
//     loginCheck,
//     logout,
//     cart,
//     checkCart,
//     updateCartLinesQuantity,
//     modifyItemQuantity,
//     removeCartItem,
//   } = useContext(RootContext);
//   const menu = useHeaderMenuQuery();
//   // const { menu } = props.headerMenuData;
//   console.log('===> menu', menu, cart);
//   useEffect(() => {
//     console.log(
//       '==> loginCheck',
//       loginCheck,
//       user,
//       setting.country,
//       props.pageContext.language.toUpperCase()
//     );
//     if (loginCheck) {
//       checkCart(
//         user,
//         setting.country,
//         props.pageContext.language.toUpperCase()
//       );
//     }
//   }, [loginCheck]);

//   useEffect(() => {
//     // if (cart.id) {
//     //   console.log('====> checkout1', cart.id); // The retrieved checkout
//     //   client.checkout.fetch(cart.id).then((checkout) => {
//     //     console.log('====> checkout', checkout); // The retrieved checkout
//     //   });
//     // }
//   }, [cart]);

//   async function cartItemUpdate(lines) {
//     if (cart.id) {
//       const result = await glStoreFrontFetch({
//         query: mutationFormat({
//           query: CartLinesUpdateMutation,
//           variables: {
//             cartId: cart.id,
//             lines,
//           },
//         }),
//         // setFetchData: setFetchResult,
//         // test: true,
//       });
//       if (result.errors) {
//         return;
//       }
//       if (
//         result.cartLinesUpdate?.userErrors &&
//         result.cartLinesUpdate.userErrors.length > 0
//       ) {
//         console.log(result.cartLinesUpdate.userErrors);
//         // const errors = errorsParser(
//         //   result.cartLinesUpdate.customerUserErrors,
//         //   'reset'
//         // );
//         // console.log(
//         //   errorsParser(result.cartLinesUpdate.customerUserErrors, 'reset')
//         // );

//         // if (errors.includes('無效URL')) {
//         // }
//         return;
//       }
//       updateCartLinesQuantity(result.cartLinesUpdate);
//       console.log({
//         ...result.cartLinesUpdate.cart,
//       });
//     }
//   }
//   return (
//     <Nav>
//       <div className="half left-half">
//         <div className="nav-item">
//           <p>COLLECTION</p>
//         </div>
//         <div className="nav-item">
//           <p>ONLINE STORE</p>
//         </div>
//         <div className="nav-item">
//           <p>PROJECTS</p>
//         </div>
//         <div className="flex-full">
//           {/* <img src="" /> */}
//           <Logo
//             className="logo"
//             // style={{
//             //   width: '100%',
//             //   maxWidth: '237px',
//             // }}
//           />
//         </div>
//       </div>
//       <div className="half right-half">
//         {/* <div className="nav-item">
//           <p>COLLECTION</p>
//         </div> */}
//         <div className="nav-item">
//           <p>SEARCH</p>
//         </div>
//         <div className="nav-item">
//           <p>ACCOUNT</p>
//         </div>
//         <div className="nav-item">
//           <p>CART</p>
//         </div>
//       </div>
//     </Nav>
//   );
// }

// // return (
// //   <Nav>
// //     <div className="half left-half">
// //       <div className="nav-item">
// //         <p>COLLECTION</p>
// //       </div>
// //     </div>
// //     <div className="half">
// //       <div className="nav-item">
// //         <p>COLLECTION</p>
// //       </div>
// //     </div>
// //     <p>
// //       {menu?.items?.map((item) => (
// //         <Link to={item.url.split(process.env.GATSBY_SHOPIFY_STORE_DOMAIN)[1]}>
// //           <Trans>{item.title}</Trans>
// //         </Link>
// //       ))}
// //     </p>
// //     <p>
// //       {getMarket(setting.country)}
// //       {props.pageContext.language === 'zh' ? ' 中文' : ' 英文'}
// //     </p>
// //     <div className="float-right">
// //       <div className="inline-block" style={{ position: 'relative' }}>
// //         <p>{cart?.totalQuantity}</p>
// //         <div
// //           style={{
// //             position: 'absolute',
// //             top: 56,
// //             left: -16,
// //             border: '1px solid #000',
// //             background: 'white',
// //             padding: 8,
// //             whiteSpace: 'nowrap',
// //           }}
// //         >
// //           {cart?.lineItems?.nodes?.length > 0 ? (
// //             <>
// //               {cart?.lineItems?.nodes?.map((item) => {
// //                 const prod = item.variant;
// //                 return (
// //                   <div>
// //                     <p
// //                       className="inline-block"
// //                       style={{ marginRight: 8 }}
// //                       onClick={() => {
// //                         removeCartItem({ itemId: item.id });
// //                       }}
// //                     >
// //                       X
// //                     </p>
// //                     <img
// //                       className="inline-block"
// //                       src={prod.image.url}
// //                       width={48}
// //                       height={48}
// //                       style={{ verticalAlign: 'middle' }}
// //                     />
// //                     <div
// //                       className="inline-block"
// //                       style={{ verticalAlign: 'middle', marginLeft: 8 }}
// //                     >
// //                       <p className="block" style={{ marginRight: 0 }}>
// //                         {prod.product.title}
// //                       </p>
// //                       {/* <p>{item.node.merchandise.selectedOptions.title}</p> */}
// //                       <p className="block" style={{ marginRight: 0 }}>
// //                         {getOptionsColor(
// //                           prod.selectedOptions,
// //                           props.pageContext.language.toUpperCase()
// //                         )}
// //                       </p>
// //                       {prod?.selectedOptions &&
// //                         prod?.selectedOptions[1].value && (
// //                           <p className="block" style={{ marginRight: 0 }}>
// //                             {prod?.selectedOptions[1].value}
// //                           </p>
// //                         )}
// //                       <p className="block" style={{ marginRight: 0 }}>
// //                         <span
// //                           onClick={async () => {
// //                             modifyItemQuantity({
// //                               item,
// //                               action: 'remove',
// //                             });
// //                             // if (item.node.quantity - 1 < 0) {
// //                             //   return;
// //                             // }
// //                             // const lines = cart?.lines?.edges?.map((line) => {
// //                             //   console.log('===> line', line, item);
// //                             //   if (item.node.id === line.node.id) {
// //                             //     return {
// //                             //       id: line.node.id,
// //                             //       merchandiseId: line.node.merchandise.id,
// //                             //       quantity: line.node.quantity - 1,
// //                             //     };
// //                             //   }
// //                             //   return {
// //                             //     id: line.node.id,
// //                             //     merchandiseId: line.node.merchandise.id,
// //                             //     quantity: line.node.quantity,
// //                             //   };
// //                             // });
// //                             // cartItemUpdate(lines);
// //                           }}
// //                         >
// //                           -{' '}
// //                         </span>{' '}
// //                         {item.quantity}{' '}
// //                         <span
// //                           onClick={async () => {
// //                             modifyItemQuantity({
// //                               item,
// //                               action: 'add',
// //                             });
// //                             // if (
// //                             //   item.node.quantity + 1 >
// //                             //   item.node.merchandise.quantityAvailable
// //                             // ) {
// //                             //   return;
// //                             // }
// //                             // const lines = cart?.lines?.edges?.map((line) => {
// //                             //   console.log('===> line', line, item);
// //                             //   if (item.node.id === line.node.id) {
// //                             //     return {
// //                             //       id: line.node.id,
// //                             //       merchandiseId: line.node.merchandise.id,
// //                             //       quantity: line.node.quantity + 1,
// //                             //     };
// //                             //   }
// //                             //   return {
// //                             //     id: line.node.id,
// //                             //     merchandiseId: line.node.merchandise.id,
// //                             //     quantity: line.node.quantity,
// //                             //   };
// //                             // });
// //                             // cartItemUpdate(lines);
// //                           }}
// //                         >
// //                           {' '}
// //                           +
// //                         </span>
// //                       </p>
// //                     </div>
// //                     <p className="block">
// //                       ${prod.price.amount * item.quantity}{' '}
// //                       <span>({prod.price.currencyCode})</span>
// //                     </p>
// //                   </div>
// //                 );
// //               })}
// //               {cart?.lines?.edges?.map((item) => (
// //                 <div>
// //                   <p
// //                     className="inline-block"
// //                     style={{ marginRight: 8 }}
// //                     onClick={() => {
// //                       removeCartItem({ itemId: item.node.id });
// //                     }}
// //                   >
// //                     X
// //                   </p>
// //                   <img
// //                     className="inline-block"
// //                     src={item.node.merchandise.image.url}
// //                     width={48}
// //                     height={48}
// //                     style={{ verticalAlign: 'middle' }}
// //                   />
// //                   <div
// //                     className="inline-block"
// //                     style={{ verticalAlign: 'middle', marginLeft: 8 }}
// //                   >
// //                     <p className="block" style={{ marginRight: 0 }}>
// //                       {item.node.merchandise.product.title}
// //                     </p>
// //                     {/* <p>{item.node.merchandise.selectedOptions.title}</p> */}
// //                     <p className="block" style={{ marginRight: 0 }}>
// //                       {getOptionsColor(
// //                         item.node.merchandise.selectedOptions,
// //                         props.pageContext.language.toUpperCase()
// //                       )}
// //                     </p>
// //                     {item.node.merchandise?.selectedOptions &&
// //                       item.node.merchandise?.selectedOptions[1].value && (
// //                         <p className="block" style={{ marginRight: 0 }}>
// //                           {item.node.merchandise?.selectedOptions[1].value}
// //                         </p>
// //                       )}
// //                     <p className="block" style={{ marginRight: 0 }}>
// //                       <span
// //                         onClick={async () => {
// //                           modifyItemQuantity({
// //                             item,
// //                             action: 'remove',
// //                           });
// //                           // if (item.node.quantity - 1 < 0) {
// //                           //   return;
// //                           // }
// //                           // const lines = cart?.lines?.edges?.map((line) => {
// //                           //   console.log('===> line', line, item);
// //                           //   if (item.node.id === line.node.id) {
// //                           //     return {
// //                           //       id: line.node.id,
// //                           //       merchandiseId: line.node.merchandise.id,
// //                           //       quantity: line.node.quantity - 1,
// //                           //     };
// //                           //   }
// //                           //   return {
// //                           //     id: line.node.id,
// //                           //     merchandiseId: line.node.merchandise.id,
// //                           //     quantity: line.node.quantity,
// //                           //   };
// //                           // });
// //                           // cartItemUpdate(lines);
// //                         }}
// //                       >
// //                         -{' '}
// //                       </span>{' '}
// //                       {item.node.quantity}{' '}
// //                       <span
// //                         onClick={async () => {
// //                           modifyItemQuantity({
// //                             item,
// //                             action: 'add',
// //                           });
// //                           // if (
// //                           //   item.node.quantity + 1 >
// //                           //   item.node.merchandise.quantityAvailable
// //                           // ) {
// //                           //   return;
// //                           // }
// //                           // const lines = cart?.lines?.edges?.map((line) => {
// //                           //   console.log('===> line', line, item);
// //                           //   if (item.node.id === line.node.id) {
// //                           //     return {
// //                           //       id: line.node.id,
// //                           //       merchandiseId: line.node.merchandise.id,
// //                           //       quantity: line.node.quantity + 1,
// //                           //     };
// //                           //   }
// //                           //   return {
// //                           //     id: line.node.id,
// //                           //     merchandiseId: line.node.merchandise.id,
// //                           //     quantity: line.node.quantity,
// //                           //   };
// //                           // });
// //                           // cartItemUpdate(lines);
// //                         }}
// //                       >
// //                         {' '}
// //                         +
// //                       </span>
// //                     </p>
// //                   </div>
// //                   <p className="block">
// //                     ${item.node.cost.totalAmount.amount}{' '}
// //                     <span>({item.node.cost.totalAmount.currencyCode})</span>
// //                   </p>
// //                 </div>
// //               ))}
// //               {cart.cost && (
// //                 <p>
// //                   ${cart.cost.totalAmount.amount}{' '}
// //                   <span>({cart.cost.totalAmount.currencyCode})</span>
// //                 </p>
// //               )}
// //               {cart.totalPriceV2 && (
// //                 <p>
// //                   ${cart.totalPriceV2.amount}{' '}
// //                   <span>({cart.totalPriceV2.currencyCode})</span>
// //                 </p>
// //               )}
// //               <a
// //                 className="block"
// //                 href={
// //                   cart.checkoutUrl ||
// //                   (cart.webUrl &&
// //                     cart.webUrl.replace('joucos.myshopify', 'pay.joucos'))
// //                 }
// //               >
// //                 結帳
// //               </a>
// //             </>
// //           ) : (
// //             <>
// //               <p>沒有商品</p>
// //             </>
// //           )}
// //         </div>
// //       </div>
// //       <p
// //         onClick={() => {
// //           navigate('/account/', {
// //             replace: false,
// //           });
// //         }}
// //       >
// //         {user.lastName}
// //       </p>
// //       {user.id ? (
// //         <p
// //           onClick={() => {
// //             logout();
// //           }}
// //         >
// //           {' '}
// //           登出
// //         </p>
// //       ) : (
// //         <p
// //           onClick={() => {
// //             navigate('/account/login', {
// //               replace: false,
// //             });
// //           }}
// //         >
// //           {' '}
// //           登入
// //         </p>
// //       )}
// //     </div>
// //     {/* <p></p> */}
// //   </Nav>
// // );

// &:hover {
//   &::-webkit-scrollbar {
//     /* display: none; */
//     /* width: 7px; */

//     width: 7px;
//     /* opacity: 0; */
//   }
//   .item-detail {
//     transform: translateX(7px);
//   }
// }
// &::-webkit-scrollbar {
//   /* display: none; */
//   /* width: 7px; */
//   /* width: 0px; */
//   /* transform: scaleX(0); */
//   transition: width 0.3s ease;
//   width: 0px;
//   /* position: absolute; */
//   /* opacity: 0; */
// }
// &::-webkit-scrollbar-track {
//   -webkit-box-shadow: none;
//   box-shadow: none;
//   border-radius: 0;
// }
// &::-webkit-scrollbar-thumb {
//   border-radius: 0;
//   background: none;
//   border-left: 1px solid transparent;
//   -webkit-box-shadow: inset 1px 0 0 0 #000;
//   box-shadow: inset 1px 0 0 0 #000;
// }
// &::-webkit-scrollbar-thumb:window-inactive {
//   background: transparent;
// }
