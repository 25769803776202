export const order = `
  billingAddress {
    address1
    address2
    city
    company
    country
    countryCodeV2
    firstName
    formattedArea
    id
    lastName
    latitude
    longitude
    name
    phone
    province
    provinceCode
    zip
  }
  cancelReason
  canceledAt
  currencyCode
  currentSubtotalPrice {
    amount
    currencyCode
  }
  currentTotalDuties {
    amount
    currencyCode
  }
  currentTotalPrice {
    amount
    currencyCode
  }
  currentTotalTax {
    amount
    currencyCode
  }
  customAttributes {
    key
    value
  }
  customerLocale
  customerUrl
  edited
  email
  financialStatus
  fulfillmentStatus
  id
  name
  orderNumber
  originalTotalDuties {
    amount
    currencyCode
  }
  originalTotalPrice {
    amount
    currencyCode
  }
  phone
  processedAt
  shippingAddress {
    address1
    address2
    city
    company
    country
    countryCodeV2
    firstName
    formattedArea
    id
    lastName
    latitude
    longitude
    name
    phone
    province
    provinceCode
    zip
  }
  shippingDiscountAllocations {
    allocatedAmount {
      amount
      currencyCode
    }
    discountApplication {
      allocationMethod
      targetSelection
      targetType
      value {
        ... on MoneyV2 {
          amount
          currencyCode
        }
        ... on PricingPercentageValue {
          percentage
        }
      }
    }
  }
  statusUrl
  subtotalPrice {
    amount
    currencyCode
  }
  successfulFulfillments(first: 24) {
    trackingCompany
    trackingInfo(first: 24) {
      number
      url
    }
    fulfillmentLineItems(first: 24) {
      nodes {
        lineItem {
          currentQuantity
          customAttributes {
            key
            value
          }
          discountAllocations {
            allocatedAmount {
              amount
              currencyCode
            }
            discountApplication {
              allocationMethod
              targetSelection
              targetType
              value {
                ... on MoneyV2 {
                  amount
                  currencyCode
                }
                ... on PricingPercentageValue {
                  percentage
                }
              }
            }
          }
          discountedTotalPrice {
            amount
            currencyCode
          }
          originalTotalPrice {
            amount
            currencyCode
          }
          quantity
          title
          variant {
            availableForSale
            barcode
            compareAtPrice {
              amount
              currencyCode
            }
            currentlyNotInStock
            id
            image {
              altText
                height
                id
                url
                
                width
            }
            price {
              amount
              currencyCode
            }
            product {
              availableForSale
              compareAtPriceRange {
                maxVariantPrice {
                  amount
                  currencyCode
                }
                minVariantPrice {
                  amount
                  currencyCode
                }
              }
              createdAt
              description(truncateAt: 10)
              descriptionHtml
              images (first: 10) {
                nodes {
                  altText
                  height
                  id
                  url(transform: {maxHeight: 768 maxWidth: 768})
                  width
                }
              }
              featuredImage {
                altText
                height
                id
                url(transform: {maxHeight: 768 maxWidth: 768})
                width
              }
              handle
              id
              isGiftCard
              onlineStoreUrl
              options(first: 8)  {
                
                  id
                  name
                  values
                
              }
              priceRange {
                maxVariantPrice {
                  amount
                  currencyCode
                }
                minVariantPrice {
                  amount
                  currencyCode
                }
              }
              productType
              publishedAt
              requiresSellingPlan
              seo {
                description
                title
              }
              tags
              title
              totalInventory
              updatedAt
            }
            quantityAvailable
            requiresShipping
            selectedOptions {
              name
              value
            }
            sku
            title
            unitPrice {
              amount
              currencyCode
            }
            unitPriceMeasurement {
              measuredType
              quantityUnit
              quantityValue
              referenceUnit
              referenceValue
            }
            weight
            weightUnit
            sellingPlanAllocations(first: 24) {
              nodes {
                checkoutChargeAmount{
                  amount
                  currencyCode
                }
                priceAdjustments {
                  compareAtPrice{
                    amount
                    currencyCode
                  }
                  perDeliveryPrice{
                    amount
                    currencyCode
                  }
                  price{
                    amount
                    currencyCode
                  }
                  unitPrice {
                    amount
                    currencyCode
                  }
                }
                remainingBalanceChargeAmount{
                  amount
                  currencyCode
                }
                sellingPlan {
                  checkoutCharge {
                    type
                    value
                  }
                  description
                  id
                  name
                  options {
                    name
                    value
                  }
                  priceAdjustments {
                    adjustmentValue
                    orderCount
                  }
                  recurringDeliveries
                }
              }
            }
            storeAvailability(first: 24) {
              nodes {
                available
                location {
                  address {
                    address1
                    address2
                    city
                    country
                    
                    latitude
                    longitude
                    phone
                    province
                    provinceCode
                    zip
                  }
                }
                pickUpTime
              }
            }
          }
        }
        quantity
      }
    }
  }
  
  totalPrice {
    amount
    currencyCode
  }
  totalRefunded {
    amount
    currencyCode
  }
  totalShippingPrice {
    amount
    currencyCode
  }
  totalTax {
    amount
    currencyCode
  }
  discountApplications(first:24) {
    nodes {
      allocationMethod
      targetSelection
      targetType
      value {
        ... on MoneyV2 {
          amount
          currencyCode
        }
        ... on PricingPercentageValue {
          percentage
        }
      }
    }
  }
  lineItems(first:24) {
    nodes {
      currentQuantity
      customAttributes {
        key
        value
      }
      discountAllocations {
        allocatedAmount {
          amount
          currencyCode
        }
        discountApplication {
          allocationMethod
          targetSelection
          targetType
          value {
            ... on MoneyV2 {
              amount
              currencyCode
            }
            ... on PricingPercentageValue {
              percentage
            }
          }
        }
      }
      discountedTotalPrice {
        amount
        currencyCode
      }
      originalTotalPrice {
        amount
        currencyCode
      }
      quantity
      title
      variant {
        availableForSale
        barcode
        compareAtPrice {
          amount
          currencyCode
        }
        currentlyNotInStock
        id
        image {
          altText
            height
            id
            url
            
            width
        }
        price {
          amount
          currencyCode
        }
        product {
          availableForSale
          compareAtPriceRange {
            maxVariantPrice {
              amount
              currencyCode
            }
            minVariantPrice {
              amount
              currencyCode
            }
          }
          createdAt
          description(truncateAt: 10)
          descriptionHtml
          images (first: 10) {
            nodes {
              altText
              height
              id
              url(transform: {maxWidth: 360, maxHeight: 360})
              width
            }
          }
          featuredImage {
            altText
            height
            id
            url(transform: {maxWidth: 360, maxHeight: 360})
            width
          }
          handle
          id
          isGiftCard
          onlineStoreUrl
          options(first: 8)  {
            
              id
              name
              values
            
          }
          priceRange {
            maxVariantPrice {
              amount
              currencyCode
            }
            minVariantPrice {
              amount
              currencyCode
            }
          }
          productType
          publishedAt
          requiresSellingPlan
          seo {
            description
            title
          }
          tags
          title
          totalInventory
          updatedAt
        }
        quantityAvailable
        requiresShipping
        selectedOptions {
          name
          value
        }
        sku
        title
        unitPrice {
          amount
          currencyCode
        }
        unitPriceMeasurement {
          measuredType
          quantityUnit
          quantityValue
          referenceUnit
          referenceValue
        }
        weight
        weightUnit
        sellingPlanAllocations(first: 24) {
          nodes {
            checkoutChargeAmount{
              amount
              currencyCode
            }
            priceAdjustments {
              compareAtPrice{
                amount
                currencyCode
              }
              perDeliveryPrice{
                amount
                currencyCode
              }
              price{
                amount
                currencyCode
              }
              unitPrice {
                amount
                currencyCode
              }
            }
            remainingBalanceChargeAmount{
              amount
              currencyCode
            }
            sellingPlan {
              checkoutCharge {
                type
                value
              }
              description
              id
              name
              options {
                name
                value
              }
              priceAdjustments {
                adjustmentValue
                orderCount
              }
              recurringDeliveries
            }
          }
        }
        storeAvailability(first: 24) {
          nodes {
            available
            location {
              address {
                address1
                address2
                city
                country
                
                latitude
                longitude
                phone
                province
                provinceCode
                zip
              }
            }
            pickUpTime
          }
        }
      }
    }
  }
`;
