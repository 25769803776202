// import { graphql } from 'gatsby';
import queryFormat, { mutationFormat } from '../helpers/queryFormat';
import { AccountLoginQuery } from '../constants/accountQuery';

const storeDomain =
  process.env.GATSBY_IS_INT === 'true'
    ? process.env.GATSBY_INT_SHOPIFY_STORE_DOMAIN
    : process.env.GATSBY_SHOPIFY_STORE_DOMAIN;
const storeApiToken =
  process.env.GATSBY_IS_INT === 'true'
    ? process.env.GATSBY_INT_SHOPIFY_STOREFRONT_API_TOKEN
    : process.env.GATSBY_SHOPIFY_STOREFRONT_API_TOKEN;

export async function glStoreFrontFetch({
  query,
  setFetchData = () => {},
  setLoading = () => {},
  token,
  customStoreDomain = false,
  customStoreApiToken = false,
  // test = false,
}) {
  // console.log('==> test', test);
  setLoading(true);
  return new Promise((resolve, reject) => {
    const accessToken = token
      ? {
          'X-Shopify-Customer-Access-Token': token,
        }
      : {};
    fetch(`https://${customStoreDomain || storeDomain}/api/2024-04/graphql`, {
      method: 'POST',
      headers: {
        // Authorization: `Bearer %ACCESS_TOKEN%`,
        'content-type': 'application/json',
        Accept: 'application/json',
        'X-Shopify-Storefront-Access-Token':
          customStoreApiToken || storeApiToken,
        // ...accessToken,
      },
      // body: JSON.stringify({ productQuery }),
      body: query,
    })
      .then((response) => response.json())
      .then((result) => {
        console.log('===> result', result, query);
        if (result.errors) {
          const data = result.data || {};
          setFetchData({ errors: result.errors, ...data });
          resolve({ errors: result.errors, ...data });
          return;
        }
        setFetchData(result.data);
        setLoading(false);
        resolve(result.data);
      })
      .catch((e) => {
        console.log('===> result e', e);
        setFetchData({
          errors: e,
          // ...result.data,
        });
        setLoading(false);
        reject({
          errors: e,
        });
      });
  });
}

export async function getUserInfoByToken(token) {
  const result = await glStoreFrontFetch({
    query: queryFormat({
      query: AccountLoginQuery,
      variables: {
        customerAccessToken: token,
      },
    }),
  });
  return result;
}

// https://joucos.myshopify.com/admin/api/2024-04/countries.json

export async function apiFetch({
  url,
  query,
  setFetchData = () => {},
  setLoading = () => {},
  token,
  method = 'POST',
  // test = false,
}) {
  // console.log('==> test', test);
  setLoading(true);
  return new Promise((resolve, reject) => {
    console.log('==> ', {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',

        // ...accessToken,
      },
      body: JSON.stringify(query),
      // body: query,
    });
    fetch(url, {
      method,
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${token}`,
        'content-type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, DELETE',

        // ...accessToken,
      },
      body: JSON.stringify(query),
      // body: query,
    })
      .then((response) => response.json())
      .then((result) => {
        console.log('===> result', result);
        if (result.errors) {
          const data = result.data || {};
          setFetchData({ errors: result.errors });
          resolve({ errors: result.errors, ...data });
          return;
        }
        setFetchData(result.data);
        setLoading(false);
        resolve(result.data);
      })
      .catch((e) => {
        console.log('===> result e', e);
        setFetchData({
          errors: e,
        });
        setLoading(false);
        reject({
          errors: e,
        });
      });
  });
}

export async function timestampApi() {
  // timeapi.io/api/Time/current/zone?timeZone=Asia/Taipei
  return new Promise((resolve, reject) => {
    fetch(`https://timeapi.io/api/Time/current/zone?timeZone=Asia/Taipei`, {
      method: 'GET',
      // mode: 'no-cors',
      headers: {
        //   // Authorization: `Bearer %ACCESS_TOKEN%`,
        'content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        //   Accept: 'application/json',
        //   'X-Shopify-Storefront-Access-Token':
        //     process.env.GATSBY_SHOPIFY_STOREFRONT_API_TOKEN,
        //   // ...accessToken,
      },
      // // body: JSON.stringify({ productQuery }),
      // body: query,
    })
      .then((response) => {
        // console.log('==> response', response);
        resolve(response.json());
      })
      .catch((e) => {
        console.log(e);
      });
  });
}
