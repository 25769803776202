import React, { useState, useEffect, useContext, useRef } from 'react';
import update from 'immutability-helper';
import { Trans } from 'gatsby-plugin-react-i18next';
// import TransAni from '../../components/TransAni';
// import { AES, enc } from 'crypto-js';
import useStoreFrontAPI, { createQuery } from './useStoreFrontAPI';
import useStoreFrontQueryData from './useStoreFrontQueryData';
import queryFormat, { mutationFormat } from '../helpers/queryFormat';
import { glStoreFrontFetch } from '../apis-browser';
import {
  AccountLoginQuery,
  AccountLogoutMutation,
} from '../constants/accountQuery';

import {
  CartQuery,
  CartCreateMutation,
  CartCreateMutationInput,
  CartSelectdDeliveryOptionUpdateMutation,
  CartBuyerIdentityUpdateMutation,
  CartLinesAddMutation,
  CartLinesUpdateMutation,
  CartLinesRemoveMutation,

  // AccountLogoutMutation,
} from '../constants/cartQuery';

import {
  CheckoutCreateMutation,
  CheckoutQuery,
  CheckoutShippingAddressUpdateV2Mutation,
  CheckoutLineItemsUpdateMutation,
  CheckoutLineItemsAddMutation,
  CheckoutLineItemsRemoveMutation,
  CheckoutCustomerDisassociateV2Mutation,
} from '../constants/checkoutQuery';
import { isBrowser } from '../helpers/browser';
import RootContext from '../components/RootContext';

// require('dotenv').config();
// import useAdminAPI, { createQuery as adminCreateQuery } from './useAdminAPI';
// import { navigate } from 'gatsby';

// userInfo = {
//   id
//   firstName
//   lastName
//   phone
//   defaultAddress
//   displayName
//   email
//   lastIncompleteCheckout
//   numberOfOrders
//   phone
//   tags
//   updatedAt
//   addresses
//   orders
//   vinfo
// }

export default function useCheckout(user) {
  // const [setting, setSetting] = useState({ lang: 'EN', country: 'TW' });
  // const [loading, setLoading] = useState(true);

  // const { staticLocales, setting } = useContext(RootContext);

  const [cart, setCart] = useState({});
  const [showCart, setShowCart] = useState(false);
  const tempObject = useRef({});

  async function checkCart(
    user = {},
    country = '',
    language = '',
    setLoading = () => {},
    checkoutId = false
  ) {
    const cartId = checkoutId || localStorage.getItem('checkoutId');
    console.log('===> checkCart ID', cartId, user);
    // if (
    //   !tempObject.current.user ||
    //   tempObject.current.country ||
    //   tempObject.current.language
    // ) {
    // alert(1);
    tempObject.current = {
      user,
      country,
      language,
    };
    // }
    if (!cartId) {
    } else {
      try {
        const result = await glStoreFrontFetch({
          query: queryFormat({
            query: CheckoutQuery,
            variables: {
              id: cartId,
              country,
              language,
            },
          }),
          token: user.token?.accessToken,
        });
        console.log('===> checkCart ID 1', result, result?.node?.id);
        if (result?.node?.id && !result?.node?.order) {
          localStorage.setItem('checkoutId', result?.node?.id);
          setCart(result?.node || {});
        } else {
          // alert('gg');
          localStorage.removeItem('checkoutId');
        }
      } catch (e) {
        console.log('===> checkCart ID 2', e);
      }
    }
    setLoading(false);
  }

  async function createCart({
    useJSON,
    staticLocales = {},
    setting = {},
    pageContext = {},
    user = {},
    lines,
    metafields = [],
    attributes = [],
    note = '',
    country = '',
    language = '',
    setShowCart = () => {},
    setLoading = () => {},
    setPopupInfo = () => {},
    callback = () => {},
  }) {
    const shippingAddress = user.defaultAddress?.id
      ? {
          shippingAddress: {
            address1: user.defaultAddress.address1,
            address2: user.defaultAddress.address2,
            city: user.defaultAddress.city,
            company: user.defaultAddress.company,
            country: user.defaultAddress.country,
            firstName: user.defaultAddress.firstName,
            lastName: user.defaultAddress.lastName,
            phone: user.defaultAddress.phone,
            province: user.defaultAddress.province,
            zip: user.defaultAddress.zip,
          },
        }
      : {};

    const email = user.email ? { email: user.email } : {};

    const createCheckout = {
      // input: {
      allowPartialAddresses: true,
      buyerIdentity: {
        countryCode: country,
      },
      customAttributes: [
        {
          key: '',
          value: '',
        },
      ],
      // email: user.email || '',
      ...email,
      lineItems: lines,
      // lineItems: [
      //   {
      //     customAttributes: [
      //       {
      //         key: '',
      //         value: '',
      //       },
      //     ],
      //     quantity: 1,
      //     variantId: '',
      //   },
      // ],
      note: '',
      // presentmentCurrencyCode: 'TWD',
      ...shippingAddress,
      // },
    };

    try {
      setLoading(true);
      const result = await glStoreFrontFetch({
        query: mutationFormat({
          query: CheckoutCreateMutation,
          variables: {
            input: createCheckout,
            country,
            language,
          },
        }),
        token: user.token?.accessToken,
      });
      console.log(
        '===> cart',
        result,
        user,
        createCheckout,
        result?.checkoutCreate?.checkout,
        result?.checkoutCreate?.checkout?.id
      );
      if (
        !result?.checkoutCreate?.checkout?.id &&
        result?.checkoutCreate?.checkoutUserErrors &&
        result?.checkoutCreate?.checkoutUserErrors.length > 0
      ) {
        setPopupInfo({
          active: true,
          type: '',
          onDisable: (cb = () => {}) => {
            cb();
          },
          data: {
            title: useJSON ? (
              (staticLocales &&
                staticLocales[setting.country] &&
                staticLocales[setting.country]['Add Fail']) ||
              'Add Fail'
            ) : (
              <Trans>Add Fail</Trans>
            ),
            Component: () => (
              <>
                {result?.checkoutCreate?.checkoutUserErrors.map((error) => (
                  <div>
                    <p className="GSText s-13 text-center l-15">
                      {error.message}
                    </p>
                  </div>
                ))}
              </>
            ),
          },
        });
        setLoading(false);
        return;
      }
      console.log('===> result.errors', result.errors);

      if (result?.checkoutCreate?.checkout?.id) {
        console.log('===> cartA');
        localStorage.setItem(
          'checkoutId',
          result?.checkoutCreate?.checkout?.id
        );
        console.log('===> cartB');
        if (isBrowser()) {
          // document.querySelector('#nav').style.zIndex = 15;
        }
        setCart(result?.checkoutCreate?.checkout || {});
        console.log('===> cartC');
        setShowCart(true);
        checkCart(
          tempObject.current.user,
          tempObject.current.country,
          tempObject.current.language,
          setLoading,
          result?.checkoutCreate?.checkout?.id
        );
        // setLoading(false);
        callback(result?.checkoutCreate?.checkout || {});
      } else if (result.errors) {
        console.log('===> result.errors', result.errors);
        setPopupInfo({
          active: true,
          type: '',
          onDisable: (callback = () => {}) => {
            callback();
          },
          data: {
            title: useJSON ? (
              (staticLocales[pageContext.language] &&
                staticLocales[pageContext.language]['Add Fail']) ||
              'Add Fail'
            ) : (
              <Trans>Add Fail</Trans>
            ),
            Component: () => (
              <p className="GSText s-13 text-center l-15">
                {useJSON ? (
                  (staticLocales[pageContext.language] &&
                    staticLocales[pageContext.language][
                      'Oops! Something went wrong, or there may be too many users at the moment. Please try again later.'
                    ]) ||
                  'Oops! Something went wrong, or there may be too many users at the moment. Please try again later.'
                ) : (
                  <Trans>
                    Oops! Something went wrong, or there may be too many users
                    at the moment. Please try again later.
                  </Trans>
                )}
              </p>
            ),
          },
        });
        setLoading(false);
        // return;
      }
    } catch (e) {
      setLoading(false);
    }
  }

  function hasCart() {
    return cart?.id;
  }

  function updateCart({ checkout, setShowCart = () => {} }) {
    console.log('===> cartB1');
    if (isBrowser()) {
      // document.querySelector('#nav').style.zIndex = 15;
      console.log('===> cartB2');
    }
    localStorage.setItem('checkoutId', checkout?.id);
    console.log('===> cartB');
    setCart(checkout || {});
    setShowCart(true);
  }

  async function addItemToCart({
    useJSON,
    staticLocales = {},
    setting = {},
    item,
    lineItems,
    quantity,
    sellingPlanId,
    user,
    country,
    language,
    pageContext,
    setShowCart = () => {},
    setLoading = () => {},
    setPopupInfo = () => {},
    callback = () => {},
  }) {
    // const spi = sellingPlanId ? { sellingPlanId } : {};
    console.log('===> quantity', quantity);
    const lines = lineItems || [
      {
        // merchandiseId: item.id,
        // quantity
        variantId: item.id,
        quantity,
        // customAttributes: {
        //   key: 'match',
        //   value: 'test-la',
        // },
        // ...spi,
      },
    ];
    if (hasCart()) {
      setLoading(true);
      const result = await glStoreFrontFetch({
        query: mutationFormat({
          query: CheckoutLineItemsAddMutation,
          variables: {
            checkoutId: cart.id,
            lineItems: lines,
          },
        }),
      });
      if (
        !result?.checkoutLineItemsAdd?.checkout?.id &&
        result?.checkoutLineItemsAdd?.checkoutUserErrors &&
        result?.checkoutLineItemsAdd?.checkoutUserErrors.length > 0
      ) {
        setPopupInfo({
          active: true,
          type: '',
          onDisable: (cb = () => {}) => {
            cb();
          },
          data: {
            title: useJSON ? (
              (staticLocales &&
                staticLocales[pageContext.language] &&
                staticLocales[pageContext.language]['Add Fail']) ||
              'Add Fail'
            ) : (
              <Trans>Add Fail</Trans>
            ),
            Component: () => (
              <>
                {result?.checkoutLineItemsAdd?.checkoutUserErrors.map(
                  (error) => (
                    <div>
                      <p className="GSText s-13 text-center l-15">
                        {error.message}
                      </p>
                    </div>
                  )
                )}
              </>
            ),
          },
        });
        setLoading(false);
        return;
      }
      // if (result.errors) {
      //   setPopupInfo({
      //     active: true,
      //     type: '',
      //     onDisable: (callback = () => {}) => {
      //       callback();
      //     },
      //     data: {
      //       title: useJSON ? (
      //         (staticLocales[pageContext.language] &&
      //           staticLocales[pageContext.language]['Add Fail']) ||
      //         'Add Fail'
      //       ) : (
      //         <Trans>Add Fail</Trans>
      //       ),
      //       Component: () => (
      //         <p className="GSText s-13 text-center l-15">
      //           {useJSON ? (
      //             (staticLocales[pageContext.language] &&
      //               staticLocales[pageContext.language][
      //                 'Oops! Something went wrong, or there may be too many users at the moment. Please try again later.'
      //               ]) ||
      //             'Oops! Something went wrong, or there may be too many users at the moment. Please try again later.'
      //           ) : (
      //             <Trans>
      //               Oops! Something went wrong, or there may be too many users at the moment. Please try again later.
      //             </Trans>
      //           )}
      //         </p>
      //       ),
      //     },
      //   });
      //   setLoading(false);
      //   // return;
      // }
      if (result?.checkoutLineItemsAdd?.checkout?.id) {
        console.log('===> cartA');
        localStorage.setItem(
          'checkoutId',
          result?.checkoutLineItemsAdd?.checkout?.id
        );
        console.log('===> cartB');
        if (isBrowser()) {
          // document.querySelector('#nav').style.zIndex = 15;
          console.log('===> cartB2');
        }
        // setCart(result?.checkoutLineItemsAdd?.checkout || {});
        checkCart(
          tempObject.current.user,
          tempObject.current.country,
          tempObject.current.language,
          setLoading,
          result?.checkoutLineItemsAdd?.checkout?.id
        );
        setShowCart(true);

        // setLoading(false);
        callback(result?.checkoutLineItemsAdd?.checkout || {});
        console.log('===> cartC');
      } else if (result.errors) {
        setPopupInfo({
          active: true,
          type: '',
          onDisable: (callback = () => {}) => {
            callback();
          },
          data: {
            title: useJSON ? (
              (staticLocales[pageContext.language] &&
                staticLocales[pageContext.language]['Add Fail']) ||
              'Add Fail'
            ) : (
              <Trans>Add Fail</Trans>
            ),
            Component: () => (
              <p className="GSText s-13 text-center l-15">
                {useJSON ? (
                  (staticLocales[pageContext.language] &&
                    staticLocales[pageContext.language][
                      'Oops! Something went wrong, or there may be too many users at the moment. Please try again later.'
                    ]) ||
                  'Oops! Something went wrong, or there may be too many users at the moment. Please try again later.'
                ) : (
                  <Trans>
                    Oops! Something went wrong, or there may be too many users
                    at the moment. Please try again later.
                  </Trans>
                )}
              </p>
            ),
          },
        });
        setLoading(false);
        // return;
      }
    } else {
      createCart({
        useJSON,
        user,
        lines,
        country,
        language,
        pageContext,
        setShowCart,
        setLoading,
        callback,
        setPopupInfo,
      });
    }
  }

  async function updateCartLinesQuantity(checkoutLineItemsUpdate, setLoading) {
    console.log(
      '==> ppppppppp',
      cart.id === checkoutLineItemsUpdate.checkout.id,
      cart.id,
      checkoutLineItemsUpdate.checkout.id
    );
    if (cart.id === checkoutLineItemsUpdate.checkout.id) {
      // const linesEdges = [...cart.lineItems.nodes];
      // const edges = linesEdges.map((item, index) => {
      //   const node = { ...item.variant };
      //   node.quantity = checkoutLineItemsUpdate.checkout.lineItems.nodes[index].quantity;
      //   node.cost = checkoutLineItemsUpdate.checkout.lineItems.nodes[index].node.cost;
      //   node.discountAllocations =
      //     checkoutLineItemsUpdate.checkout.lineItems.nodes[index].node.discountAllocations;
      //   return {
      //     ...item,
      //     node,
      //   };
      // });
      // setCart(
      //   update(cart, {
      //     $set: checkoutLineItemsUpdate.checkout,
      //   })
      // );
      checkCart(
        tempObject.current.user,
        tempObject.current.country,
        tempObject.current.language,
        setLoading,
        cart.id
      );
    }
  }

  async function cartItemUpdate(lines, setLoading = () => {}) {
    if (cart.id) {
      setLoading(true);
      const result = await glStoreFrontFetch({
        query: mutationFormat({
          query: CheckoutLineItemsUpdateMutation,
          variables: {
            checkoutId: cart.id,
            lineItems: lines,
          },
        }),
        // setFetchData: setFetchResult,
        // test: true,
      });

      if (
        result.errors &&
        (!result ||
          !result.checkoutLineItemsUpdate ||
          !result.checkoutLineItemsUpdate.checkout)
      ) {
        setLoading(false);
        return;
      }
      if (
        result.checkoutLineItemsUpdate?.checkoutUserErrors &&
        result.checkoutLineItemsUpdate.checkoutUserErrors.length > 0 &&
        (!result.checkoutLineItemsUpdate ||
          !result.checkoutLineItemsUpdate.checkout)
      ) {
        console.log(result.checkoutLineItemsUpdate.checkoutUserErrors);
        // const errors = errorsParser(
        //   result.cartLinesUpdate.customerUserErrors,
        //   'reset'
        // );
        // console.log(
        //   errorsParser(result.cartLinesUpdate.customerUserErrors, 'reset')
        // );

        // if (errors.includes('無效URL')) {
        // }
        setLoading(false);
        return;
      }
      updateCartLinesQuantity(result.checkoutLineItemsUpdate, setLoading);
      // setLoading(false);
      console.log({
        ...result.checkoutLineItemsUpdate.checkout,
      });
    }
  }

  async function modifyItemQuantity({
    useJSON = false,
    staticLocales = {},
    setting = {},
    item,
    quantity,
    action,
    setLoading = () => {},
    setPopupInfo = () => {},
  }) {
    console.log('==> item, quantity, action', item, quantity, action);
    let lines = cart?.lineItems?.nodes || [];
    if (
      quantity !== null &&
      quantity !== undefined &&
      typeof quantity === 'number'
    ) {
      if (quantity > item.variant.quantityAvailable) {
        return;
      }
      lines = lines?.map((line) => {
        console.log('===> line', line, item);
        if (item.variant.id === line.variant.id) {
          return {
            id: line.variant.id,
            variantId: line.variant.id,
            quantity,
          };
        }
        return {
          id: line.id,
          variantId: line.variant.id,
          quantity: line.quantity,
        };
      });
    } else if (action) {
      // let lines = cart?.lines?.edges || [];
      let customizedTimestamp = item.customAttributes.find(
        (attributes) => attributes.key === 'Customized'
      );
      console.log('===> customizedTimestamp', customizedTimestamp, item);
      customizedTimestamp = customizedTimestamp?.value;
      if (action === 'add') {
        console.log('===> line add1');
        if (item.quantity + 1 > item.variant.quantityAvailable) {
          // 超過可購買數量
          setPopupInfo({
            active: true,
            type: '',
            onDisable: (callback = () => {}) => {
              callback();
            },
            data: {
              title: useJSON ? (
                (staticLocales &&
                  staticLocales[setting.country] &&
                  staticLocales[setting.country]['Add Fail']) ||
                'Add Fail'
              ) : (
                <Trans>Add Fail</Trans>
              ),
              Component: () => (
                <p className="GSText s-13 text-center l-15">
                  {useJSON ? (
                    (staticLocales &&
                      staticLocales[setting.country] &&
                      staticLocales[setting.country][
                        'Exceeds available quantity.'
                      ]) ||
                    'Exceeds available quantity.'
                  ) : (
                    <Trans>Exceeds available quantity.</Trans>
                  )}
                </p>
              ),
            },
          });
          return;
        }
        console.log('===> line add2');
        if (customizedTimestamp) {
          lines = lines?.map((line) => {
            console.log('===> line remove', line, item);
            // let hasInclude = false;
            const hasInclude = line.customAttributes?.find(
              (attributes) => attributes.value === customizedTimestamp
            );
            if (hasInclude) {
              console.log('gooo1!', {
                id: line.id,
                variantId: line.variant.id,
                quantity: line.quantity + 1,
                customAttributes: line.customAttributes || [],
              });
              return {
                id: line.id,
                variantId: line.variant.id,
                quantity: line.quantity + 1,
                customAttributes: line.customAttributes || [],
              };
            }
            return {
              id: line.id,
              variantId: line.variant.id,
              quantity: line.quantity,
              customAttributes: line.customAttributes || [],
            };
          });
        } else {
          lines = lines?.map((line) => {
            console.log('===> line add', line, item);
            if (line.customAttributes?.length > 0) {
              return {
                id: line.id,
                variantId: line.variant.id,
                quantity: line.quantity,
                customAttributes: line.customAttributes || [],
              };
            }
            if (item.variant.id === line.variant.id) {
              console.log('gooo!', {
                id: line.variant.id,
                variantId: line.variant.id,
                quantity: line.quantity + 1,
                customAttributes: line.customAttributes || [],
              });
              return {
                id: line.id,
                variantId: line.variant.id,
                quantity: line.quantity + 1,
                customAttributes: line.customAttributes || [],
              };
            }
            return {
              id: line.id,
              variantId: line.variant.id,
              quantity: line.quantity,
              customAttributes: line.customAttributes || [],
            };
          });
        }
      } else if (action === 'remove') {
        if (item.quantity - 1 < 0) {
          return;
        }
        if (customizedTimestamp) {
          lines = lines?.map((line) => {
            console.log('===> line remove', line, item);
            // let hasInclude = false;
            const hasInclude = line.customAttributes?.find(
              (attributes) => attributes.value === customizedTimestamp
            );
            if (hasInclude) {
              console.log('gooo1!', {
                id: line.id,
                variantId: line.variant.id,
                quantity: line.quantity - 1,
                customAttributes: line.customAttributes || [],
              });
              return {
                id: line.id,
                variantId: line.variant.id,
                quantity: line.quantity - 1,
                customAttributes: line.customAttributes || [],
              };
            }
            return {
              id: line.id,
              variantId: line.variant.id,
              quantity: line.quantity,
              customAttributes: line.customAttributes || [],
            };
          });
        } else {
          lines = lines?.map((line) => {
            console.log('===> line remove', line, item);
            if (line.customAttributes?.length > 0) {
              return {
                id: line.id,
                variantId: line.variant.id,
                quantity: line.quantity,
                customAttributes: line.customAttributes || [],
              };
            }
            if (item.variant.id === line.variant.id) {
              console.log('gooo1!', {
                id: line.id,
                variantId: line.variant.id,
                quantity: line.quantity - 1,
                customAttributes: line.customAttributes || [],
              });
              return {
                id: line.id,
                variantId: line.variant.id,
                quantity: line.quantity - 1,
                customAttributes: line.customAttributes || [],
              };
            }
            return {
              id: line.id,
              variantId: line.variant.id,
              quantity: line.quantity,
              customAttributes: line.customAttributes || [],
            };
          });
        }
      }
    }
    console.log('===> lines', lines);
    cartItemUpdate(lines, setLoading);
  }

  async function removeCartItem({
    useJSON = false,
    staticLocales = {},
    setting = {},
    item,
    itemId,
    setLoading = () => {},
    setPopupInfo = () => {},
  }) {
    if (cart.id) {
      setLoading(true);
      const customizedId = [];
      if (item.customAttributes.length > 0) {
        let customizedTimestamp = item.customAttributes.find(
          (attributes) => attributes.key === 'Customized'
        );
        customizedTimestamp = customizedTimestamp?.value;
        const lines = cart?.lineItems?.nodes || [];
        for (let i = 0; i <= lines.length - 1; i++) {
          const attributes = lines[i].customAttributes || [];
          for (let j = 0; j <= attributes.length - 1; j++) {
            if (attributes[j].value === customizedTimestamp) {
              customizedId.push(lines[i].id);
            }
          }
        }
      }

      const result = await glStoreFrontFetch({
        query: mutationFormat({
          query: CheckoutLineItemsRemoveMutation,
          variables: {
            checkoutId: cart.id,
            lineItemIds: [itemId, ...customizedId],
          },
        }),
        // setFetchData: setFetchResult,
        // test: true,
      });
      // if (result.errors) {
      //   return;
      // }
      if (
        result.checkoutLineItemsRemove?.checkoutUserErrors &&
        result.checkoutLineItemsRemove.checkoutUserErrors.length > 0
      ) {
        console.log(
          '==> result.checkoutLineItemsRemove.checkoutUserErrors',
          result.checkoutLineItemsRemove.checkoutUserErrors,
          result
        );
        setPopupInfo({
          active: true,
          type: '',
          onDisable: (cb = () => {}) => {
            cb();
          },
          data: {
            title: useJSON ? (
              (staticLocales &&
                staticLocales[setting.country] &&
                staticLocales[setting.country]['Add Fail']) ||
              'Add Fail'
            ) : (
              <Trans>Add Fail</Trans>
            ),
            Component: () => (
              <>
                {result?.checkoutLineItemsAdd?.checkoutUserErrors.map(
                  (error) => (
                    <div>
                      <p className="GSText s-13 text-center l-15">
                        {error.message}
                      </p>
                    </div>
                  )
                )}
              </>
            ),
          },
        });

        if (result.checkoutLineItemsRemove.checkout) {
          // setCart(result.checkoutLineItemsRemove.checkout);
          checkCart(
            tempObject.current.user,
            tempObject.current.country,
            tempObject.current.language,
            setLoading,
            result.checkoutLineItemsRemove.checkout.id
          );
        }
        // setLoading(false);

        // const errors = errorsParser(
        //   result.cartLinesUpdate.customerUserErrors,
        //   'reset'
        // );
        // console.log(
        //   errorsParser(result.cartLinesUpdate.customerUserErrors, 'reset')
        // );

        // if (errors.includes('無效URL')) {
        // }
        return;
      }
      // updateCartLinesQuantity(result.cartLinesUpdate);

      // setCart(result.checkoutLineItemsRemove.checkout);
      console.log({
        ...result.checkoutLineItemsRemove.checkout,
      });
      // setLoading(false);

      checkCart(
        tempObject.current.user,
        tempObject.current.country,
        tempObject.current.language,
        setLoading,
        result.checkoutLineItemsRemove.checkout.id
      );
    }
  }

  useEffect(() => {
    async function updateSelectedDeliveryOptions() {
      console.log('===> cart.id && user && user.id', cart.id, user, user.id);
      if (cart.id && user && user.id && user.defaultAddress?.id) {
        const shippingAddress = {
          address1: user.defaultAddress.address1,
          address2: user.defaultAddress.address2,
          city: user.defaultAddress.city,
          company: user.defaultAddress.company,
          country: user.defaultAddress.country,
          firstName: user.defaultAddress.firstName,
          lastName: user.defaultAddress.lastName,
          phone: user.defaultAddress.phone,
          province: user.defaultAddress.province,
          zip: user.defaultAddress.zip,
        };
        const result = await glStoreFrontFetch({
          query: mutationFormat({
            query: CheckoutShippingAddressUpdateV2Mutation,
            variables: {
              checkoutId: cart.id,
              shippingAddress,
            },
          }),
          token: user.token?.accessToken,
        });
        console.log(
          '===> cart',
          result,
          user,
          // createCheckout,
          result?.checkoutShippingAddressUpdateV2?.checkout,
          result?.checkoutShippingAddressUpdateV2?.checkout?.id
        );
        if (
          (result?.checkoutShippingAddressUpdateV2?.checkoutUserErrors &&
            result?.checkoutShippingAddressUpdateV2?.checkoutUserErrors.length >
              0) ||
          result.errors
        ) {
          return;
        }
        if (result?.checkoutShippingAddressUpdateV2?.checkout?.id) {
          console.log('===> cartA');
          localStorage.setItem(
            'checkoutId',
            result?.checkoutShippingAddressUpdateV2?.checkout?.id
          );
          console.log('===> cartB');
          setCart(result?.checkoutShippingAddressUpdateV2?.checkout || {});
          console.log('===> cartC');
        }

        console.log('==> result updateSelectedDeliveryOptions', result);
      } else if (cart.id && (!user || !user.id)) {
        await glStoreFrontFetch({
          query: mutationFormat({
            query: CheckoutCustomerDisassociateV2Mutation,
            variables: {
              checkoutId: cart.id,
            },
          }),
          token: user.token?.accessToken,
        });
      }
    }
    updateSelectedDeliveryOptions();
    if (cart.id && (!user || !user.id)) {
      localStorage.removeItem('checkoutId');
      setCart({});
    }
  }, [user]);

  // useEffect(() => {
  //   const result = glStoreFrontFetch({
  //     query: queryFormat({
  //       query: CheckoutQuery,
  //       variables: {
  //         id:
  //           'gid://shopify/Checkout/dd10d0c814644c96a414d632ec1b36f7?key=c6b1799b7230edc588e6bcab03cddb52',
  //       },
  //     }),
  //   });
  // }, []);

  return {
    cart,
    checkCart,
    addItemToCart,
    updateCartLinesQuantity,
    modifyItemQuantity,
    removeCartItem,

    showCart,
    setShowCart,
    updateCart,
    // user,
    // updateUser,
    // loginCheck,
    // logout,
    // // loading,
    // vipSetting,
    // fetchUserInfoByToken,
  };
}
