import React, { useEffect, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { isBrowser } from '../helpers/browser';

export default function Logo(props) {
  const { style = {}, className = '', color = 'black', animation } = props;
  const path = useRef({});
  const cursor = useRef(null);
  // if (isBrowser()) {
  // alert(color)
  // }
  useEffect(() => {
    console.log('===> path', path);
    if (animation === 'typing') {
      let left = 0;
      Object.values(path.current).map((item, index) => {
        setTimeout(() => {
          if (index === 0) {
            item.style.fill = 'white';
            left += item.getBoundingClientRect().width + 10;
            cursor.current.style.transform = `translateX(${
              left
              // path.current[index - 1].getBoundingClientRect().y + 10
            }px)`;
          }
          if (index >= 1) {
            cursor.current.style.transform = `translateX(${
              left + path.current[index].getBoundingClientRect().width + 10
              // path.current[index - 1].getBoundingClientRect().y + 10
            }px)`;
            item.style.fill = 'white';
            left += path.current[index].getBoundingClientRect().width + 10;
          }
          if (index === 5) {
            cursor.current.classList.add('breath');
          }
        }, 150 * index);
        // console.log(item);

        // console.log(item.getBoundingClientRect());
      });
    }
  }, []);
  // return (
  //   <StaticImage style={style} src="../images/jslogo.svg" alt="A dinosaur" />
  // );
  return (
    <div className={className} style={{ position: 'relative', ...style }}>
      <svg
        style={{ position: 'absolute' }}
        // width="237"
        // height="44"
        width="100%"
        height="100%"
        viewBox="0 0 237 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.6683 1.29419V13.6802V14.3669V32.6686C15.6683 35.8113 14.9047 38.0561 13.3773 39.4294C11.8237 40.8027 9.875 41.5158 7.47867 41.5158C5.16133 41.5158 2.844 40.9083 0.553 39.6935L0 41.1196C1.106 42.0175 2.42267 42.7042 3.97633 43.2324C5.53 43.7341 7.13633 43.9982 8.82167 43.9982C11.3233 43.9982 13.6143 43.4436 15.6683 42.3609C17.7223 41.2517 19.3287 39.6143 20.54 37.4223C21.725 35.2303 22.3307 32.5101 22.3307 29.3146V14.3669V13.6802V9.00574C22.3307 5.75738 23.0417 3.8559 24.6217 2.21852L24.3847 1.29419H15.6683Z"
          fill={color}
          ref={(p) => (path.current[0] = p)}
        >
          {/* <animate
            id="project_anim1"
            attributeName="fill"
            from="#black"
            to={color}
            begin="0s"
            dur="0.3s"
            fill="freeze"
            // repeatCount="indefinite"
          /> */}
        </path>
        <path
          d="M63.3324 2.87863C59.9354 0.950739 56.0644 0 51.7457 0C51.6931 0 51.6404 0 51.5877 0C51.5614 0 51.5351 0 51.4824 0C49.0597 0 47.1901 0.264094 45.6627 0.713055C43.7931 1.24124 42.3974 2.03353 40.9227 2.85222C37.5784 4.7537 34.5237 8.21333 32.6277 11.5409C30.7317 14.8685 30.2051 18.2225 30.2051 22.4216C30.2051 26.6207 31.1531 30.3708 33.0491 33.6984C34.9451 37.026 37.5521 39.6934 40.9227 41.542C44.1881 43.3115 47.7167 43.7868 49.8234 43.9189C50.7977 43.9717 51.4298 43.9717 51.6404 43.9717C51.6668 43.9717 51.6667 43.9717 51.6931 43.9717C51.7194 43.9717 51.7458 43.9717 51.7721 43.9717C56.0908 43.9717 59.9354 43.021 63.3324 41.1459C66.7294 39.2444 68.9678 36.2337 70.8638 32.9062C72.7598 29.5786 73.2864 25.4059 73.2864 21.2068C73.2864 17.0077 72.3384 13.2575 70.4424 9.92995C68.5201 6.57595 66.7294 4.78011 63.3324 2.87863ZM56.1961 41.2515C47.9801 42.9681 39.8694 37.5806 37.5521 26.8848C35.2347 16.189 39.6061 4.22551 47.8221 2.5089C56.0381 0.792283 64.9651 9.98277 67.2824 20.6786C69.5998 31.348 64.4121 39.5349 56.1961 41.2515Z"
          fill={color}
          ref={(p) => (path.current[1] = p)}
        >
          {/* <animate
            id="project_anim1"
            attributeName="fill"
            from="#black"
            to={color}
            begin="0s"
            dur="0.3s"
            fill="freeze"
            // repeatCount="indefinite"
          /> */}
        </path>
        <path
          d="M110.415 1.29419V13.4689V14.3669V29.2354C110.415 33.2232 109.388 36.3131 107.334 38.5051C105.28 40.6971 102.462 41.8063 98.9073 41.8063C96.4583 41.8063 94.378 41.3045 92.64 40.2745C90.902 39.271 89.5853 37.792 88.7163 35.917C87.8473 34.0155 87.3997 31.7971 87.3997 29.2354V14.3669V12.3333V1.29419H78.7097L78.4727 2.21852C80.0527 3.8559 80.7637 5.75738 80.7637 9.00574V12.3333V14.3669V25.855C80.7637 29.6315 81.4483 32.8799 82.8177 35.5736C84.2133 38.2674 86.2147 40.3537 88.848 41.8063C91.4813 43.2588 94.2463 43.9718 99.039 43.9718C104.622 43.9718 108.493 41.7006 110.52 39.5615C112.443 37.5279 114.365 34.8342 114.365 28.179V14.3669V13.4689V9.00574C114.365 5.75738 115.076 3.8559 116.656 2.21852L116.419 1.29419H110.415Z"
          fill={color}
          ref={(p) => (path.current[2] = p)}
        >
          {/* <animate
            id="project_anim1"
            attributeName="fill"
            from="#black"
            to={color}
            begin="0s"
            dur="0.3s"
            fill="freeze"
            // repeatCount="indefinite"
          /> */}
        </path>
        <path
          d="M152.023 39.0595C150.364 40.3272 148.942 40.8818 148.152 41.1195C147.705 41.2251 147.283 41.3308 146.809 41.41C145.098 41.7005 143.386 41.6741 141.727 41.3308C141.49 41.2779 141.253 41.2251 141.016 41.1723C135.223 39.6934 130.351 34.4379 128.85 26.251C127.033 16.321 130.799 5.5988 137.724 2.85222C143.755 0.369732 151.207 6.07417 154.578 11.6202L154.999 11.5145V4.01423C155.052 4.04064 155.078 4.06705 154.999 4.01423C152.05 1.74302 146.836 0 142.754 0C137.382 0 134.854 1.34688 132.194 2.87863C128.85 4.78011 125.795 8.23974 123.899 11.5673C122.003 14.8949 121.477 18.2489 121.477 22.448C121.477 26.6471 122.425 30.3973 124.321 33.7248C126.217 37.0524 128.824 39.7198 132.194 41.5684C136.961 44.1566 142.306 43.9981 142.886 43.9981C146.888 43.9981 152.813 42.572 156.368 38.1352L156.816 33.8569L156.526 33.7513C155.052 35.9432 153.393 38.0296 152.023 39.0595Z"
          fill={color}
          ref={(p) => (path.current[3] = p)}
        >
          {/* <animate
            id="project_anim1"
            attributeName="fill"
            from="#black"
            to={color}
            begin="0s"
            dur="0.3s"
            fill="freeze"
            // repeatCount="indefinite"
          /> */}
        </path>
        <path
          d="M194.418 2.87863C191.021 0.950739 187.15 0 182.832 0C182.779 0 182.726 0 182.674 0C182.647 0 182.621 0 182.568 0C180.119 0 178.276 0.264094 176.749 0.713055C174.879 1.24124 173.483 2.03353 172.009 2.85222C168.664 4.7537 165.61 8.21333 163.714 11.5409C161.818 14.8685 161.291 18.2225 161.291 22.4216C161.291 26.6207 162.239 30.3708 164.135 33.6984C166.031 37.026 168.638 39.6934 172.009 41.542C175.274 43.3115 178.803 43.7868 180.909 43.9189C181.884 43.9717 182.516 43.9717 182.726 43.9717C182.753 43.9717 182.753 43.9717 182.779 43.9717C182.805 43.9717 182.832 43.9717 182.858 43.9717C187.177 43.9717 191.021 43.021 194.418 41.1459C197.815 39.2444 200.054 36.2337 201.95 32.9062C203.846 29.5786 204.372 25.4059 204.372 21.2068C204.372 17.0077 203.424 13.2575 201.528 9.92995C199.58 6.57595 197.815 4.78011 194.418 2.87863ZM187.282 41.2515C179.066 42.9681 170.955 37.5806 168.638 26.8848C166.321 16.189 170.692 4.22551 178.908 2.5089C187.124 0.792283 196.051 9.98277 198.368 20.6786C200.659 31.348 195.498 39.5349 187.282 41.2515Z"
          fill={color}
          ref={(p) => (path.current[4] = p)}
        >
          {/* <animate
            id="project_anim1"
            attributeName="fill"
            from="#black"
            to={color}
            begin="0s"
            dur="0.3s"
            fill="freeze"
            repeatCount="1"
          /> */}
        </path>
        <path
          d="M234.841 24.9833C233.419 23.1611 231.286 21.5765 228.468 20.256L221.885 17.1397C221.121 16.77 220.41 16.4003 219.752 16.0041C219.015 15.5552 218.356 15.1062 217.83 14.6572C217.487 14.3403 217.171 13.997 216.934 13.6801C216.118 12.5445 215.697 11.5409 215.697 9.92994C215.697 8.63588 215.96 7.50028 216.434 6.52313C216.855 5.73085 217.356 5.07061 217.856 4.54242C219.015 3.40682 220.542 2.69376 222.043 2.35044C222.122 2.32403 222.175 2.32403 222.254 2.32403C222.464 2.29762 222.649 2.27121 222.78 2.27121C222.833 2.27121 222.886 2.27121 222.938 2.27121C222.965 2.27121 223.017 2.27121 223.044 2.27121C223.07 2.27121 223.096 2.27121 223.149 2.27121C227.81 2.56171 232.392 6.33826 235.526 11.6466L235.921 11.5409V4.01423C235.947 4.04064 236 4.06705 235.921 4.01423C233.077 1.74302 228.389 0 224.413 0C223.57 0 222.412 0.105638 222.412 0.105638C216.592 0.739464 213.616 4.27833 212.537 5.94212C211.352 7.76437 210.746 9.7979 210.746 11.9899C210.746 14.3931 211.378 16.5587 212.668 18.4602C213.959 20.3881 215.934 21.9726 218.593 23.2403L224.992 26.3038C226.019 26.7792 226.915 27.2809 227.678 27.7563C227.705 27.7827 227.731 27.7827 227.757 27.8091C228.758 28.4694 229.522 29.1296 230.075 29.7898C230.549 30.3708 230.996 30.9783 231.312 31.6121C231.549 32.1403 231.681 32.6421 231.786 33.1174C231.813 33.3551 231.839 33.5928 231.839 33.8305C231.839 35.1245 231.628 36.2601 231.233 37.2373C229.653 40.5385 227.178 41.4364 225.861 41.6741C225.545 41.7269 225.229 41.7533 224.94 41.7533C219.963 41.7533 215.012 37.8711 211.694 32.3516L211.273 32.4572V39.9575C211.194 39.9046 211.246 39.9311 211.273 39.9575C214.196 42.2287 219.015 43.9453 223.07 43.9453C223.518 43.9453 223.965 43.9189 224.413 43.8925L225.124 43.8397C226.809 43.7076 228.547 43.1002 230.075 42.3871C232.234 41.3572 233.919 39.8782 235.157 37.9768C236.395 36.0753 237 33.9361 237 31.5329C237 28.9976 236.289 26.8056 234.841 24.9833Z"
          // fill={color}
          fill={color}
          ref={(p) => (path.current[5] = p)}
        >
          {/* <animate
            id="project_anim1"
            attributeName="fill"
            from="#black"
            to={color}
            begin="0s"
            dur="0.3s"
            fill="freeze"
            // repeatCount="indefinite"
          /> */}
        </path>
      </svg>
      <div style={{ paddingBottom: '18.565%', width: '100%' }} />
      {animation === 'typing' && (
        <div
          ref={cursor}
          // className="breath"
          style={{
            position: 'absolute',
            top: '-10px',
            bottom: '-10px',
            width: '2px',
            background: 'white',
            left: 0,
          }}
        />
      )}
    </div>
  );
}
