import React, { useState, useEffect } from 'react';
import update from 'immutability-helper';
import { AES, enc } from 'crypto-js';
import useStoreFrontAPI, { createQuery } from './useStoreFrontAPI';
import useStoreFrontQueryData from './useStoreFrontQueryData';
import queryFormat, { mutationFormat } from '../helpers/queryFormat';
import { glStoreFrontFetch } from '../apis-browser';
import {
  AccountLoginQuery,
  AccountLogoutMutation,
} from '../constants/accountQuery';

import { CheckoutCustomerAssociateV2Mutation } from '../constants/checkoutQuery';

// require('dotenv').config();
// import useAdminAPI, { createQuery as adminCreateQuery } from './useAdminAPI';
// import { navigate } from 'gatsby';

// userInfo = {
//   id
//   firstName
//   lastName
//   phone
//   defaultAddress
//   displayName
//   email
//   lastIncompleteCheckout
//   numberOfOrders
//   phone
//   tags
//   updatedAt
//   addresses
//   orders
//   vinfo
// }

export default function useUserInfo() {
  // const [setting, setSetting] = useState({ lang: 'EN', country: 'TW' });
  // const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});
  const [vipTags, setVipTags] = useState([]);
  const [isVip, setIsVip] = useState(null);
  const [isAbnormal, setIsAbnormal] = useState(false);
  const [hasConcerns, setHasConcerns] = useState(false);
  const [loginCheck, setlLoginCheck] = useState(false);
  function updateUser(updateData) {
    console.log('==> updateData', updateData);
    setUser(update(user, updateData));
  }
  function vipSetting(userData) {
    const { email, id, expiredAt } = userData;
    const text = `${email}-${id}-${new Date(expiredAt).getTime()}`;
    const ciphertext = AES.encrypt(
      text,
      process.env.GATSBY_VIP_INFO_KEY
    ).toString();
    localStorage.setItem('vinfo', ciphertext);
    // vinfo = JSON.stringify(data);
  }
  async function logout() {
    await glStoreFrontFetch({
      query: queryFormat({
        query: AccountLogoutMutation,
        variables: {
          customerAccessToken: user?.token?.accessToken,
        },
      }),
    });
    setUser({});
    setIsVip(null);
    setIsAbnormal(false);
    setHasConcerns(false);
    localStorage.removeItem('t');
    localStorage.removeItem('vinfo');
  }
  async function fetchUserInfoByToken(t, shouldUpdateUser = false) {
    let token = t;
    if (!token) {
      token = localStorage.getItem('t');
      if (!token || token === 'undefined') {
        localStorage.removeItem('t');
        localStorage.removeItem('vinfo');
        setlLoginCheck(true);
        return;
      }
      token = AES.decrypt(token, process.env.GATSBY_VIP_INFO_KEY);
      token = token.toString(enc.Utf8);
      token = JSON.parse(token);
    }
    // const token =
    //   t ||
    //   (localStorage.getItem('t') !== 'undefined'
    //     ? JSON.parse(localStorage.getItem('t'))
    //     : false);
    let result;
    if (token) {
      result = await glStoreFrontFetch({
        query: queryFormat({
          query: AccountLoginQuery,
          variables: {
            customerAccessToken: token.accessToken,
          },
          setting: {
            country: localStorage.getItem('country') || 'TW',
          },
        }),
      });
      if (!result.customer) {
        localStorage.removeItem('t');
        localStorage.removeItem('vinfo');
        setlLoginCheck(true);
        return;
      }
      const tokenEncrypt = AES.encrypt(
        JSON.stringify(token),
        process.env.GATSBY_VIP_INFO_KEY
      ).toString();
      // localStorage.setItem('vinfo', ciphertext);
      localStorage.setItem('t', tokenEncrypt);

      const checkoutId = localStorage.getItem('checkoutId');
      if (checkoutId) {
        try {
          const checkoutCustomerAssociate = await glStoreFrontFetch({
            query: mutationFormat({
              query: CheckoutCustomerAssociateV2Mutation,
              variables: {
                customerAccessToken: token.accessToken,
                checkoutId,
              },
            }),
          });
          console.log(
            '===> checkoutCustomerAssociate',
            checkoutCustomerAssociate
          );
          if (checkoutCustomerAssociate.checkoutCustomerAssociateV2.checkout) {
          }
        } catch (e) {
          console.log(e);
        }
      }
      if (shouldUpdateUser) {
        setUser({
          ...result.customer,
          token,
        });
      }
      setlLoginCheck(true);
      return {
        customer: result.customer,
        token,
      };
    }
  }

  useEffect(() => {
    if (vipTags.length > 0 && user?.id && isVip === null) {
      for (const tag of vipTags) {
        console.log('==> setIsVip', tag, vipTags); // logs 3, 5, 7
        if (user.tags.includes(tag)) {
          setIsVip(true);
          break;
        }
      }
    }
    if (user?.tags?.includes('abnormal')) {
      setIsAbnormal(true);
    }
    if (user?.tags?.includes('A') || user?.tags?.includes('B')) {
      setHasConcerns(true);
    }
  }, [vipTags, user]);

  useEffect(() => {
    // function checkVIPLogin() {
    async function userInit() {
      // const token = localStorage.getItem('t');
      // console.log(
      //   '===> token',
      //   token,
      //   queryFormat({
      //     query: AccountLoginQuery,
      //     variables: {
      //       customerAccessToken: token,
      //     },
      //   })
      // );
      // if (token) {
      //   const result = await glStoreFrontFetch({
      //     query: queryFormat({
      //       query: AccountLoginQuery,
      //       variables: {
      //         customerAccessToken: token,
      //       },
      //     }),
      //   });
      //   console.log('===> resultaa', result);
      //   if (!result.customer) {
      //     localStorage.removeItem('t');
      //     localStorage.removeItem('vinfo');
      //     return;
      //   }
      // }
      const userInfo = await fetchUserInfoByToken();
      if (!userInfo) {
        return;
      }
      let info = localStorage.getItem('vinfo');
      const vinfo = {};
      if (info) {
        info = AES.decrypt(info, process.env.GATSBY_VIP_INFO_KEY);
        info = info.toString(enc.Utf8);
        info = info.split('-');
        info.map((item, index) => {
          switch (index) {
            case 0:
              vinfo.email = item;
              break;
            case 1:
              vinfo.id = item;
              break;
            case 2:
              vinfo.expiredAt = item;
              break;
          }
        });
      }
      // fetchUserInfoByToken
      // vinfo = JSON.parse(vinfo);
      setUser({
        ...userInfo.customer,
        token: userInfo.token,
        vinfo,
      });
    }
    userInit();
    // }
  }, []);

  useEffect(() => {
    if (user.token) {
      const tokenEncrypt = AES.encrypt(
        JSON.stringify(user.token),
        process.env.GATSBY_VIP_INFO_KEY
      ).toString();
      localStorage.setItem('t', tokenEncrypt);
    }
  }, [user]);
  // useEffect(() => {
  //   setSetting({
  //     ...setting,
  //     country: fetchStoreFrontAPIData?.localization?.country?.isoCode
  //       ? fetchStoreFrontAPIData.localization.country.isoCode
  //       : defaultCountry,
  //   });
  // }, [fetchStoreFrontAPIData]);

  // useEffect(() => {
  //   const url = typeof window !== 'undefined' ? window.location.href : '';
  //   if (!url) {
  //     return;
  //   }
  //   const { origin } = window.location;
  //   const { pathname } = window.location;

  //   let tailUrl = '';
  //   pathname.split('/').map((data, index) => {
  //     console.log('===> data', data, pathname);
  //     if (
  //       ((index === 0 || index === 1) && langsArray.find((s) => s === data)) ||
  //       data === ''
  //     ) {
  //       return;
  //     }
  //     tailUrl += `/${data}`;
  //   });
  //   // tailUrl = tailUrl === '' ? '/' : tailUrl;
  //   console.log('===> tailUrl', tailUrl, setting.lang);
  //   if (setting.lang === 'ZH') {
  //     navigate(`${origin}${tailUrl}`, {
  //       replace: false,
  //     });
  //   } else {
  //     navigate(`${origin}/${setting.lang.toLowerCase()}${tailUrl}`, {
  //       replace: false,
  //     });
  //   }
  // }, [setting.lang]);
  return {
    user,
    updateUser,
    loginCheck,
    logout,
    // loading,
    vipSetting,
    fetchUserInfoByToken,
    vipTags,
    setVipTags,
    isVip,
    isAbnormal,
    hasConcerns,
  };
}
