import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
// import { LazyLoadImage } from 'react-lazy-load-image-component';
import { currencyCode, dollarFloat } from '../helpers/currency';
import { langPrefix } from '../helpers/urlParser';
import { getOptionsColorHEX, getColorHEX } from '../helpers/itemHelper';
import { isBrowser } from '../helpers/browser';
import { currencyName } from '../utils/useSetting';
// import { currencyCode, dollarFloat } from '../helpers/currency';

const ItemDetail = styled.div`
  position: relative;
  width: 100%;
  /* &.opacity6 {
    opacity: 0.6;
    &:hover {
      opacity: 1;
    }
  } */
  &:hover {
    .options-detail {
      opacity: 1;
    }
    .under-detail {
      &.hover-ani {
        opacity: 1;
      }
      /* .display-flex {
        flex-wrap: wrap;
      } */
    }
    .padding {
      .hover-cover {
        opacity: 1;
      }
    }
  }
  .video-container {
    width: 100%;
    display: block;
    position: relative;
    min-width: 100%;
    .padding-bottom {
      position: relative;
      width: 100%;
      /* padding-bottom: 140%; */
      padding-bottom: 145%;
    }
    .video {
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .padding {
    width: 100%;
    /* padding-bottom: 140%; */
    padding-bottom: 145%;
    position: relative;
    background-position: center;
    background-size: cover;
    .hover-cover {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: cover;
      transition: opacity 0.15s ease;
    }
  }
  .under-detail {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    height: 55.2px;
    &.options-detail {
      height: auto;
    }
    p {
      margin-top: 10px;
      text-decoration: none;
    }
    &.hover-ani {
      opacity: 0;
      transition: opacity 0.3s ease;
    }
    @media screen and (max-width: 900px) {
      /* flex-direction: column; */
      display: block;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 55px;
      &.options-detail {
        opacity: 1;
        display: flex;
        height: auto;
        .display-flex {
          &:nth-child(1) {
            display: none;
          }
          p {
            /* &:nth-child(1) {
              display: none;
            }
            &:nth-child(2) {
              opacity: 1; */
            }
          }
        }
      }
      p {
        margin-top: 2px;
      }
    }
    @media screen and (max-width: 512px) {
      p {
        margin-top: 5px;
        /* &.s-13 {
          font-size: 11px !important;
        }
        &.prics-text {
          font-size: 9px !important;
        } */
      }
    }
  }
  .inbox {
    width: 100%;
    position: absolute;
    bottom: 0px;
    padding: 50px;
    border-top: 1px solid #000;
    @media screen and (max-width: 900px) {
      padding: 25px;
      background: rgba(255, 255, 255, 0.4);
      backdrop-filter: blur(20px);
    }
  }
  .options-detail {
    opacity: 0;
    transition: opacity 0.3s ease;
    p {
      margin-top: 0px;
    }
    
  }
  .colors {
    a {
      margin-left: 10px;
      &:nth-child(1) {
        margin-left: 0px;
      }
      .dot-color {
        width: 10px;
        height: 10px;
        position: relative;
        background: red;
        border-radius: 50%;
      }
    }
  }
  .main-item {
    padding-left: 50px;
    padding-right: 50px;
    @media screen and (max-width: 1020px) {
      padding-left: 25px;
      padding-right: 25px;
    }
  }
`;

export default function Item(props) {
  const {
    className,
    itemId,
    item = {},
    url = '',
    onClick = () => {},
    type = 'under',
    pageContext,
    location,
    timestamp = new Date().getTime(),
    showOptions = false,
    colorItems = false,
    contexntHoverAni = false,
    style = {},
    textAlign = false,
    underDetailClassName = '',
    mainItem = false,
    hasFetchInit = true,
    setting = {},
    useSpecialImage = false,
  } = props;
  const [allColorItems, setAllColorItems] = useState([]);
  const id = itemId || item.id.split('/')[item.id.split('/').length - 1];

  // console.log('==> item colorItems', item, colorItems);
  // itemId = itemId[itemId.length - 1];
  useEffect(() => {
    if (colorItems) {
      const currentItem = [];
      const otherItems = [];
      colorItems.map((colorItem) => {
        // console.log('==> aaaaaaaa', colorItem.id, item.id, colorItem);
        if (colorItem.id === item.id) {
          currentItem.push(colorItem);
        } else {
          otherItems.push(colorItem);
        }
      });
      setAllColorItems([...currentItem, ...otherItems]);
    }
  }, [colorItems]);

  const textPosition = textAlign
    ? { textAlign, width: '100%', padding: '0px 10px' }
    : {};
  // console.log('=> textPosition', textPosition);
  console.log(
    '==> item',
    item,
    item.totalInventory <= 0 && timestamp >= new Date(item.saleAt.value),
    timestamp,
    new Date(item.saleAt?.value),
    item.saleAt
  );
  return (
    <Link
      // key={currentItem?.id}
      to={`${langPrefix(pageContext, location)}/products/${id}`}
      // to={`/products/${itemId}`}
      state={{ itemId: id, ...item, timestamp }}
      onClick={onClick}
    >
      <ItemDetail
        className={`${className} item-detail pointer`}
        style={
          item.totalInventory <= 0 && timestamp >= new Date(item.saleAt.value)
            ? { ...style, opacity: 0.25 }
            : style
        }
      >
        {useSpecialImage &&
        item?.showVideo?.value &&
        JSON.parse(item?.showVideo?.value || 'false') &&
        item.videoSrc?.value ? (
          <div className="video-container">
            <div className="padding-bottom" />
            <video
              // ref={(ref) => {
              //   console.log('==> video', ref);
              //   setVideo(ref);
              // }}
              id="video"
              className="video"
              // src="https://player.vimeo.com/progressive_redirect/playback/853293383/rendition/720p/file.mp4?loc=external&signature=cb374b8e8962757a47c4b8be81efeff5d8e541bde9f4ce72a0c04ed367c75d6d"
              controls={false}
              autoPlay="1"
              muted="1"
              loop="1"
              playsInline="1"
            >
              <source
                type="video/mp4"
                // src="https://player.vimeo.com/progressive_redirect/playback/840396638/rendition/1080p/file.mp4?loc=external&amp;signature=087fbc1e20517f7d7b5b76bcd3d19b646e4b15eec1f2b6c27365ea40bb7c5682"
                // src="https://player.vimeo.com/progressive_redirect/playback/853293383/rendition/720p/file.mp4?loc=external&signature=cb374b8e8962757a47c4b8be81efeff5d8e541bde9f4ce72a0c04ed367c75d6d"
                src={item.videoSrc.value}
              />
            </video>
          </div>
        ) : (
          <div
            id="cover1"
            className="padding"
            style={{
              // backgroundImage: `url(${
              //   useSpecialImage && item.collectionImage?.value
              //     ? item.collectionImage?.value
              //     : item?.images?.nodes
              //     ? item.images.nodes[
              //         (useSpecialImage &&
              //           item?.showImageIndex?.value &&
              //           parseInt(item?.showImageIndex?.value)) ||
              //           (useSpecialImage &&
              //             item?.showImageIndex?.value == 0 &&
              //             0) ||
              //           item.images.nodes.length - 1
              //       ]?.url
              //     : ''
              // })`,
              backgroundImage: `url(${
                // useSpecialImage
                //   ? item?.images?.nodes
                //     ? item.images.nodes[
                //         (useSpecialImage &&
                //           item?.showImageIndex?.value &&
                //           parseInt(item?.showImageIndex?.value)) ||
                //           (useSpecialImage &&
                //             item?.showImageIndex?.value == 0 &&
                //             0) ||
                //           item.images.nodes.length - 1
                //       ]?.url
                //     : item.collectionImage?.value ||
                //       item.images.nodes[item.images.nodes.length - 1]?.url ||
                //       ''
                //   : item.images.nodes[item.images.nodes.length - 1]?.url || ''

                useSpecialImage
                  ? item?.images?.nodes
                    ? item.images.nodes[
                        (useSpecialImage &&
                          (item?.showImageIndex?.value ||
                            item?.showImageIndex?.value === 0) &&
                          `${parseInt(item?.showImageIndex?.value)}`) ||
                          (useSpecialImage &&
                            item?.showImageIndex?.value == 0 &&
                            0) ||
                          item.images.nodes.length - 1
                      ]?.url
                    : item.collectionImage?.value ||
                      item.images.nodes[item.images.nodes.length - 1]?.url ||
                      ''
                  : item.images.nodes[item.images.nodes.length - 1]?.url || ''

                // item.collectionImage?.value
                //   ? item.collectionImage?.value
                //   : item?.images?.nodes
                //   ? item.images.nodes[
                //       (useSpecialImage &&
                //         item?.showImageIndex?.value &&
                //         parseInt(item?.showImageIndex?.value)) ||
                //         (useSpecialImage &&
                //           item?.showImageIndex?.value == 0 &&
                //           0) ||
                //         item.images.nodes.length - 1
                //     ]?.url
                //   : ''
              })`,
            }}
          >
            <div
              className="hover-cover"
              style={{
                backgroundImage: `url(${url})`,
              }}
            />
          </div>
        )}

        {/* <div className="padding" /> */}
        {/* <LazyLoadImage
          alt=""
          effect="blur"
          // height={image.height}
          src={url} // use normal <img> attributes as props
          // width={image.width}
          style={{ position: 'relative', top: 0, left: 0, width: '100%' }}
        /> */}
        {type === 'inbox' && (
          <div className="inbox">
            <p
              className="s-13 GSText text-overflow-ellipsis"
              style={textPosition}
            >
              {item.title}
            </p>
            <div className="margin-top-5 display-flex justify-content">
              <p className="s-13 GSText" style={textPosition}>
                {item.totalInventory <= 0 ? (
                  <span className="s-11">Sold Out</span>
                ) : hasFetchInit ? (
                  `${currencyCode(
                    item.priceRange.minVariantPrice.currencyCode
                  )}${'$ '}
                ${Math.floor(item.priceRange.minVariantPrice.amount)}`
                ) : (
                  ' '
                )}
              </p>
              {allColorItems && allColorItems.length > 0 && (
                <div className="display-flex colors align-items-center">
                  {allColorItems.map((moreItem) => {
                    // const color = getOptionsColorHEX(moreItem?.options);
                    let color = '';
                    if (moreItem?.color?.value) {
                      const colors = JSON.parse(moreItem.color.value);
                      color = getColorHEX(colors);
                    }
                    console.log('===> allColorItems item', moreItem, color);
                    const moreItemId =
                      moreItem.id.split('/')[moreItem.id.split('/').length - 1];
                    return (
                      <Link
                        key={moreItem.id}
                        to={`${langPrefix(
                          pageContext,
                          location
                        )}/products/${moreItemId}`}
                        state={{ itemId: moreItemId, ...moreItem }}
                      >
                        <div
                          // onMouseEnter={(e) => {
                          //   const ele = e.target.closest('.item-detail');
                          //   const img = ele.querySelector('.padding');
                          //   const text = ele.querySelectorAll(
                          //     '.item-content-detail p'
                          //   );
                          //   img.style.backgroundImage = `url(${moreItem.images?.nodes[0]?.url})`;
                          //   text[0].innerText = moreItem.title;
                          //   text[1].innerText = `${currencyCode(
                          //     moreItem.variants.nodes[0].price
                          //       .currencyCode
                          //   )}$ ${
                          //     moreItem.variants.nodes[0].price.amount
                          //   }`;
                          // }}
                          // onMouseLeave={(e) => {
                          //   const ele = e.target.closest('.item-detail');
                          //   const img = ele.querySelector('.padding');
                          //   const text = ele.querySelectorAll(
                          //     '.item-content-detail p'
                          //   );
                          //   img.style.backgroundImage = `url(${allColorItems[0]?.images?.nodes[0]?.url})`;
                          //   text[0].innerText = allColorItems[0].title;
                          //   // text[1].innerText = item.title;
                          //   text[1].innerText = `${currencyCode(
                          //     allColorItems[0].variants.nodes[0].price
                          //       .currencyCode
                          //   )}$ ${
                          //     allColorItems[0].variants.nodes[0].price.amount
                          //   }`;

                          //   // text[0].text =
                          // }}
                          className="dot-color"
                          style={{ background: color }}
                        />
                      </Link>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        )}
        {(type === 'under' || type === 'under-flex') && (
          <>
            <div
              className={`under-detail p-v-10 item-content-detail ${
                contexntHoverAni && 'hover-ani'
              } ${underDetailClassName}`}
            >
              {type === 'under' && (
                <>
                  <p
                    className="s-13 GSText text-overflow-ellipsis"
                    style={textPosition}
                  >
                    {item.title}
                  </p>
                  {/* <p
                    className="s-13 GSText prics-text text-overflow-ellipsis"
                    style={textPosition}
                  >
                    {item.totalInventory <= 0 ? (
                      <span className="s-11">Sold Out</span>
                    ) : hasFetchInit ? (
                      `${currencyCode(
                        item.priceRange.minVariantPrice.currencyCode
                      )}${'$ '}
                      ${Math.floor(item.priceRange.minVariantPrice.amount)}`
                    ) : (
                      ' '
                    )}
                    {isBrowser() &&
                      setting.country.toUpperCase() !== 'TW' &&
                      item.totalInventory !== 0 &&
                      window.Currency && (
                        <span className="s-9">
                          {' '}
                          ≈ {currencyName[setting.country.toUpperCase()]}${' '}
                          {isBrowser() &&
                            setting.country &&
                            Math.round(
                              window.Currency.convert(
                                item.priceRange.minVariantPrice.amount,
                                'TWD',
                                currencyName[setting.country.toUpperCase()]
                              ) * 100
                            ) / 100}
                        </span>
                      )}
                  </p> */}
                  <p
                    className="s-13 GSText prics-text text-overflow-ellipsis"
                    style={textPosition}
                  >
                    {item.totalInventory <= 0 ? (
                      <span className="s-11">Sold Out</span>
                    ) : hasFetchInit ? (
                      process.env.GATSBY_IS_INT === 'true' ? (
                        `${currencyCode(
                          item.priceRange.minVariantPrice.currencyCode
                        )}${'$ '}
                  ${item.priceRange.minVariantPrice.amount}`
                      ) : setting.country &&
                        setting.country.toUpperCase() !== 'TW' &&
                        isBrowser() &&
                        window.Currency ? (
                        <>
                          {currencyName[setting.country.toUpperCase()]}${' '}
                          <span>
                            {/* ≈ {' '} */}
                            {Math.round(
                              window.Currency.convert(
                                dollarFloat(
                                  item.priceRange.minVariantPrice.amount,
                                  setting.country
                                ),
                                'TWD',
                                currencyName[setting.country.toUpperCase()]
                              ) * 100
                            ) / 100}{' '}
                            {/* <span className="s-9 hide-512">
                              ( TWD${' '}
                              {dollarFloat(
                                item.priceRange.minVariantPrice.amount,
                                'TW'
                              )}{' '}
                              )
                            </span> */}
                          </span>
                        </>
                      ) : (
                        `${currencyCode(
                          item.priceRange.minVariantPrice.currencyCode
                        )}${'$ '}
                      ${Math.floor(item.priceRange.minVariantPrice.amount)}`
                      )
                    ) : process.env.GATSBY_IS_INT === 'true' ? (
                      `${currencyCode(
                        item.priceRange.minVariantPrice.currencyCode
                      )}${'$ '}
                  ${item.priceRange.minVariantPrice.amount}`
                    ) : setting.country &&
                      setting.country.toUpperCase() !== 'TW' &&
                      isBrowser() &&
                      window.Currency ? (
                      <>
                        {currencyName[setting.country.toUpperCase()]}${' '}
                        <span>
                          {/* ≈ {' '} */}
                          {Math.round(
                            window.Currency.convert(
                              dollarFloat(
                                item.priceRange.minVariantPrice.amount,
                                setting.country
                              ),
                              'TWD',
                              currencyName[setting.country.toUpperCase()]
                            ) * 100
                          ) / 100}{' '}
                          {/* <span className="s-9 hide-512">
                              ( TWD${' '}
                              {dollarFloat(
                                item.priceRange.minVariantPrice.amount,
                                'TW'
                              )}{' '}
                              )
                            </span> */}
                        </span>
                      </>
                    ) : isBrowser() && window.Currency ? (
                      `${currencyCode(
                        item.priceRange.minVariantPrice.currencyCode
                      )}${'$ '}
                      ${
                        Math.round(
                          window?.Currency?.convert(
                            dollarFloat(
                              item.priceRange.minVariantPrice.amount,
                              setting.country
                            ),
                            'TWD',
                            currencyName[setting.country.toUpperCase()]
                          ) * 100
                        ) / 100
                      }
                `
                    ) : (
                      ''
                    )}
                    {/* {isBrowser() &&
                      setting.country.toUpperCase() !== 'TW' &&
                      item.totalInventory !== 0 &&
                      window.Currency && (
                        <span className="s-9">
                          {' '}
                          ≈ {currencyName[setting.country.toUpperCase()]}${' '}
                          {isBrowser() &&
                            setting.country &&
                            Math.round(
                              window.Currency.convert(
                                item.priceRange.minVariantPrice.amount,
                                'TWD',
                                currencyName[setting.country.toUpperCase()]
                              ) * 100
                            ) / 100}
                        </span>
                      )} */}
                  </p>
                </>
              )}
              {type === 'under-flex' && (
                <>
                  <div className="display-flex flex-1 align-items-center justify-content width-100">
                    <p
                      className="s-13 GSText text-overflow-ellipsis"
                      style={textPosition}
                    >
                      {item.title}
                    </p>
                    <p
                      className="s-13 GSText prics-text text-overflow-ellipsis"
                      style={textPosition}
                    >
                      {/* {item.totalInventory <= 0 ? (
                        <span className="s-11">Sold Out</span>
                      ) : hasFetchInit ? (
                        `${currencyCode(
                          item.priceRange.minVariantPrice.currencyCode
                        )}${'$ '}
              ${Math.floor(item.priceRange.minVariantPrice.amount)}`
                      ) : (
                        ' '
                      )}
                      {isBrowser() &&
                        setting.country.toUpperCase() !== 'TW' &&
                        item.totalInventory !== 0 &&
                        window.Currency && (
                          <span className="s-9">
                            {' '}
                            ≈ {
                              currencyName[setting.country.toUpperCase()]
                            }${' '}
                            {isBrowser() &&
                              setting.country &&
                              Math.round(
                                window.Currency.convert(
                                  item.priceRange.minVariantPrice.amount,
                                  'TWD',
                                  currencyName[setting.country.toUpperCase()]
                                ) * 100
                              ) / 100}
                          </span>
                        )} */}
                      {item.totalInventory <= 0 ? (
                        <span className="s-11">Sold Out</span>
                      ) : hasFetchInit ? (
                        process.env.GATSBY_IS_INT === 'true' ? (
                          `${currencyCode(
                            item.priceRange.minVariantPrice.currencyCode
                          )}${'$ '}
                          ${item.priceRange.minVariantPrice.amount}`
                        ) : setting.country &&
                          setting.country.toUpperCase() !== 'TW' &&
                          isBrowser() &&
                          window.Currency ? (
                          <>
                            {currencyName[setting.country.toUpperCase()]}${' '}
                            <span>
                              {/* ≈ {' '} */}
                              {Math.round(
                                window.Currency.convert(
                                  dollarFloat(
                                    item.priceRange.minVariantPrice.amount,
                                    setting.country
                                  ),
                                  'TWD',
                                  currencyName[setting.country.toUpperCase()]
                                ) * 100
                              ) / 100}
                              {/* {' '}
                                <span className='s-9 hide-512'>
                                  ( TWD$ {dollarFloat(item.priceRange.minVariantPrice.amount, 'TW')} )
                                </span> */}
                            </span>
                          </>
                        ) : (
                          `${currencyCode(
                            item.priceRange.minVariantPrice.currencyCode
                          )}${'$ '}
                          ${Math.floor(item.priceRange.minVariantPrice.amount)}`
                        )
                      ) : (
                        ' '
                      )}
                    </p>
                  </div>
                  <div className="display-flex options-detail align-items-center justify-content width-100 margin-top-5">
                    <div className="display-flex">
                      {item.options
                        ?.find((option) => option.name === '大小')
                        ?.values.map((value, index) => (
                          <p
                            key={`${itemId}-${value}`}
                            className="s-13 GSText p-h-5"
                            style={index === 0 ? { paddingLeft: 0 } : {}}
                          >
                            {value}
                          </p>
                        ))}
                    </div>
                    {/* {colorItems && colorItems.length > 0 && (
                  <div className="display-flex colors">
                    {[0, 1].map((item, index) => (
                      <Link key={`${itemId}-color-${index}`}>
                        <div className="dot-color" />
                      </Link>
                    ))}
                  </div>
                )} */}
                    {allColorItems && allColorItems.length > 0 && (
                      <div className="display-flex colors">
                        {allColorItems.map((moreItem) => {
                          // const color = getOptionsColorHEX(moreItem?.options);
                          let color = '';
                          if (moreItem?.color?.value) {
                            const colors = JSON.parse(moreItem.color.value);
                            color = getColorHEX(colors);
                          }
                          // console.log(
                          //   '===> allColorItems item',
                          //   moreItem,
                          //   color
                          // );
                          const moreItemId =
                            moreItem.id.split('/')[
                              moreItem.id.split('/').length - 1
                            ];
                          return (
                            <Link
                              key={moreItem.id}
                              to={`${langPrefix(
                                pageContext,
                                location
                              )}/products/${moreItemId}`}
                              state={{ itemId: moreItemId, ...moreItem }}
                            >
                              <div
                                onMouseEnter={(e) => {
                                  const ele = e.target.closest('.item-detail');
                                  const img = ele.querySelector(
                                    '.padding .hover-cover'
                                  );
                                  const text = ele.querySelectorAll(
                                    '.item-content-detail p'
                                  );
                                  if (!img) {
                                    return;
                                  }
                                  img.style.backgroundImage = `url(${
                                    moreItem.images?.nodes[
                                      moreItem.images?.nodes?.length - 1
                                    ]?.url
                                  })`;
                                  text[0].innerText = moreItem.title;
                                  // text[1].innerText =
                                  //   moreItem.totalInventory === 0
                                  //     ? 'Sold Out'
                                  //     : `${currencyCode(
                                  //         moreItem.variants.nodes[0].price
                                  //           .currencyCode
                                  //       )}$ ${
                                  //         moreItem.variants.nodes[0].price
                                  //           .amount
                                  //       }`;
                                  text[1].innerHtml =
                                    moreItem.totalInventory <= 0
                                      ? 'Sold Out'
                                      : `${currencyCode(
                                          moreItem.variants.nodes[0].price
                                            .currencyCode
                                        )}$ ${
                                          moreItem.variants.nodes[0].price
                                            .amount
                                        }${isBrowser()}` &&
                                        setting.country.toUpperCase() !==
                                          'TW' &&
                                        moreItem.totalInventory !== 0 &&
                                        window.Currency && (
                                          <span className="s-9">
                                            {' '}
                                            ≈{' '}
                                            {
                                              currencyName[
                                                setting.country.toUpperCase()
                                              ]
                                            }
                                            ${' '}
                                            {isBrowser() &&
                                              setting.country &&
                                              Math.round(
                                                window.Currency.convert(
                                                  moreItem.priceRange
                                                    .minVariantPrice.amount,
                                                  'TWD',
                                                  currencyName[
                                                    setting.country.toUpperCase()
                                                  ]
                                                ) * 100
                                              ) / 100}
                                          </span>
                                        );
                                }}
                                onMouseLeave={(e) => {
                                  const ele = e.target.closest('.item-detail');
                                  const img = ele.querySelector(
                                    '.padding .hover-cover'
                                  );
                                  const text = ele.querySelectorAll(
                                    '.item-content-detail p'
                                  );
                                  if (!img) {
                                    return;
                                  }
                                  img.style.backgroundImage = `url(${allColorItems[0]?.images?.nodes[0]?.url})`;
                                  text[0].innerText = allColorItems[0].title;
                                  // text[1].innerText = item.title;
                                  // text[1].innerText =
                                  //   allColorItems[0].totalInventory === 0
                                  //     ? 'Sold Out'
                                  //     : `${currencyCode(
                                  //         allColorItems[0].variants.nodes[0]
                                  //           .price.currencyCode
                                  //       )}$ ${
                                  //         allColorItems[0].variants.nodes[0]
                                  //           .price.amount
                                  //       }`;
                                  text[1].innerHtml =
                                    allColorItems[0].totalInventory <= 0
                                      ? 'Sold Out'
                                      : `${currencyCode(
                                          allColorItems[0].variants.nodes[0]
                                            .price.currencyCode
                                        )}$ ${
                                          allColorItems[0].variants.nodes[0]
                                            .price.amount
                                        }${isBrowser()}` &&
                                        setting.country.toUpperCase() !==
                                          'TW' &&
                                        allColorItems[0].totalInventory !== 0 &&
                                        window.Currency && (
                                          <span className="s-9">
                                            {' '}
                                            ≈{' '}
                                            {
                                              currencyName[
                                                setting.country.toUpperCase()
                                              ]
                                            }
                                            ${' '}
                                            {isBrowser() &&
                                              setting.country &&
                                              Math.round(
                                                window.Currency.convert(
                                                  allColorItems[0].priceRange
                                                    .minVariantPrice.amount,
                                                  'TWD',
                                                  currencyName[
                                                    setting.country.toUpperCase()
                                                  ]
                                                ) * 100
                                              ) / 100}
                                          </span>
                                        );
                                  // text[0].text =
                                }}
                                className="dot-color"
                                style={{ background: color }}
                              />
                            </Link>
                          );
                        })}
                      </div>
                    )}
                    {/* // <p className="s-13 GSText">{item.title}</p> */}
                  </div>
                </>
              )}
            </div>
            {showOptions && type === 'under' && (
              <div
                className={`under-detail options-detail ${
                  mainItem ? 'main-item' : ''
                }`}
              >
                <div className="display-flex">
                  {item.options
                    .find((option) => option.name === '大小')
                    ?.values.map((value, index) => (
                      <p
                        key={`${itemId}-${value}`}
                        className="s-13 GSText p-h-5"
                        style={index === 0 ? { paddingLeft: 0 } : {}}
                      >
                        {value}
                      </p>
                    ))}
                </div>
                {/* {colorItems && colorItems.length > 0 && (
                  <div className="display-flex colors">
                    {[0, 1].map((item, index) => (
                      <Link key={`${itemId}-color-${index}`}>
                        <div className="dot-color" />
                      </Link>
                    ))}
                  </div>
                )} */}
                {allColorItems && allColorItems.length > 0 && (
                  <div className="display-flex colors">
                    {allColorItems.map((moreItem) => {
                      // const color = getOptionsColorHEX(moreItem?.options);
                      let color = '';
                      if (moreItem?.color?.value) {
                        const colors = JSON.parse(moreItem.color.value);
                        color = getColorHEX(colors);
                      }
                      console.log('===> allColorItems item', moreItem, color);
                      const moreItemId =
                        moreItem.id.split('/')[
                          moreItem.id.split('/').length - 1
                        ];
                      return (
                        <Link
                          key={moreItem.id}
                          to={`${langPrefix(
                            pageContext,
                            location
                          )}/products/${moreItemId}`}
                          state={{ itemId: moreItemId, ...moreItem }}
                        >
                          <div
                            onMouseEnter={(e) => {
                              console.log(moreItem);
                              const ele = e.target.closest('.item-detail');
                              const img = ele.querySelector(
                                '.padding .hover-cover'
                              );
                              const text = ele.querySelectorAll(
                                '.item-content-detail p'
                              );
                              if (!img) {
                                return;
                              }
                              img.style.backgroundImage = `url(${
                                moreItem.images?.nodes[
                                  moreItem.images?.nodes?.length - 1
                                ]?.url
                              })`;
                              text[0].innerText = moreItem.title;
                              text[1].innerText =
                                moreItem.totalInventory <= 0
                                  ? 'Sold Out'
                                  : `${currencyCode(
                                      moreItem.variants.nodes[0].price
                                        .currencyCode
                                    )}$ ${
                                      moreItem.variants.nodes[0].price.amount
                                    }`;
                            }}
                            onMouseLeave={(e) => {
                              const ele = e.target.closest('.item-detail');
                              const img = ele.querySelector(
                                '.padding .hover-cover'
                              );
                              const text = ele.querySelectorAll(
                                '.item-content-detail p'
                              );
                              if (!img) {
                                return;
                              }
                              img.style.backgroundImage = `url(${allColorItems[0]?.images?.nodes[0]?.url})`;
                              text[0].innerText = allColorItems[0].title;
                              // text[1].innerText = item.title;
                              text[1].innerText =
                                allColorItems[0].totalInventory <= 0
                                  ? 'Sold Out'
                                  : `${currencyCode(
                                      allColorItems[0].variants.nodes[0].price
                                        .currencyCode
                                    )}$ ${
                                      allColorItems[0].variants.nodes[0].price
                                        .amount
                                    }`;

                              // text[0].text =
                            }}
                            className="dot-color"
                            style={{ background: color }}
                          />
                        </Link>
                      );
                    })}
                  </div>
                )}
                {/* // <p className="s-13 GSText">{item.title}</p> */}
              </div>
            )}
          </>
        )}
      </ItemDetail>
    </Link>
  );
}
