exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-activate-js": () => import("./../../../src/pages/account/activate.js" /* webpackChunkName: "component---src-pages-account-activate-js" */),
  "component---src-pages-account-foo-activate-js": () => import("./../../../src/pages/account/foo_activate.js" /* webpackChunkName: "component---src-pages-account-foo-activate-js" */),
  "component---src-pages-account-foo-reset-js": () => import("./../../../src/pages/account/foo_reset.js" /* webpackChunkName: "component---src-pages-account-foo-reset-js" */),
  "component---src-pages-account-index-js": () => import("./../../../src/pages/account/index.js" /* webpackChunkName: "component---src-pages-account-index-js" */),
  "component---src-pages-account-login-js": () => import("./../../../src/pages/account/login.js" /* webpackChunkName: "component---src-pages-account-login-js" */),
  "component---src-pages-account-recover-js": () => import("./../../../src/pages/account/recover.js" /* webpackChunkName: "component---src-pages-account-recover-js" */),
  "component---src-pages-account-register-js": () => import("./../../../src/pages/account/register.js" /* webpackChunkName: "component---src-pages-account-register-js" */),
  "component---src-pages-account-reset-js": () => import("./../../../src/pages/account/reset.js" /* webpackChunkName: "component---src-pages-account-reset-js" */),
  "component---src-pages-bc-js": () => import("./../../../src/pages/bc.js" /* webpackChunkName: "component---src-pages-bc-js" */),
  "component---src-pages-care-js": () => import("./../../../src/pages/care.js" /* webpackChunkName: "component---src-pages-care-js" */),
  "component---src-pages-collections-index-js": () => import("./../../../src/pages/collections/index.js" /* webpackChunkName: "component---src-pages-collections-index-js" */),
  "component---src-pages-exhibition-[id]-js": () => import("./../../../src/pages/exhibition/[id].js" /* webpackChunkName: "component---src-pages-exhibition-[id]-js" */),
  "component---src-pages-exhibition-buy-js": () => import("./../../../src/pages/exhibition/buy.js" /* webpackChunkName: "component---src-pages-exhibition-buy-js" */),
  "component---src-pages-exhibition-foo-scan-js": () => import("./../../../src/pages/exhibition/foo_scan.js" /* webpackChunkName: "component---src-pages-exhibition-foo-scan-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-influencer-index-js": () => import("./../../../src/pages/influencer/index.js" /* webpackChunkName: "component---src-pages-influencer-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-registration-coming-js": () => import("./../../../src/pages/registration/coming.js" /* webpackChunkName: "component---src-pages-registration-coming-js" */),
  "component---src-pages-registration-index-js": () => import("./../../../src/pages/registration/index.js" /* webpackChunkName: "component---src-pages-registration-index-js" */),
  "component---src-pages-shipping-and-return-js": () => import("./../../../src/pages/shipping-and-return.js" /* webpackChunkName: "component---src-pages-shipping-and-return-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../../../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-templates-collections-js": () => import("./../../../src/templates/collections.js" /* webpackChunkName: "component---src-templates-collections-js" */),
  "component---src-templates-collections-new-js": () => import("./../../../src/templates/collections-new.js" /* webpackChunkName: "component---src-templates-collections-new-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

